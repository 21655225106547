import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { useLayoutEffect } from "react";
import { Layout } from "./widgets";
import AddCompany from "./components/addCompany/AddCompany";
import { getCampaigns } from "./redux/createNewCompany/actions";
import { getSegments } from "./redux/segments/actions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";

import {
  Balance,
  BalanceHistory,
  BankAudits,
  Company,
  ContactDetails,
  Counters,
  Finance,
  Profile,
  Replenishment,
  SignIn,
  SignUp,
  Statistics,
} from "./modules";
import { PasswordRecovery } from "./modules/PasswordRecovery";
import { SignUpConfirm } from "./modules/SignUpConfirm";
import { SignUpEmailChange } from "./modules/SignUpEmailChange";
import { PasswordResetConfirm } from "./modules/PasswordResetConfirm";
import { userSelectors } from "./redux/user/selectors";
import { getUserInfo, getUserRequisites } from "./redux/user/actions";
import useNotifier from "./hooks/useNotifier";
import Staff from "./modules/AdminPanel/Staff/Staff";
import Payments from "./modules/AdminPanel/Payments/Payments";
import AdminLayout from "./modules/AdminPanel/AdminMenu/AdminLayouts/AdminLayout";
import Clients from "./modules/AdminPanel/Clients/Clients";
import AdminSignIn from "./modules/AdminPanel/Sign-in/adminSignIn";
import AdminFirstSignIn from "./modules/AdminPanel/Sign-in/FirstSignIn";
import AdminPasswordReset from "./modules/AdminPanel/Sign-in/adminPasswordReset";
import Reports from "./modules/AdminPanel/Reports/Reports";
import Campaigns from "./modules/AdminPanel/Campaigns/Campaings";
import Logs from "./modules/AdminPanel/Logs/Logs";
import Dsp from "./modules/AdminPanel/Dsp/dsp";
import Ssp from "./modules/AdminPanel/Ssp/ssp";
import Dmp from "./modules/AdminPanel/Dmp/dmp";
import CampaignsReports from "./modules/AdminPanel/ReportsCampaigns/reports";

function App() {
  const [ setIsLoggedOut] = useState(false);
  const timerId = useRef(null);

  useEffect(() => {
    const autoLogout = () => {
      const programmatic_save_password = JSON.parse(localStorage.getItem('programmatic_save_password'));
      // browser tab is inactive and flag 'save password' is NOT set
      if (document.visibilityState === "hidden" && !programmatic_save_password) {
        // set timer to log user out in 30 minutes
        const id = window.setTimeout(() => {
          setIsLoggedOut(true);
          localStorage.removeItem('token');
        }, 30 * 60 * 1000);
        timerId.current = id;
      } else {
        // clear existing timer
        window.clearTimeout(timerId.current);
      }
    };

    document.addEventListener("visibilitychange", autoLogout);

    return () => document.removeEventListener("visibilitychange", autoLogout);
  }, []);

  useNotifier();
  const dispatch = useDispatch();
  const user = useSelector(userSelectors.all_object);
  useLayoutEffect(() => {
    if (!Object.keys(user).length) {
      dispatch(getUserInfo());
      dispatch(getUserRequisites());
    }
    dispatch(getCampaigns());
    dispatch(getSegments());
  }, [user]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<Company />} />

          <Route path="add-company" element={<AddCompany />}></Route>

          <Route path="statistics" element={<Statistics />} />

          <Route path="finance" element={<Finance />}>
            <Route path="replenishment" element={<Replenishment />} />
            <Route path="balance-history" element={<BalanceHistory />} />
          </Route>

          <Route path="counters" element={<Counters />} />
          <Route path="archive-campaigns" element={<Company isArchive={true} />} />

          <Route path="profile" element={<Profile />}>
            <Route path="contact-details" element={<ContactDetails />} />
            <Route path="bank-audits" element={<BankAudits />} />
            {/* <Route path="settings" element={<Settings />} /> */}
          </Route>

          <Route path="balance" element={<Balance />} />
        </Route>

        <Route path="sign-in" element={<SignIn />} />
        <Route path="sign-up" element={<SignUp />} />
        <Route path="password-recovery" element={<PasswordRecovery />} />
        <Route
          path="password/reset/confirm"
          element={<PasswordResetConfirm />}
        />
        <Route
          path="sign-up/confirmation"
          element={<SignUpConfirm />}
        />
        <Route path="sign-up/email/change" element={<SignUpEmailChange />} />
        <Route path="/admin" element={<AdminLayout />}>
        <Route path="/admin" element={<Navigate to={'/admin/clients'}/>} />
          <Route path="/admin/clients" element={<Clients />} />
          <Route path="/admin/staff" element={<Staff />} />
          <Route path="/admin/payments" element={<Payments />} />
          <Route path="/admin/campaigns" element={<Campaigns />} />
          <Route path="/admin/reports" element={<Reports />} />
          <Route path="/admin/logs" element={<Logs />} />
          <Route path="/admin/dsp" element={<Dsp />} />
          <Route path="/admin/ssp" element={<Ssp />} />
          <Route path="/admin/dmp" element={<Dmp />} />
          <Route path="/admin/campaigns-reports" element={<CampaignsReports />} />
        </Route>
        <Route path="admin/sign-in" element={<AdminSignIn />} />
        <Route path="admin/sign-up/confirmation" element={<AdminFirstSignIn />} />
        <Route path="admin/password-reset" element={<AdminPasswordReset />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
