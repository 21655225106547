/* eslint-disable no-restricted-globals */
import { useState, useRef } from "react";
import DataTable from "../Table/Datatable";
import { format as dateFormat } from "date-fns";
import { useEffect } from "react";
import { API_HOST } from "../../../configs/main";
import "./reports.scss";
import { INIT_FILTER_VALUE, filterDate, filterEmploye, filterStatsDate, filterStatsPartner } from "./filters";
import { Modal, Box, Typography, MenuItem } from "@material-ui/core";
import { FormControl, InputLabel, Select, Checkbox, ListItemText, ListSubheader, TextField, InputAdornment, Button  } from "@mui/material";
import { ContentCopy } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";

import { useSnackbar } from "notistack";
import DateFromTo from "../../../widgets/DateFromTo/DateFromTo";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Select as UiSelect } from "../../../ui";
import useAdminUser from "../../../data/useAdminUser";

import { regionsData } from "./geolist.js";

const campaignStyles = {
  height: "100vh",
  overflowY: "scroll",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100vw",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
};

const RegionSelect = ({ defaultValue, regions, handleChange }) => {
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [openRegions, setOpenRegions] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [needUpdateRegions, setNeedUpdateRegions] = useState(false);

  useEffect(() => {
    if (defaultValue) {
      const defaultRegionIds = defaultValue.split(",").map(id => id.trim());
      setSelectedRegions(defaultRegionIds);
    }
  }, [defaultValue]);

  const toggleRegion = (regionId, isIndeterminate) => {
    if (isIndeterminate) return;

    setSelectedRegions((prevSelected) => {
      setNeedUpdateRegions(true);
      if (prevSelected.includes(regionId)) {
        return prevSelected.filter((id) => id !== regionId);
      } else {
        return [...prevSelected, regionId];
      }
    });
  };

  const handleToggleOpen = (regionId) => {
    setOpenRegions((prevOpen) => {
      if (prevOpen.includes(regionId)) {
        return prevOpen.filter((id) => id !== regionId);
      } else {
        return [...prevOpen, regionId];
      }
    });
  };

  const filterRegions = (regionsList) => {
    return regionsList.filter((region) =>
      region.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      (region.chld && filterRegions(region.chld).length > 0)
    );
  };

  const isRegionIndeterminate = (region) => {
    if (!region.chld) return false;

    const childIds = getAllRegionIds(region.chld);
    const selectedChildIds = childIds.filter(id => selectedRegions.includes(id));
    return selectedChildIds.length > 0 && selectedChildIds.length < childIds.length;
  };

  const getAllRegionIds = (regionsList) => {
    let ids = [];
    regionsList.forEach((region) => {
      ids.push(region.id);
      if (region.chld) {
        ids = [...ids, ...getAllRegionIds(region.chld)];
      }
    });
    return ids;
  };

  const renderMenuItems = (regionsList) => {
    const filteredRegions = filterRegions(regionsList);

    return filteredRegions
      .filter(region => region.name !== "Не определено" && region.name !== "Без области")
      .map((region) => {
        const isSelected = selectedRegions.includes(region.id);
        const isIndeterminate = isRegionIndeterminate(region);

        return (
          <div key={region.id}>
            <MenuItem
              onClick={() => toggleRegion(region.id, isIndeterminate)}
              style={{ display: "flex", alignItems: "center" }}
            >
              {region.chld && (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleToggleOpen(region.id);
                  }}
                  style={{
                    background: "none",
                    cursor: "pointer",
                    color: "#007bff",
                    marginRight: "10px",
                    fontSize: "20px",
                    fontWeight: 500,
                    width: 20,
                    height: 20,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {openRegions.includes(region.id) ? "-" : "+"}
                </button>
              )}
              <Checkbox
                checked={isSelected}
                indeterminate={isIndeterminate}
                onChange={() => toggleRegion(region.id, isIndeterminate)}
                style={{ pointerEvents: "none", marginRight: "8px" }}
              />
              <ListItemText primary={`${region.name} (${region.count})`} />
            </MenuItem>
            {openRegions.includes(region.id) && region.chld && (
              <Box sx={{ paddingLeft: "20px" }}>
                {renderMenuItems(region.chld)}
              </Box>
            )}
          </div>
        );
      });
  };

  const getSelectedRegionIds = (regionsList) => {
    let selected = [];

    regionsList.forEach((region) => {
      if (selectedRegions.includes(region.id)) {
        selected.push(region.id);
      }
      if (region.chld) {
        selected = [...selected, ...getSelectedRegionIds(region.chld)];
      }
    });

    return selected;
  };

  const getAllSelectedRegionNames = () => {
    const allSelectedNames = new Set();

    const collectSelectedNames = (regionsList) => {
      regionsList.forEach((region) => {
        if (selectedRegions.includes(region.id)) {
          allSelectedNames.add(region.name);
        }
        if (region.chld) {
          collectSelectedNames(region.chld);
        }
      });
    };

    collectSelectedNames(regions);
    return Array.from(allSelectedNames).join(", ");
  };

  const handleCloseListRegions = () => {
    if (!needUpdateRegions) return;
  
    const selectedRegionIds = getSelectedRegionIds(regions);
  
    const filteredRegionIds = selectedRegionIds.filter((regionId) => {
      const region = findRegionById(regions, regionId);
      return !isRegionIndeterminate(region);
    });
  
    handleChange("geo", filteredRegionIds.join(","));
    setNeedUpdateRegions(false);
  };
  
  const findRegionById = (regionsList, id) => {
    for (const region of regionsList) {
      if (region.id === id) return region;
      if (region.chld) {
        const found = findRegionById(region.chld, id);
        if (found) return found;
      }
    }
    return null;
  };

  return (
    <Box sx={{ width: 200, padding: "10px 0", textAlign: "start" }}>
      <FormControl fullWidth size="small">
        <InputLabel sx={{ lineHeight: 1, opacity: "80%", fontSize: 14 }}>
          Выбор региона
        </InputLabel>
        <Select
          multiple
          value={selectedRegions}
          renderValue={getAllSelectedRegionNames}
          onClose={handleCloseListRegions}
          style={{ height: 32, background: "#FFFFFF", borderColor: "transparent" }}
          sx={{ "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": { borderColor: "transparent" } }}
        >
          <ListSubheader>
            <TextField
              size="small"
              autoFocus
              placeholder="Поиск"
              fullWidth
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onKeyDown={(e) => {
                if (e.key !== "Escape") {
                  e.stopPropagation();
                }
              }}
            />
          </ListSubheader>
          {renderMenuItems(regions)}
        </Select>
      </FormControl>
    </Box>
  );
};





const InputCustomArea = ({ value, handleChange }) => {
  return (
    <input
      onChange={(e) => handleChange(e.target.value)}
      defaultValue={value}
      onDoubleClick={(e) => {
        const input = e.target;
        const fullTextArea = document.createElement("textarea");

        // Настройка textarea
        fullTextArea.value = input.value;
        fullTextArea.spellcheck = false;

        fullTextArea.style.width = "95vw";
        fullTextArea.style.fontFamily = "sans-serif";
        fullTextArea.style.height = "auto";
        fullTextArea.style.padding = "10px";
        fullTextArea.style.border = "1px solid #3f73f9";
        fullTextArea.style.borderRadius = "4px";
        fullTextArea.style.position = "fixed";
        fullTextArea.style.top = "30px";
        fullTextArea.style.left = "30px";
        fullTextArea.style.zIndex = 1000;
        fullTextArea.style.backgroundColor = "#fff";
        fullTextArea.style.boxShadow = "0 2px 10px rgba(0, 0, 0, 0.2)";

        fullTextArea.onblur = () => {
          fullTextArea.remove();
          input.style.display = "inline-block";
        };

        fullTextArea.oninput = (e) => {
          handleChange(e.target.value);
          input.value = e.target.value;
        };

        document.body.appendChild(fullTextArea);
        input.style.display = "none";

        fullTextArea.focus();
      }}
      style={{
        width: '100%',
        height: '30px',
        padding: '5px',
        borderRadius: '4px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      }}
    />
  );
};

const GraphComponent = ({ report_id, dates, spec }) => {
  const [specForGraph, setSpecForGraph] = useState(Number(spec));
  const [detailForGraph, setDetailForGraph] = useState(0);
  const [graphData, setGraphData] = useState([]);

  const chartOptions = ["Показы", "Клики", "Лиды", "Бюджет", "ДРР", "Доходность", "eCPC"];
  const chartOptions2 = ["Общая", "Детальная"];

  useEffect(() => {
    if (dates.date0 && dates.date1) {
      fetch(`${API_HOST}/api/v1/admin/production-reports/graphic?_id=${report_id}&date0=${dates.date0}&date1=${dates.date1}&spec=${specForGraph}&detail=${[4, 5, 6].includes(specForGraph) ? 0 : detailForGraph}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("admin_token")}`,
        },
      })
        .then((res) => res.ok && res.json())
        .then((data) => setGraphData(data));
    }
  }, [specForGraph, detailForGraph, dates, report_id]);

  return (
    <div style={{ width: "49%" }}>
      <div style={{ display: "flex" }}>
        <Box sx={{ width: 200, padding: "10px 0", textAlign: "start" }}>
          <FormControl fullWidth size="small">
            <InputLabel sx={{ lineHeight: 1, opacity: "80%", fontSize: 14 }}>График</InputLabel>
            <Select
              onChange={(e) => setSpecForGraph(chartOptions.indexOf(e.target.value))}
              value={chartOptions[specForGraph]}
              style={{ height: 32, background: "#FFFFFF", borderColor: "transparent" }}
              sx={{ "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": { borderColor: "transparent" } }}
            >
              {chartOptions.map((option) => (
                <MenuItem key={option} value={option}>{option}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        {![4, 5, 6].includes(specForGraph) && (
          <Box sx={{ width: 200, padding: "10px 0", textAlign: "start", marginLeft: 10 }}>
            <FormControl fullWidth size="small">
              <InputLabel sx={{ lineHeight: 1, opacity: "80%", fontSize: 14 }}>Детализация</InputLabel>
              <Select
                onChange={(e) => setDetailForGraph(chartOptions2.indexOf(e.target.value))}
                value={chartOptions2[detailForGraph]}
                style={{ height: 32, background: "#FFFFFF", borderColor: "transparent" }}
                sx={{ "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": { borderColor: "transparent" } }}
              >
                {chartOptions2.map((option) => (
                  <MenuItem key={option} value={option}>{option}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )}
      </div>
      {graphData.length === 0 ? (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "400px", border: "1px dashed #5b5b5b" }}>
          <p style={{ fontSize: 18 }}>Нет данных для графика.</p>
        </div>
      ) : (
        <MultiLineChartByDay data={graphData} label={chartOptions[specForGraph]} />
      )}
    </div>
  );
};


// const testDataChart2 = [
//   {
//       "date": 1728248400,
//       "value": 10
//   }, 
//   {
//       "date": 1728334800,
//       "value": 40
//   },
//   {
//       "date": 1728421200,
//       "value": 30
//   }
// ]


// const testDataChart = [
//   { name: 'PRG1', date: Math.floor(Date.now() / 1000) - 6 * 24 * 60 * 60, value: 2340 },
//   { name: 'PRG2', date: Math.floor(Date.now() / 1000) - 6 * 24 * 60 * 60, value: 1500 },
//   { name: 'PRG1', date: Math.floor(Date.now() / 1000) - 5 * 24 * 60 * 60, value: 2100 },
//   { name: 'PRG2', date: Math.floor(Date.now() / 1000) - 5 * 24 * 60 * 60, value: 1300 },
//   { name: 'PRG1', date: Math.floor(Date.now() / 1000) - 4 * 24 * 60 * 60, value: 2900 },
//   { name: 'PRG2', date: Math.floor(Date.now() / 1000) - 4 * 24 * 60 * 60, value: 1700 },
//   { name: 'PRG1', date: Math.floor(Date.now() / 1000) - 3 * 24 * 60 * 60, value: 1800 },
//   { name: 'PRG2', date: Math.floor(Date.now() / 1000) - 3 * 24 * 60 * 60, value: 2200 },
//   { name: 'PRG1', date: Math.floor(Date.now() / 1000) - 2 * 24 * 60 * 60, value: 2400 },
//   { name: 'PRG2', date: Math.floor(Date.now() / 1000) - 2 * 24 * 60 * 60, value: 1600 },
//   { name: 'PRG1', date: Math.floor(Date.now() / 1000) - 1 * 24 * 60 * 60, value: 2500 },
//   { name: 'PRG2', date: Math.floor(Date.now() / 1000) - 1 * 24 * 60 * 60, value: 1800 },
//   { name: 'PRG1', date: Math.floor(Date.now() / 1000), value: 2600 },
//   { name: 'PRG2', date: Math.floor(Date.now() / 1000), value: 1900 },
// ];



const DatePickerTest = ({ defaultValue, onChange }) => {
  const [selectedDate, setSelectedDate] = useState(defaultValue || '');

  useEffect(() => {
    if (defaultValue) {
      setSelectedDate(formatDatePicker(defaultValue));
    }
  }, [defaultValue]);

  const handleDateChange = (e) => {
    const newDate = e.target.value;
    setSelectedDate(newDate);
    
    if (onChange) {
      onChange(newDate);
    }
  };

  return (
    <div style={stylesDatePicker.datepickerContainer}>
      <input 
        type="date" 
        value={selectedDate} 
        onChange={handleDateChange} 
        style={stylesDatePicker.datepickerInput}
      />
    </div>
  );
};

const formatDatePicker = (date, istext = false) => {
  if (date === 0 || !date) return;
  const d = new Date(date * 1000);
  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, '0');
  const day = String(d.getDate()).padStart(2, '0');
  if (istext) return `${day}.${month}.${year}`
  return `${year}-${month}-${day}`;
};

const stylesDatePicker = {
  datepickerContainer: {
    padding: "5px 0 0 0",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  datepickerInput: {
    padding: '8px',
    fontSize: '14px',
    border: '2px solid #ccc',
    borderRadius: '4px',
    outline: 'none',
    transition: 'border-color 0.3s ease',
    ':hover': {
      borderColor: '#888',
    },
    ':focus': {
      borderColor: '#007bff',
    },
  },
};

const formatDate = (timestamp) => {
  const date = new Date(parseInt(timestamp) * 1000);
  return date.toLocaleDateString();
};

const MultiLineChartByDay = ({ data, label }) => {
  const isSingleLineData = data.every(item => item.name === undefined);

  let formattedData;

  if (isSingleLineData) {
    formattedData = data.map(item => ({
      name: label,
      date: formatDate(item.date),
      value: item.value,
    }));
  } else {
    formattedData = data.map(item => ({
      ...item,
      date: formatDate(item.date),
    }));
  }

  const uniqueNames = [...new Set(formattedData.map(item => item.name))];

  const groupedData = uniqueNames.map(name => {
    const valuesByDate = formattedData.filter(item => item.name === name);
    return {
      name,
      ...Object.fromEntries(valuesByDate.map(item => [item.date, item.value])),
    };
  });

  const finalData = [];
  const uniqueDates = [...new Set(formattedData.map(item => item.date))];

  uniqueDates.forEach(date => {
    const entry = { date };
    uniqueNames.forEach(name => {
      const value = groupedData.find(item => item.name === name)[date] || 0;
      entry[name] = value;
    });
    finalData.push(entry);
  });

  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart data={finalData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip />
        <Legend />
        {uniqueNames.map((name, index) => (
          <Line 
            key={name} 
            type="monotone" 
            dataKey={name} 
            name={name} 
            stroke={[
              "#e57373",
              "#f06292",
              "#ba68c8",
              "#64b5f6",
              "#4db6ac",
              "#81c784",
              "#ffd54f",
              "#ff8a65", 
              "#ffb74d",
              "#64ffda"
            ][index % 10]}
            strokeWidth={2}
            connectNulls
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};



const OpenCampaign = ({ report_id, arrStaff, setOpenModal }) => {
  const [filterArr, setFilter] = useState([]);
  const [filterArrSvod, setFilterSvod] = useState([]);
  const [filterArrDetailsSvod, setFilterDetailsSvod] = useState([]);
  const [filterArrDetails, setFilterDetails] = useState([]);
  const [goalsListOptions, setGoalsListOptions] = useState([]);
  const [filtersValue, setValue] = useState(INIT_FILTER_VALUE);
  const [listGoals, setListGoals] = useState([]);
  const { role } = useAdminUser();
  const [openDetails, setOpenDetails] = useState(false);
  const [needUpdateGoals, setNeedUpdateGoals] = useState(false);
  const [specForDetails, setSpecForDetails] = useState('PRG1');
  const [openSvod, setOpenSvod] = useState(false);
  const [svodDetails, setSvodDetails] = useState("");
  const [upd, setUpd] = useState(false);
  const debounceTimers = useRef({});

  const [searchGoals, setSearchGoals] = useState('');
  const [searchStaffAccount, setSearchStaffAccount] = useState('');
  const [searchStaffContext, setSearchStaffContext] = useState('');
  const [searchStaffTraffic, setSearchStaffTraffic] = useState('');
  const [searchStaffLead, setSearchStaffLead] = useState('');

  const [fieldsToUpdate, setFieldsToUpdate] = useState([]);
  const [closeModal, setCloseModal] = useState(false);
  const [saveFields, setSaveFields] = useState(false);
  const [graphDate, setGraphDate] = useState({
    date0: 0,
    date1: 0
  })
  const { enqueueSnackbar } = useSnackbar();


  const additionalGoals = [
    {
      name: "Лиды(Calltracking)",
      goal_id: 1,
    },
    {
      name: "Сделки(Calltracking)",
      goal_id: 2,
    }
  ];

  useEffect(() => {
    async function fetchData() {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("admin_token")
      );
      const responseHead = await fetch(`${API_HOST}/api/v1/admin/production-reports/fetch/head?_id=${report_id}`, {
        method: "GET",
        headers: myHeaders,
      });
      if (responseHead.ok) {
        const data = await responseHead.json();
        setFilter(data);
        setListGoals(data[0]?.list_of_goals.split(',').filter(Boolean));
      }
      const responseGoals = await fetch(`${API_HOST}/api/v1/admin/production-reports/get-goals?_id=${report_id}`, {
        method: "GET",
        headers: myHeaders,
      });
      if (responseGoals.ok) {
        const data = await responseGoals.json();
        setGoalsListOptions(data.concat(additionalGoals));
      }
      const responseSvod = await fetch(`${API_HOST}/api/v1/admin/production-reports/fetch/summary?_id=${report_id}`, {
        method: "GET",
        headers: myHeaders,
      });
      if (responseSvod.ok) {
        const data = await responseSvod.json();
        setFilterSvod([data]);
      }

    }
    fetchData();
    
    // setFilter(testCampaigns);
    // setFilterSvod(testCampaignsSvod);
    // setFilterDetails(testCampaignsDetails);
    // setFilterDetailsSvod(testCampaignsDetailsSvod)
  }, [upd]);

  useEffect(() => {
    async function fetchData() {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("admin_token")
      );
      if (specForDetails) {
        const responseDetails = await fetch(`${API_HOST}/api/v1/admin/production-reports/fetch/detalization?_id=${report_id}&spec=${specForDetails}`, {
          method: "GET",
          headers: myHeaders,
        });
        if (responseDetails.ok) {
          const data = await responseDetails.json();
          setFilterDetails([data]);
        }
      }
    }
    fetchData();
  }, [specForDetails, upd]);


  useEffect(() => {
    async function fetchData() {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("admin_token")
      );
      if (svodDetails) {
        const responseDetails = await fetch(`${API_HOST}/api/v1/admin/production-reports/fetch/summary-prgs?_id=${report_id}&spec=${svodDetails === "consumption" ? "budget" : svodDetails}_fact`, {
          method: "GET",
          headers: myHeaders,
        });
        if (responseDetails.ok) {
          const data = await responseDetails.json();
          setFilterDetailsSvod(
            Object.keys(data)
              .filter(key => key.startsWith('PRG'))
              .map(key => ({ prg: key, count: data[key] }))
          );
        }
      }
    }
    fetchData();
  }, [svodDetails]);

  

  useEffect(() => {
    if (closeModal) {
      updateAllFields();
      enqueueSnackbar("Отчёт успешно сохранён.", {
        variant: "success",
        autoHideDuration: 3000,
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
      setCloseModal(false);
      setOpenModal(false);
    }
  }, [closeModal]);


  useEffect(() => {
    if (saveFields) {
      updateAllFields();
      enqueueSnackbar("Отчёт успешно сохранён.", {
        variant: "success",
        autoHideDuration: 3000,
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
      setSaveFields(false);
    }
  }, [saveFields]);


  const updateAllFields = async () => {
    for (const field of fieldsToUpdate) {
      await updateField(field.field, field.value);
    }
    setFieldsToUpdate([]);
    setUpd((prev) => !prev);
  };

  const debounceUpdate = (field, callback, delay = 800) => {
    clearTimeout(debounceTimers.current[field]);
    debounceTimers.current[field] = setTimeout(() => {
      callback();
      delete debounceTimers.current[field];
    }, delay);
  };
  
  const handleChange = (field, value) => {
    console.log(field, value)
    debounceUpdate(field, () => {
      setFieldsToUpdate((prevState) => {
        const updatedFields = prevState.map(item =>
          item.field === field ? { ...item, value } : item
        );
        return updatedFields.some(item => item.field === field)
          ? updatedFields
          : [...updatedFields, { field, value }];
      });
    });
  };

  const handleChangeDetails = (field, value, index) => {
    setFilterDetails((prevState) =>
      prevState.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            [field]: value,
          };
        }
        return item;
      })
    );
  }

  const handleChangeSvodDetails = (field, value) => {
    setFilterDetailsSvod((prevState) =>
      prevState.map((item) => {
        if (item.prg === field) {
          return {
            ...item,
            count: value,
          };
        }
        return item;
      })
    );
  }
  
  const updateField = async (field, value) => {
    await fetch(`${API_HOST}/api/v1/admin/production-reports/update?_id=${report_id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("admin_token")}`,
      },
      body: JSON.stringify({ key: field.toString(), value: value }),
    });
  };





  const calltrackingOptions = ["нет, но в работе", "не нужен, ecom", "да (Calltouch)", "да (Roistat)", "да (Comagic)", "да (Mango)", "да (Callibri)", "да (Битрикс24)"];
  

  const columns = [
    {
      Header: "Проект",
      accessor: "project",
      maxWidth: 400,
      minWidth: 80,
      width: 290,
    },
    {
      Header: "Сайт проекта",
      accessor: "domain",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
    },
    {
      Header: "Дата начала цикла",
      accessor: "start_cycle_date",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
    },
    {
      Header: "Дата окончания цикла",
      accessor: "end_cycle_date",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
    },
    {
      Header: "Цель рк",
      accessor: "goal",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
    },
    {
      Header: "Гео",
      accessor: "geo",
      maxWidth: 400,
      minWidth: 80,
      width: 220,
    },
    {
      Header: "CallTracking",
      accessor: "calltracking",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
    },
    {
      Header: "Атрибуция по лидам",
      accessor: "attribution",
      maxWidth: 400,
      minWidth: 80,
      width: 270,
    },
    {
      Header: "Номера целей",
      accessor: "list_of_goals",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
    },
    {
      Header: "Целевые страницы",
      accessor: "landing_pages",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
    },
    {
      Header: "Название в Яметрике",
      accessor: "yametrika_name",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
    },
    {
      Header: "Аккаунт-менеджер",
      accessor: "account_manager",
      maxWidth: 400,
      minWidth: 80,
      width: 230,
    },
    {
      Header: "TeamLead",
      accessor: "team_lead",
      maxWidth: 400,
      minWidth: 80,
      width: 230,
    },
    {
      Header: "Контекстолог",
      accessor: "contextologist",
      maxWidth: 400,
      minWidth: 80,
      width: 230,
    },
    {
      Header: "Трафик-менеджер",
      accessor: "traffic_manager",
      maxWidth: 400,
      minWidth: 80,
      width: 230,
    }
  ];


  const columnsSvod = [
    {
      Header: "КЛИКИ план",
      accessor: "clicks",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "КЛИКИ факт",
      accessor: "clicks_fact",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "КЛИКИ % цели",
      accessor: "clicks_goal",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "ЛИДЫ план",
      accessor: "leads",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "ЛИДЫ факт",
      accessor: "leads_fact",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "ЛИДЫ % цели",
      accessor: "leads_goal",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "ЛИДЫ Квал. план",
      accessor: "leads_qual",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "ЛИДЫ Квал. факт",
      accessor: "leads_qual_fact",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "ЛИДЫ Квал. % цели",
      accessor: "leads_qual_goal",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "eCPL",
      accessor: "eCPL",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "ДОХОД план",
      accessor: "income",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "ДОХОД факт",
      accessor: "income_fact",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "ДРР, % факт",
      accessor: "drr_fact",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "БЮДЖЕТ НА ПРОИЗВОДСТВО",
      accessor: "budget",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "БЮДЖЕТ факт. расход",
      accessor: "budget_fact",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "БЮДЖЕТ % расхода планового бюджета",
      accessor: "budget_goal",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "ПОКАЗЫ план",
      accessor: "views",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "ПОКАЗЫ факт",
      accessor: "views_fact",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "ПОКАЗЫ % цели",
      accessor: "views_goal",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    }
  ];

  const columnsSvodDetails = [
    {
      Header: "PRG",
      accessor: "prg",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "Количество",
      accessor: "count",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
  ]

  function getColumnsDetails() {
    const queryName = tableDetailsOptions.find((el) => el.value === specForDetails).query;

    let columns = [
      {
        Header: "Клики",
        accessor: "clicks",
        maxWidth: 400,
        minWidth: 80,
        width: 120,
      },
      {
        Header: "Лиды",
        accessor: "leads",
        maxWidth: 400,
        minWidth: 80,
        width: 120,
      },
      {
        Header: "eCPC",
        accessor: "ecpc",
        maxWidth: 400,
        minWidth: 80,
        width: 120,
      },
    ];
  
    if (queryName === "zeropark" || queryName === "rich_ads" || queryName === "bidvert") {
      columns.unshift(
        {
          Header: "Расход (USD)",
          accessor: "consumption_dol",
          maxWidth: 400,
          minWidth: 80,
          width: 120,
        },
        {
          Header: "Расход (RUB)",
          accessor: "consumption_rub",
          maxWidth: 400,
          minWidth: 80,
          width: 120,
        }
      );
    } else {
      columns.unshift(
        {
          Header: "Расход",
          accessor: "consumption",
          maxWidth: 400,
          minWidth: 80,
          width: 120,
        },
        {
          Header: "Показы",
          accessor: "views",
          maxWidth: 400,
          minWidth: 80,
          width: 120,
        }
      );
    }
  
    return columns;
  }

  // const columnsDetails = [
  //   {
  //     Header: "Расход",
  //     accessor: "consumption",
  //     maxWidth: 400,
  //     minWidth: 80,
  //     width: 120,
  //   },
  //   {
  //     Header: "Клики",
  //     accessor: "clicks",
  //     maxWidth: 400,
  //     minWidth: 80,
  //     width: 120,
  //   },
  //   {
  //     Header: "Показы",
  //     accessor: "views",
  //     maxWidth: 400,
  //     minWidth: 80,
  //     width: 120,
  //   },
  //   {
  //     Header: "Лиды",
  //     accessor: "leads",
  //     maxWidth: 400,
  //     minWidth: 80,
  //     width: 120,
  //   },
  //   {
  //     Header: "eCPC",
  //     accessor: "ecpc",
  //     maxWidth: 400,
  //     minWidth: 80,
  //     width: 120,
  //   },
  // ];

  function getTableDataSvod() {

    return filterArrSvod.map((el) => {
      return {
        clicks: (
          <div className="td">
            <input onChange={(e) => handleChange('clicks', Number(e.target.value))} defaultValue={el.clicks} type="number" style={{ height: 0 }}/>
          </div>
        ),
        clicks_fact: (
          <div className="td" style={{ height: 30, display: "flex", alignItems: "center", gap: 5 }}>
            {el.clicks_fact}
            <button onClick={() => {setOpenSvod(true); setSvodDetails("clicks")}} style={{ padding: "3px 3px", borderRadius: 5, display: "flex", alignItems: "center", justifyContent: "center" }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000000" viewBox="0 0 256 256"><path d="M224,104a8,8,0,0,1-16,0V59.32l-66.33,66.34a8,8,0,0,1-11.32-11.32L196.68,48H152a8,8,0,0,1,0-16h64a8,8,0,0,1,8,8Zm-40,24a8,8,0,0,0-8,8v72H48V80h72a8,8,0,0,0,0-16H48A16,16,0,0,0,32,80V208a16,16,0,0,0,16,16H176a16,16,0,0,0,16-16V136A8,8,0,0,0,184,128Z"></path></svg>
            </button>
          </div>
        ),
        clicks_goal: (
          <div style={{ color: el.clicks_goal <= 100 ? "#ff4141" : "#49e02d" }} className="td">{el.clicks_goal?.toFixed(2)}%</div>
        ),
        leads: (
          <div className="td">
            <input onChange={(e) => handleChange('leads', Number(e.target.value))} defaultValue={el.leads} type="number" style={{ height: 0 }}/>
          </div>
        ),
        leads_fact: (
          <div className="td" style={{ height: 30, display: "flex", alignItems: "center", gap: 5 }}>
            {el.leads_fact}
            <button onClick={() => {setOpenSvod(true); setSvodDetails("leads")}} style={{ padding: "3px 3px", borderRadius: 5, display: "flex", alignItems: "center", justifyContent: "center" }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000000" viewBox="0 0 256 256"><path d="M224,104a8,8,0,0,1-16,0V59.32l-66.33,66.34a8,8,0,0,1-11.32-11.32L196.68,48H152a8,8,0,0,1,0-16h64a8,8,0,0,1,8,8Zm-40,24a8,8,0,0,0-8,8v72H48V80h72a8,8,0,0,0,0-16H48A16,16,0,0,0,32,80V208a16,16,0,0,0,16,16H176a16,16,0,0,0,16-16V136A8,8,0,0,0,184,128Z"></path></svg>
            </button>
          </div>
        ),
        leads_goal: (
          <div style={{ color: el.leads_goal <= 100 ? "#ff4141" : "#49e02d" }} className="td">{el.leads_goal?.toFixed(2)}%</div>
        ),
        leads_qual: (
          <div className="td">
            <input onChange={(e) => handleChange('leads_qual', Number(e.target.value))} defaultValue={el.leads_qual} type="number" style={{ height: 0 }}/>
          </div>
        ),
        leads_qual_fact: (
          <div className="td" style={{ position: "relative" }}>
            <input onChange={(e) => handleChange('leads_qual_fact', Number(e.target.value))} defaultValue={el.leads_qual_fact} type="number" style={{ height: 0 }}/>
            <button onClick={() => {setOpenSvod(true); setSvodDetails("leads_qual")}} style={{ padding: "3px 3px", borderRadius: 5, display: "flex", alignItems: "center", justifyContent: "center", position: "absolute", right: 0, top: "50%", transform: "translateY(-50%)" }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000000" viewBox="0 0 256 256"><path d="M224,104a8,8,0,0,1-16,0V59.32l-66.33,66.34a8,8,0,0,1-11.32-11.32L196.68,48H152a8,8,0,0,1,0-16h64a8,8,0,0,1,8,8Zm-40,24a8,8,0,0,0-8,8v72H48V80h72a8,8,0,0,0,0-16H48A16,16,0,0,0,32,80V208a16,16,0,0,0,16,16H176a16,16,0,0,0,16-16V136A8,8,0,0,0,184,128Z"></path></svg>
            </button>
          </div>
        ),
        leads_qual_goal: (
          <div style={{ color: el.leads_qual_goal <= 100 ? "#ff4141" : "#49e02d" }} className="td">{el.leads_qual_goal?.toFixed(2)}%</div>
        ),
        eCPL: (
          <div className="td">{el.eCPL?.toFixed(2)}</div>
        ),
        income: (
          <div className="td">
            <input onChange={(e) => handleChange('income', Number(e.target.value))} defaultValue={el.income} type="number" style={{ height: 0 }}/>
          </div>
        ),
        income_fact: (
          <div className="td">
            <input onChange={(e) => handleChange('income_fact', Number(e.target.value))} defaultValue={el.income_fact} type="number" style={{ height: 0 }}/>
          </div>
        ),
        drr_fact: (
          <div className="td">{el.drr_fact?.toFixed(2)}</div>
        ),
        budget: (
          <div className="td">
            <input onChange={(e) => handleChange('budget', Number(e.target.value))} defaultValue={el.budget} type="number" style={{ height: 0 }}/>
          </div>
        ),
        budget_fact: (
          <div className="td" style={{ height: 30, display: "flex", alignItems: "center", gap: 5 }}>
            {el.budget_fact}
            <button onClick={() => {setOpenSvod(true); setSvodDetails("consumption")}} style={{ padding: "3px 3px", borderRadius: 5, display: "flex", alignItems: "center", justifyContent: "center" }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000000" viewBox="0 0 256 256"><path d="M224,104a8,8,0,0,1-16,0V59.32l-66.33,66.34a8,8,0,0,1-11.32-11.32L196.68,48H152a8,8,0,0,1,0-16h64a8,8,0,0,1,8,8Zm-40,24a8,8,0,0,0-8,8v72H48V80h72a8,8,0,0,0,0-16H48A16,16,0,0,0,32,80V208a16,16,0,0,0,16,16H176a16,16,0,0,0,16-16V136A8,8,0,0,0,184,128Z"></path></svg>
            </button>
          </div>
        ),
        budget_goal: (
          <div style={{ color: el.budget_goal >= 100 ? "#ff4141" : "#49e02d" }} className="td">{el.budget_goal?.toFixed(2)}%</div>
        ),
        views: (
          <div className="td">
            <input onChange={(e) => handleChange('views', Number(e.target.value))} defaultValue={el.views} type="number" style={{ height: 0 }}/>
          </div>
        ),
        views_fact: (
          <div className="td" style={{ height: 30, display: "flex", alignItems: "center", gap: 5 }}>
            {el.views_fact}
            <button onClick={() => {setOpenSvod(true); setSvodDetails("views")}} style={{ padding: "3px 3px", borderRadius: 5, display: "flex", alignItems: "center", justifyContent: "center" }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000000" viewBox="0 0 256 256"><path d="M224,104a8,8,0,0,1-16,0V59.32l-66.33,66.34a8,8,0,0,1-11.32-11.32L196.68,48H152a8,8,0,0,1,0-16h64a8,8,0,0,1,8,8Zm-40,24a8,8,0,0,0-8,8v72H48V80h72a8,8,0,0,0,0-16H48A16,16,0,0,0,32,80V208a16,16,0,0,0,16,16H176a16,16,0,0,0,16-16V136A8,8,0,0,0,184,128Z"></path></svg>
            </button>
          </div>
        ),
        views_goal: (
          <div style={{ color: el.views_goal <= 100 ? "#ff4141" : "#49e02d" }} className="td">{el.views_goal?.toFixed(2)}%</div>
        ),
      };
    });
  }


  
  function getTableDataSvodDetails() {
    return filterArrDetailsSvod.map((el, index) => {
      const query = `${tableDetailsOptions.find((element) => element.value === el.prg)?.query}_${svodDetails}`
      return {
        prg: (
          <div className="td">
            {el.prg}
          </div>
        ),
        count: (
          <div className="td">
            <input type="number" onChange={(e) => {
              handleChangeSvodDetails(el.prg, Number(e.target.value), index)
              handleChange(["rich_ads_consumption", "zeropark_consumption", "bidvert_consumption"].includes(query) ? query + "_dol" : query, Number(e.target.value))
              }}
              value={el.count || ''} />
          </div>
        )
      };
    });
  }

  
  function getTableDataDetails() {
    const queryName = tableDetailsOptions.find((el) => el.value === specForDetails).query;
  
    return filterArrDetails.map((el, index) => {
        const dynamicFields = {};
        if (queryName === "zeropark" || queryName === "rich_ads" || queryName === "bidvert") {
            dynamicFields.consumption_dol = (
                <div className="td" style={{ height: 30 }}>
                    <input 
                        type="number" 
                        onChange={(e) => {
                            handleChangeDetails(`${queryName}_consumption_dol`, Number(e.target.value), index);
                            handleChange(`${queryName}_consumption_dol`, Number(e.target.value), index);
                        }}
                        value={el[`${queryName}_consumption_dol`] || ''}
                    />
                </div>
            );
            dynamicFields.consumption_rub = (
                <div className="td" style={{ height: 30, display: "flex", alignItems: "center" }}>
                  {(el[`${queryName}_consumption_rub`])?.toFixed(2) || ''}
                </div>
            );
        } else {
            dynamicFields.consumption = (
                <div className="td" style={{ height: 30 }}>
                    <input 
                        type="number" 
                        onChange={(e) => {
                            handleChangeDetails(`${queryName}_consumption`, Number(e.target.value), index);
                            handleChange(`${queryName}_consumption`, Number(e.target.value), index);
                        }}
                        value={el[`${queryName}_consumption`] || ''}
                    />
                </div>
            );
            dynamicFields.views = (
                <div className="td" style={{ height: 30 }}>
                    <input 
                        type="number" 
                        onChange={(e) => {
                            handleChangeDetails(`${queryName}_views`, Number(e.target.value), index);
                            handleChange(`${queryName}_views`, Number(e.target.value), index);
                        }}
                        value={el[`${queryName}_views`] || ''}
                    />
                </div>
            );
        }
        
        return {
            ...dynamicFields,
            clicks: (
                <div className="td" style={{ height: 30 }}>
                    <input 
                        type="number" 
                        onChange={(e) => {
                            handleChangeDetails(`${queryName}_clicks`, Number(e.target.value), index);
                            handleChange(`${queryName}_clicks`, Number(e.target.value), index);
                        }}
                        value={el[`${queryName}_clicks`] || ''}
                    />
                </div>
            ),
            leads: (
                <div className="td" style={{ height: 30 }}>
                    <input 
                        type="number" 
                        onChange={(e) => {
                            handleChangeDetails(`${queryName}_leads`, Number(e.target.value), index);
                            handleChange(`${queryName}_leads`, Number(e.target.value), index);
                        }}
                        value={el[`${queryName}_leads`] || ''}
                    />
                </div>
            ),
            ecpc: (
                <div className="td" style={{ height: 30, display: "flex", alignItems: "center" }}>
                    {el[`${queryName}_ecpc`]?.toFixed(2)}
                </div>
            ),
        };
    });
}



  const filteredGoals = goalsListOptions.filter((goal) =>
    goal.name.toLowerCase().includes(searchGoals.toLowerCase())
  );

  const filteredStaffAccount = arrStaff.filter((employee) =>
    employee.full_name?.toLowerCase().includes(searchStaffAccount.toLowerCase())
  );

  const filteredStaffLead = arrStaff.filter((employee) =>
    employee.full_name?.toLowerCase().includes(searchStaffLead.toLowerCase())
  );

  const filteredStaffContext = arrStaff.filter((employee) =>
    employee.full_name?.toLowerCase().includes(searchStaffContext.toLowerCase())
  );

  const filteredStaffTraffic = arrStaff.filter((employee) =>
    employee.full_name?.toLowerCase().includes(searchStaffTraffic.toLowerCase())
  );


  const goalsOptions = ["Лидогенерация", "Охват", "ДРР"]
  const attributionsOptions = ["Автоматическая атрибуция", "Последний значимый источник", "Последний переход из Директа", "Последний источник", "Последний значимый источник (Кросс-девайс)", "Последний переход из Директа (Кросс-девайс)", "Последний источник (Кросс-девайс)", "Первый источник трафика", "Первый источник трафика (Кросс-девайс)", "Один из источников трафика"]

  const handleChangeListGoals = (event) => {
    const {
      target: { value },
    } = event;
    setListGoals(
      typeof value === 'string' ? value.split(',').filter(Boolean) : value,
    );
    setNeedUpdateGoals(true)
  };
  
  const handleCloseListGoals = () => {
    if (!needUpdateGoals) return;

    const filteredGoals = listGoals.filter(goal => goal);
    handleChange('list_of_goals', filteredGoals.join(','));

    setNeedUpdateGoals(false)
  }

  function getTableData() {

    return filterArr.map((el) => {
      return {
        project: (
          <div className="td" style={{ height: 30 }}>
            <InputCustomArea value={el.project} handleChange={(value) => handleChange('project', value)} />
          </div>
        ),
        domain: <div className="td">{el.domain}</div>,
        goal: (
          <Box sx={{ width: 200, textAlign: "start", padding: "2px 0", height: 30 }}>
            <FormControl fullWidth size="small">
              <InputLabel
                id="demo-select-small-label"
                sx={{ lineHeight: 1, opacity: "80%", fontSize: 14 }}
              >
                Цель РК
              </InputLabel>
              <Select
                defaultValue={el.goal || "0"}
                onChange={(e) => handleChange('goal', e.target.value)}
                style={{
                  height: 32,
                  margin: "0 10px",
                  background: "#FFFFFF",
                  borderColor: "transparent",
                }}
                sx={{
                  "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                    borderColor: "transparent",
                  },
                }}
                label="Цель РК"
                id="demo-select-small"
              >
                <MenuItem value="0">Нет</MenuItem>
                {goalsOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        ),
        calltracking: (
          <Box sx={{ width: 200, textAlign: "start", padding: "2px 0", height: 30 }}>
            <FormControl fullWidth size="small">
              <InputLabel
                id="demo-select-small-label"
                sx={{ lineHeight: 1, opacity: "80%", fontSize: 14 }}
              >
                CallTracking
              </InputLabel>
              <Select
                onChange={(e) => handleChange('calltracking', e.target.value)}
                defaultValue={el.calltracking || "нет, не планируют"}
                style={{
                  height: 32,
                  margin: "0 10px",
                  background: "#FFFFFF",
                  borderColor: "transparent",
                }}
                sx={{
                  "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                    borderColor: "transparent",
                  },
                }}
                label="CallTracking"
                id="demo-select-small"
              >
                <MenuItem value="нет, не планируют">нет, не планируют</MenuItem>
                {calltrackingOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        ),
        attribution: (
          <Box sx={{ width: 240, textAlign: "start", padding: "2px 0", height: 30 }}>
            <FormControl fullWidth size="small">
              <InputLabel
                id="demo-select-small-label"
                sx={{ lineHeight: 1, opacity: "80%", fontSize: 14 }}
              >
                Атрибуция по лидам
              </InputLabel>
              <Select
                onChange={(e) => handleChange('attribution', e.target.value)}
                defaultValue={el.attribution || "Нет"}
                style={{
                  height: 32,
                  margin: "0 10px",
                  background: "#FFFFFF",
                  borderColor: "transparent",
                }}
                sx={{
                  "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                    borderColor: "transparent",
                  },
                }}
                label="CallTracking"
                id="demo-select-small"
              >
                <MenuItem value="Нет">Нет</MenuItem>
                {attributionsOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        ),
        list_of_goals: (
          <Box sx={{ width: 200, textAlign: "start", padding: "2px 0", height: 30 }}>
            <FormControl fullWidth size="small">
              <InputLabel
                id="demo-select-small-label"
                sx={{ lineHeight: 1, opacity: "80%", fontSize: 14 }}
              >
                Список целей
              </InputLabel>
              <Select
                style={{
                  margin: "0 10px",
                  fontSize: 14,
                  height: 32,
                  background: "#FFFFFF",
                  borderColor: "transparent",
                }}
                sx={{
                  "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                    borderColor: "transparent",
                  },
                }}
                placeholder="Список целей"
                multiple
                value={listGoals || []}
                renderValue={(selected) => selected.join(', ')}
                onChange={handleChangeListGoals}
                onClose={handleCloseListGoals}
              >
                <ListSubheader>
                  <TextField
                    size="small"
                    autoFocus
                    placeholder="Поиск"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => setSearchGoals(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Backspace") {
                        e.target.focus();
                      }
                      if (e.key !== "Escape") {
                        e.stopPropagation();
                      }
                    }}
                  />
                </ListSubheader>
                {Array.isArray(filteredGoals) && filteredGoals.length > 0 ? (
                  filteredGoals.map((e) => {
                    const goalIndex = goalsListOptions.findIndex((goal) => goal.goal_id === e.goal_id);
                    const displayValue = `${goalIndex + 1} - ${e.name} - ${e.goal_id}`;
                    return (
                      <MenuItem style={{ display: "flex", gap: 10 }} key={e.goal_id.toString()} value={e.goal_id.toString()}>
                        <Checkbox checked={listGoals.includes(e.goal_id.toString())} />
                        <ListItemText style={{ fontSize: 14 }} primary={displayValue} />
                      </MenuItem>
                    )
                  })
                ) : (
                  <MenuItem disabled>
                    <ListItemText style={{ fontSize: 14 }} primary="Нет доступных целей" />
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Box>
        ),
        geo: <RegionSelect defaultValue={el.geo} regions={regionsData} handleChange={handleChange} />,
        landing_pages: <div className="td" style={{ height: 30 }}>
          <InputCustomArea value={el.landing_pages} handleChange={(value) => handleChange('landing_pages', value)} />
        </div>,
        yametrika_name: <div className="td" style={{ height: 30 }}>
          <InputCustomArea value={el.yametrika_name} handleChange={(value) => handleChange('yametrika_name', value)} />
        </div>,
        account_manager: (
          <Box sx={{ width: 220, textAlign: "start", padding: "2px 0", height: 30 }}>
            <FormControl fullWidth size="small">
              <InputLabel
                id="demo-select-small-label"
                sx={{ lineHeight: 1, opacity: "80%", fontSize: 14 }}
              >
                Аккаунт менеджер
              </InputLabel>
              <Select
                onChange={(e) => handleChange('account_manager', e.target.value === "Нет" ? null : e.target.value)}
                defaultValue={el.account_manager || "Нет"}
                style={{
                  height: 32,
                  margin: "0 10px",
                  background: "#FFFFFF",
                  borderColor: "transparent",
                }}
                sx={{
                  "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                    borderColor: "transparent",
                  },
                }}
                label="CallTracking"
                id="demo-select-small"
                onClosed={() => setSearchStaffAccount("")}
              >
                <ListSubheader>
                  <TextField
                    size="small"
                    autoFocus
                    placeholder="Поиск"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => setSearchStaffAccount(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Backspace" && filteredStaffAccount === "") {
                        e.preventDefault();
                      }
                      if (e.key !== "Escape") {
                        e.stopPropagation();
                      }
                    }}
                  />
                </ListSubheader>
                <MenuItem value="Нет">Нет</MenuItem>
                {filteredStaffAccount.map((staff) => (
                  <MenuItem key={staff.id} value={staff.id}>
                    {staff.full_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        ),
        team_lead: (
          <Box sx={{ width: 220, textAlign: "start", padding: "2px 0", height: 30 }}>
            <FormControl fullWidth size="small">
              <InputLabel
                id="demo-select-small-label"
                sx={{ lineHeight: 1, opacity: "80%", fontSize: 14 }}
              >
                TeamLead
              </InputLabel>
              <Select
                onChange={(e) => handleChange('team_lead', e.target.value === "Нет" ? null : e.target.value)}
                defaultValue={el.team_lead || "Нет"}
                style={{
                  height: 32,
                  margin: "0 10px",
                  background: "#FFFFFF",
                  borderColor: "transparent",
                }}
                sx={{
                  "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                    borderColor: "transparent",
                  },
                }}
                label="CallTracking"
                id="demo-select-small"
                onClosed={() => setSearchStaffLead("")}
              >
                <ListSubheader>
                  <TextField
                    size="small"
                    autoFocus
                    placeholder="Поиск"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => setSearchStaffLead(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Backspace" && filteredStaffLead === "") {
                        e.preventDefault();
                      }
                      if (e.key !== "Escape") {
                        e.stopPropagation();
                      }
                    }}
                  />
                </ListSubheader>
                <MenuItem value="Нет">Нет</MenuItem>
                {filteredStaffLead.map((staff) => (
                  <MenuItem key={staff.id} value={staff.id}>
                    {staff.full_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        ),
        contextologist: (
          <Box sx={{ width: 220, textAlign: "start", padding: "2px 0", height: 30 }}>
            <FormControl fullWidth size="small">
              <InputLabel
                id="demo-select-small-label"
                sx={{ lineHeight: 1, opacity: "80%", fontSize: 14 }}
              >
                Контекстолог
              </InputLabel>
              <Select
                onChange={(e) => handleChange('contextologist', e.target.value === "Нет" ? null : e.target.value)}
                defaultValue={el.contextologist || "Нет"}
                style={{
                  height: 32,
                  margin: "0 10px",
                  background: "#FFFFFF",
                  borderColor: "transparent",
                }}
                sx={{
                  "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                    borderColor: "transparent",
                  },
                }}
                label="CallTracking"
                id="demo-select-small"
                onClosed={() => setSearchStaffContext("")}
              >
                <ListSubheader>
                  <TextField
                    size="small"
                    autoFocus
                    placeholder="Поиск"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => setSearchStaffContext(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Backspace" && filteredStaffContext === "") {
                        e.preventDefault();
                      }
                      if (e.key !== "Escape") {
                        e.stopPropagation();
                      }
                    }}
                  />
                </ListSubheader>
                <MenuItem value="Нет">Нет</MenuItem>
                {filteredStaffContext.map((staff) => (
                  <MenuItem key={staff.id} value={staff.id}>
                    {staff.full_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        ),
        traffic_manager: (
          <Box sx={{ width: 220, textAlign: "start", padding: "2px 0", height: 30 }}>
            <FormControl fullWidth size="small">
              <InputLabel
                id="demo-select-small-label"
                sx={{ lineHeight: 1, opacity: "80%", fontSize: 14 }}
              >
                Трафик менеджер
              </InputLabel>
              <Select
                onChange={(e) => handleChange('traffic_manager', e.target.value === "Нет" ? null : e.target.value)}
                defaultValue={el.traffic_manager || "Нет"}
                style={{
                  height: 32,
                  margin: "0 10px",
                  background: "#FFFFFF",
                  borderColor: "transparent",
                }}
                sx={{
                  "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                    borderColor: "transparent",
                  },
                }}
                label="CallTracking"
                id="demo-select-small"
                onClosed={() => setSearchStaffTraffic("")}
              >
                <ListSubheader>
                  <TextField
                    size="small"
                    autoFocus
                    placeholder="Поиск"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => setSearchStaffTraffic(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Backspace" && filteredStaffTraffic === "") {
                        e.preventDefault();
                      }
                      if (e.key !== "Escape") {
                        e.stopPropagation();
                      }
                    }}
                  />
                </ListSubheader>
                <MenuItem value="Нет">Нет</MenuItem>
                {filteredStaffTraffic.map((staff) => (
                  <MenuItem key={staff.id} value={staff.id}>
                    {staff.full_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        ),
        start_cycle_date: <div className="td" style={{ height: 40 }}>
          <DatePickerTest defaultValue={el.start_cycle_date} onChange={(date) => handleChange('start_cycle_date', Math.floor(new Date(date).getTime() / 1000))} />
        </div>,
        end_cycle_date: <div className="td" style={{ height: 40 }}>
          <DatePickerTest defaultValue={el.end_cycle_date} onChange={(date) => handleChange('end_cycle_date', Math.floor(new Date(date).getTime() / 1000))} />
        </div>,
      };
    });
  }


  

  const tableDetailsOptions = [
    {
      name: "Я.ПОИСК PRG1",
      value: "PRG1",
      query: "yasearch"
    },
    {
      name: "РСЯ PRG2",
      value: "PRG2",
      query: "rsy"
    },
    {
      name: "ЯНДЕКС РЕТАРГЕТ PRG10",
      value: "PRG10",
      query: "yaretarget"
    },
    {
      name: "ЯНДЕКС ДПО PRG11",
      value: "PRG11",
      query: "yadpo"
    },
    {
      name: "ТОВАРКА ИЛИ МК PRG12",
      value: "PRG12",
      query: "tov_or_mk"
    },
    {
      name: "ЯНДЕКС СМАРТ PRG22",
      value: "PRG22",
      query: "yasmart"
    },
    {
      name: "ИНВОЛТРА PRG8",
      value: "PRG8",
      query: "involtra"
    },
    {
      name: "RICHADS ₽ PRG81",
      value: "PRG81",
      query: "rich_ads"
    },
    {
      name: "БИДВЕРТ ₽ PRG84",
      value: "PRG84",
      query: "bidvert"
    },
    {
      name: "ZEROPARK ₽ PRG82",
      value: "PRG82",
      query: "zeropark"
    },
  ]

  const handleDateChange = (value) => {
    const startDate = new Date(value[0]);
    const endDate = new Date(value[1]);
  
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(0, 0, 0, 0);
  
    setGraphDate(() => ({
      date0: Math.floor(startDate.getTime() / 1000),
      date1: Math.floor(endDate.getTime() / 1000),
    }));
  };

  // const handleWheel = (e) => {
  //   e.preventDefault();
  //   const scrollAmount = e.deltaY * 0.8;
  //   e.currentTarget.scrollLeft += scrollAmount;
  // };

  const svodDetailsKeys = {
    leads_qual_fact: "Лиды Квал. факт",
    clicks: "КЛИКИ факт",
    leads: "ЛИДЫ факт",
    consumption: "БЮДЖЕТ факт. расход",
    views: "ПОКАЗЫ факт",
  }

  return (
    <div>
      <button onClick={() => setSaveFields(true)} style={{ backgroundColor: "#dfe8ff", color: "#3f73f9", padding: "5px 10px", height: 35, borderRadius: 5, position: "absolute", right: 190, top: 30 }}>Сохранить</button>
      <button onClick={() => setCloseModal(true)} style={{ backgroundColor: "#dfe8ff", color: "#3f73f9", padding: "5px 10px", height: 35, borderRadius: 5, position: "absolute", right: 30, top: 30 }}>Сохранить и выйти</button>

      <div style={{ width: "100%", overflowX: "auto", paddingBottom: "20px", scrollbarWidth: "none" }}>
        <DataTable data={getTableData()} columns={columns} />
      </div>

      <div>
        <h2 style={{ marginBottom: "0px" }}>Свод</h2>
        <div style={{ width: "100%", overflowX: "auto", paddingBottom: "20px", scrollbarWidth: "none" }}>
          <DataTable data={getTableDataSvod()} columns={columnsSvod} />
        </div>
      </div>

      {
        openSvod && (
          <div style={{ marginBottom: "20px" }}>

            <div style={{ display: "flex", gap: "50px", marginBottom: "10px" }}>
              <h2 style={{ marginBottom: "20px" }}>{svodDetailsKeys[svodDetails]}</h2>
              <button onClick={() => setOpenSvod(false)} style={{ backgroundColor: "#dfe8ff", color: "#3f73f9", padding: "5px 20px", height: 30, fontSize: 14, borderRadius: 5 }}>Закрыть детализацию</button>
            </div>

            <DataTable data={getTableDataSvodDetails()} columns={columnsSvodDetails} />
          </div>
        )
      }

      {
        openDetails && (
          <div>
            <div style={{ display: "flex", gap: "20px", marginBottom: "10px", alignItems: "center" }}>
              <h2 style={{ marginBottom: "20px" }}>Детализация</h2>
              <Box sx={{ width: 280, padding: "10px 0", textAlign: "start" }}>
                <FormControl fullWidth size="small">
                  <InputLabel
                    id="demo-select-small-label"
                    sx={{ lineHeight: 1, opacity: "80%", fontSize: 14 }}
                  >
                    Таблица
                  </InputLabel>
                  <Select
                    defaultValue={"PRG1"}
                    onChange={(e) => {
                      setSpecForDetails(e.target.value);
                    }}
                    style={{
                      height: 32,
                      background: "#FFFFFF",
                      borderColor: "transparent",
                    }}
                    sx={{
                      "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                        borderColor: "transparent",
                      },
                    }}
                    label="CallTracking"
                    id="demo-select-small"
                  >
                    {tableDetailsOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>
            <div>
              <div style={{ width: "100%", overflowX: "auto", paddingBottom: "20px", scrollbarWidth: "none" }}>
                <DataTable data={getTableDataDetails()} columns={getColumnsDetails()} />
              </div>
            </div>
          </div>
        )
      }
      <div style={{ width: "100%", display: "flex", justifyContent: "center", margin: "0px 0 35px" }}>
        <button style={{ backgroundColor: "#dfe8ff", color: "#3f73f9", padding: "5px 30px", borderRadius: 5 }} onClick={() => setOpenDetails(!openDetails)}>{openDetails ? "Скрыть детализацию" : "Раскрыть детализацию"}</button>
      </div>

      <div style={{ margin: "20px 0" }}>
        <div style={{ display: "flex", gap: "20px", marginBottom: "10px" }}>
          <h2 style={{ marginBottom: "20px" }}>График</h2>
          <div style={{ width: "500px" }}>
            <DateFromTo
              from={new Date(new Date().setHours(0, 0, 0, 0) - 7 * 24 * 60 * 60 * 1000).getTime()}
              to={new Date(new Date().setHours(23, 59, 59, 999)).getTime()}
              handleChange={handleDateChange}
            />
          </div>
        </div>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <GraphComponent report_id={report_id} dates={graphDate} spec={2} />  
          <GraphComponent report_id={report_id} dates={graphDate} spec={3} />  
        </div>
      </div>
    </div>
  )
}



const OpenModal = ({ openModal, setOpenModal, type, report_id, arrStaff }) => {
  let modalContent;

  const ModalStats = ({ openModal, setOpenModal, children }) => {
    const closeModal = () => setOpenModal(false);
  
    const modalStyles = {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: openModal ? 'flex' : 'none',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 999,
    };
  
    const modalContentStyles = {
      backgroundColor: '#fff',
      width: '80%',
      maxWidth: 400,
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    };
  
    return (
      <div style={modalStyles} onClick={closeModal}>
        <div style={modalContentStyles} onClick={(e) => e.stopPropagation()}>
          {children}
        </div>
      </div>
    );
  };

  switch (type) {
    case "detail":
      modalContent = (
        <ModalStats
          openModal={openModal}
          setOpenModal={() => setOpenModal(false)}
        >
          <Box sx={campaignStyles}>
            <div  style={{ display: "flex", justifyContent: "space-between", width: "100%", marginBottom: 30 }}>
              <h2>
                Шапка проекта
              </h2>
            </div>

            {report_id && <OpenCampaign report_id={report_id} arrStaff={arrStaff} setOpenModal={setOpenModal} />}
          </Box>
        </ModalStats>
      );
      break;

    default:
      modalContent = null;
  }

  return modalContent;
};

function CampaignsReports() {
  const [arrStaff, setStaff] = useState([]);
  const [filterArr, setFilter] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [report_id, setReport_id] = useState(null);
  const [searchValue, setSearchValue] = useState({
    project: '',
    domain: '',
    manager: ''
  });
  const [upd, setUpd] = useState(false);

  useEffect(() => {
    if (openModal) return;
    async function fetchData() {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("admin_token")
      );
      const res = await fetch(`${API_HOST}/api/v1/admin/production-reports/fetch/head`, {
        method: "GET",
        headers: myHeaders,
      });
      if (res.ok) {
        const data = await res.json();
        setFilter(data);
      }
    }
    fetchData();
  }, [openModal, upd]);

  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("admin_token")
    );
    fetch(`${API_HOST}/api/v1/admin/employee`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({}),
    }).then((res) => {
      if (res.ok) {
        res.json().then((data) => {
          setStaff(data);
        });
      }
    });
  }, []);

  function getFilteredData() {
    return filterArr.filter((el) => {
      const projectMatches = searchValue.project
        ? el.project?.toLowerCase().includes(searchValue.project.toLowerCase())
        : true;
      const domainMatches = searchValue.domain
        ? el.domain?.toLowerCase().includes(searchValue.domain.toLowerCase())
        : true;
      const managerMatches = searchValue.manager
        ? arrStaff.find(
            (staff) =>
              staff.id === el.account_manager ||
              staff.id === el.team_lead ||
              staff.id === el.contextologist ||
              staff.id === el.traffic_manager
          )?.full_name.toLowerCase().includes(searchValue.manager.toLowerCase())
        : true;

      return projectMatches && domainMatches && managerMatches;
    });
  }


  const columns = [
    {
      Header: "Открыть",
      accessor: "open",
      maxWidth: 400,
      minWidth: 80,
      width: 130,
    },
    {
      Header: "Проект",
      accessor: "project",
      maxWidth: 400,
      minWidth: 80,
      width: 290,
    },
    {
      Header: "Сайт проекта",
      accessor: "domain",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
    },
    {
      Header: "Дата начала цикла",
      accessor: "start_cycle_date",
      maxWidth: 400,
      minWidth: 80,
      width: 130,
    },
    {
      Header: "Дата окончания цикла",
      accessor: "end_cycle_date",
      maxWidth: 400,
      minWidth: 80,
      width: 130,
    },
    {
      Header: "КЛИКИ план",
      accessor: "clicks",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "КЛИКИ факт",
      accessor: "clicks_fact",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "КЛИКИ % цели",
      accessor: "clicks_goal",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "ЛИДЫ план",
      accessor: "leads",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "ЛИДЫ факт",
      accessor: "leads_fact",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "ЛИДЫ % цели",
      accessor: "leads_goal",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "ДОХОД план",
      accessor: "income",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "ДОХОД факт",
      accessor: "income_fact",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "ДРР, % факт",
      accessor: "drr_fact",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "БЮДЖЕТ % расхода планового бюджета",
      accessor: "budget_goal",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "Аккаунт-менеджер",
      accessor: "account_manager",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
    },
    {
      Header: "TeamLead",
      accessor: "team_lead",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
    },
    {
      Header: "Контекстолог",
      accessor: "contextologist",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
    },
    {
      Header: "Трафик-менеджер",
      accessor: "traffic_manager",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
    },
    {
      Header: "Удалить проект",
      accessor: "delete",
      maxWidth: 400,
      minWidth: 80,
      width: 130,
    },
    {
      Header: "Дублировать проект",
      accessor: "dublicate",
      maxWidth: 400,
      minWidth: 80,
      width: 130,
    },
  ];

  const deleteProject = (project_id) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("admin_token")
    );
    fetch(`${API_HOST}/api/v1/admin/production-reports/delete?_id=${project_id}`, {
      method: "POST",
      headers: myHeaders,
    }).then((res) => {
      if (res.ok) {
        res.json().then(() => {
          setUpd((prev) => !prev);
        });
      }
    });
  }

  const dublicateProject = (project_id) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("admin_token")
    );
    fetch(`${API_HOST}/api/v1/admin/production-reports/dublicate?_id=${project_id}`, {
      method: "POST",
      headers: myHeaders
    }).then((res) => {
      if (res.ok) {
        res.json().then(() => {
          setUpd((prev) => !prev);
        });
      }
    });
  }


  function getTableData() {
    return getFilteredData().map((el) => {
      return {
        project: <div className="td">{el.project}</div>,
        domain: <div className="td">{el.domain}</div>,
        start_cycle_date: <div className="td">{formatDatePicker(el.start_cycle_date, true)}</div>,
        end_cycle_date: <div className="td">{formatDatePicker(el.end_cycle_date, true)}</div>,
        clicks: <div className="td">{el.clicks}</div>,
        clicks_fact: <div className="td">{el.clicks_fact?.toFixed(2)}</div>,
        clicks_goal: <div style={{ color: el.clicks_goal <= 100 ? "#ff4141" : "#49e02d" }} className="td">{el.clicks_goal?.toFixed(2)}%</div>,
        leads: <div className="td">{el.leads}</div>,
        leads_fact: <div className="td">{el.leads_fact?.toFixed(2)}</div>,
        leads_goal: <div style={{ color: el.leads_goal <= 100 ? "#ff4141" : "#49e02d" }} className="td">{el.leads_goal?.toFixed(2)}%</div>,
        income: <div className="td">{el.income?.toFixed(2)}</div>,
        income_fact: <div className="td">{el.income_fact?.toFixed(2)}</div>,
        drr_fact: <div className="td">{el.drr_fact?.toFixed(2)}</div>,
        budget_goal: <div style={{ color: el.budget_goal >= 100 ? "#ff4141" : "#49e02d" }} className="td">{el.budget_goal?.toFixed(2)}%</div>,
        account_manager: <div className="td">{arrStaff.find((staff) => staff.id === el.account_manager)?.full_name}</div>,
        team_lead: <div className="td">{arrStaff.find((staff) => staff.id === el.team_lead)?.full_name}</div>,
        contextologist: <div className="td">{arrStaff.find((staff) => staff.id === el.contextologist)?.full_name}</div>,
        traffic_manager: <div className="td">{arrStaff.find((staff) => staff.id === el.traffic_manager)?.full_name}</div>,
        open: (
          <button
            onClick={() => {
              setReport_id(el.id)
              setOpenModal(true)
              setModalType("detail")
            }}
            style={{padding: "5px 10px", fontSize: "12px", fontWeight: "500", borderRadius: 5, background: "#DFE8FF", color: "#3F73F9"}}>
            Открыть 
          </button>
        ),
        delete: (
          <button
            onClick={() => {
              confirm("Вы уверены что хотите удалить проект - " + el.project + " ?") && deleteProject(el.id)
            }}
            style={{padding: "5px 10px", fontSize: "12px", fontWeight: "500", borderRadius: 5, background: "#DFE8FF", color: "#3F73F9"}}>
            Удалить 
          </button>
        ),
        dublicate: (
          <button
            onClick={() => {
              confirm("Вы уверены что хотите создать дубликат проекта - " + el.project + " ?") && dublicateProject(el.id)
            }}
            style={{padding: "5px 10px", fontSize: "12px", fontWeight: "500", borderRadius: 5, background: "#DFE8FF", color: "#3F73F9"}}>
            Дублировать
          </button>
        ),
      };
    });
  }
  return (
    <div className="logs">
      <div className="filters">
        <input
          onChange={(e) =>
            setSearchValue((prev) => ({ ...prev, project: e.target.value }))
          }
          className="searchEmploee"
          placeholder="По названию проекта"
        />
        <input
          onChange={(e) =>
            setSearchValue((prev) => ({ ...prev, domain: e.target.value }))
          }
          className="searchEmploee"
          placeholder="По сайту проекта"
        />
        <input
          onChange={(e) =>
            setSearchValue((prev) => ({ ...prev, manager: e.target.value }))
          }
          className="searchEmploee"
          placeholder="По сотруднику"
        />
      </div>
      <DataTable data={getTableData()} columns={columns} />
      <OpenModal openModal={openModal} setOpenModal={setOpenModal} type={modalType} report_id={report_id} arrStaff={arrStaff} />
    </div>
  );
}

export default CampaignsReports;
