export const regionsData = [
  {
      "id": "225",
      "name": "Россия",
      "count": 3651,
      "chld": [
          {
              "id": "1",
              "name": "Москва и Московская область",
              "count": 1384,
              "chld": [
                  {
                      "id": "213",
                      "name": "Москва",
                      "count": 1066
                  },
                  {
                      "id": "10758",
                      "name": "Химки",
                      "count": 25
                  },
                  {
                      "id": "10725",
                      "name": "Домодедово",
                      "count": 12
                  },
                  {
                      "id": "21623",
                      "name": "Ивантеевка",
                      "count": 10
                  },
                  {
                      "id": "10716",
                      "name": "Балашиха",
                      "count": 8
                  },
                  {
                      "id": "10740",
                      "name": "Мытищи",
                      "count": 8
                  },
                  {
                      "id": "10743",
                      "name": "Одинцово",
                      "count": 5
                  },
                  {
                      "id": "10719",
                      "name": "Видное",
                      "count": 4
                  },
                  {
                      "id": "20571",
                      "name": "Жуковский",
                      "count": 4
                  },
                  {
                      "id": "10729",
                      "name": "Звенигород",
                      "count": 3
                  },
                  {
                      "id": "10735",
                      "name": "Красногорск",
                      "count": 3
                  },
                  {
                      "id": "21619",
                      "name": "Фрязино",
                      "count": 3
                  },
                  {
                      "id": "10715",
                      "name": "Апрелевка",
                      "count": 2
                  },
                  {
                      "id": "10731",
                      "name": "Истра",
                      "count": 2
                  },
                  {
                      "id": "20728",
                      "name": "Королёв",
                      "count": 2
                  },
                  {
                      "id": "10747",
                      "name": "Подольск",
                      "count": 2
                  },
                  {
                      "id": "10750",
                      "name": "Раменское",
                      "count": 2
                  },
                  {
                      "id": "10722",
                      "name": "Воскресенск",
                      "count": 1
                  },
                  {
                      "id": "21627",
                      "name": "Дедовск",
                      "count": 1
                  },
                  {
                      "id": "21735",
                      "name": "Дзержинский",
                      "count": 1
                  },
                  {
                      "id": "214",
                      "name": "Долгопрудный",
                      "count": 1
                  },
                  {
                      "id": "215",
                      "name": "Дубна",
                      "count": 1
                  },
                  {
                      "id": "10727",
                      "name": "Егорьевск",
                      "count": 1
                  },
                  {
                      "id": "10733",
                      "name": "Клин",
                      "count": 1
                  },
                  {
                      "id": "21647",
                      "name": "Краснознаменск",
                      "count": 1
                  },
                  {
                      "id": "21641",
                      "name": "Лобня",
                      "count": 1
                  },
                  {
                      "id": "10738",
                      "name": "Люберцы",
                      "count": 1
                  },
                  {
                      "id": "10746",
                      "name": "Павловский Посад",
                      "count": 1
                  },
                  {
                      "id": "10756",
                      "name": "Ступино",
                      "count": 1
                  },
                  {
                      "id": "10765",
                      "name": "Щёлково",
                      "count": 1
                  },
                  {
                      "id": "37120",
                      "name": "Электрогорск",
                      "count": 1
                  },
                  {
                      "id": "20523",
                      "name": "Электросталь",
                      "count": 1
                  }
              ]
          },
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 751
          },
          {
              "id": "10174",
              "name": "Санкт-Петербург и Ленинградская область",
              "count": 285,
              "chld": [
                  {
                      "id": "2",
                      "name": "Санкт-Петербург",
                      "count": 228
                  },
                  {
                      "id": "118936",
                      "name": "Мурино",
                      "count": 13
                  },
                  {
                      "id": "10871",
                      "name": "Кириши",
                      "count": 1
                  },
                  {
                      "id": "118515",
                      "name": "Кудрово",
                      "count": 1
                  },
                  {
                      "id": "10877",
                      "name": "Никольское",
                      "count": 1
                  },
                  {
                      "id": "10878",
                      "name": "Отрадное",
                      "count": 1
                  },
                  {
                      "id": "10891",
                      "name": "Сосновый Бор",
                      "count": 1
                  },
                  {
                      "id": "10892",
                      "name": "Тихвин",
                      "count": 1
                  }
              ]
          },
          {
              "id": "10776",
              "name": "Рязанская область",
              "count": 102,
              "chld": [
                  {
                      "id": "10777",
                      "name": "Сасово",
                      "count": 81
                  },
                  {
                      "id": "11",
                      "name": "Рязань",
                      "count": 21
                  }
              ]
          },
          {
              "id": "11162",
              "name": "Свердловская область",
              "count": 96,
              "chld": [
                  {
                      "id": "54",
                      "name": "Екатеринбург",
                      "count": 84
                  },
                  {
                      "id": "20256",
                      "name": "Сухой Лог",
                      "count": 7
                  },
                  {
                      "id": "20720",
                      "name": "Верхняя Пышма",
                      "count": 1
                  },
                  {
                      "id": "11164",
                      "name": "Каменск-Уральский",
                      "count": 1
                  },
                  {
                      "id": "11170",
                      "name": "Новоуральск",
                      "count": 1
                  }
              ]
          },
          {
              "id": "11316",
              "name": "Новосибирская область",
              "count": 86,
              "chld": [
                  {
                      "id": "65",
                      "name": "Новосибирск",
                      "count": 83
                  }
              ]
          },
          {
              "id": "11010",
              "name": "Республика Дагестан",
              "count": 73,
              "chld": [
                  {
                      "id": "28",
                      "name": "Махачкала",
                      "count": 72
                  },
                  {
                      "id": "11008",
                      "name": "Каспийск",
                      "count": 1
                  }
              ]
          },
          {
              "id": "10658",
              "name": "Владимирская область",
              "count": 62,
              "chld": [
                  {
                      "id": "192",
                      "name": "Владимир",
                      "count": 61
                  }
              ]
          },
          {
              "id": "10995",
              "name": "Краснодарский край",
              "count": 52,
              "chld": [
                  {
                      "id": "239",
                      "name": "Сочи",
                      "count": 19
                  },
                  {
                      "id": "35",
                      "name": "Краснодар",
                      "count": 17
                  },
                  {
                      "id": "970",
                      "name": "Новороссийск",
                      "count": 2
                  },
                  {
                      "id": "11002",
                      "name": "Тихорецк",
                      "count": 2
                  },
                  {
                      "id": "20231",
                      "name": "Новокубанск",
                      "count": 1
                  }
              ]
          },
          {
              "id": "11079",
              "name": "Нижегородская область",
              "count": 49,
              "chld": [
                  {
                      "id": "47",
                      "name": "Нижний Новгород",
                      "count": 42
                  },
                  {
                      "id": "11080",
                      "name": "Арзамас",
                      "count": 2
                  }
              ]
          },
          {
              "id": "11119",
              "name": "Республика Татарстан",
              "count": 45,
              "chld": [
                  {
                      "id": "43",
                      "name": "Казань",
                      "count": 38
                  },
                  {
                      "id": "121642",
                      "name": "Иннополис",
                      "count": 1
                  },
                  {
                      "id": "236",
                      "name": "Набережные Челны",
                      "count": 1
                  }
              ]
          },
          {
              "id": "10857",
              "name": "Калининградская область",
              "count": 36,
              "chld": [
                  {
                      "id": "22",
                      "name": "Калининград",
                      "count": 34
                  },
                  {
                      "id": "20139",
                      "name": "Зеленоградск",
                      "count": 1
                  }
              ]
          },
          {
              "id": "11409",
              "name": "Приморский край",
              "count": 35,
              "chld": [
                  {
                      "id": "75",
                      "name": "Владивосток",
                      "count": 35
                  }
              ]
          },
          {
              "id": "11225",
              "name": "Челябинская область",
              "count": 35,
              "chld": [
                  {
                      "id": "56",
                      "name": "Челябинск",
                      "count": 29
                  },
                  {
                      "id": "11202",
                      "name": "Златоуст",
                      "count": 2
                  },
                  {
                      "id": "11207",
                      "name": "Копейск",
                      "count": 1
                  },
                  {
                      "id": "235",
                      "name": "Магнитогорск",
                      "count": 1
                  }
              ]
          },
          {
              "id": "11029",
              "name": "Ростовская область",
              "count": 31,
              "chld": [
                  {
                      "id": "39",
                      "name": "Ростов-на-Дону",
                      "count": 25
                  },
                  {
                      "id": "11043",
                      "name": "Каменск-Шахтинский",
                      "count": 1
                  },
                  {
                      "id": "11051",
                      "name": "Сальск",
                      "count": 1
                  },
                  {
                      "id": "971",
                      "name": "Таганрог",
                      "count": 1
                  }
              ]
          },
          {
              "id": "11131",
              "name": "Самарская область",
              "count": 30,
              "chld": [
                  {
                      "id": "51",
                      "name": "Самара",
                      "count": 17
                  },
                  {
                      "id": "240",
                      "name": "Тольятти",
                      "count": 8
                  }
              ]
          },
          {
              "id": "977",
              "name": "Республика Крым",
              "count": 30,
              "chld": [
                  {
                      "id": "146",
                      "name": "Симферополь",
                      "count": 9
                  },
                  {
                      "id": "959",
                      "name": "Севастополь",
                      "count": 6
                  },
                  {
                      "id": "11470",
                      "name": "Ялта",
                      "count": 5
                  },
                  {
                      "id": "27085",
                      "name": "Белогорск",
                      "count": 1
                  },
                  {
                      "id": "11469",
                      "name": "Феодосия",
                      "count": 1
                  }
              ]
          },
          {
              "id": "11111",
              "name": "Республика Башкортостан",
              "count": 26,
              "chld": [
                  {
                      "id": "172",
                      "name": "Уфа",
                      "count": 23
                  }
              ]
          },
          {
              "id": "11309",
              "name": "Красноярский край",
              "count": 26,
              "chld": [
                  {
                      "id": "62",
                      "name": "Красноярск",
                      "count": 20
                  },
                  {
                      "id": "11311",
                      "name": "Норильск",
                      "count": 4
                  }
              ]
          },
          {
              "id": "10672",
              "name": "Воронежская область",
              "count": 25,
              "chld": [
                  {
                      "id": "193",
                      "name": "Воронеж",
                      "count": 22
                  }
              ]
          },
          {
              "id": "10832",
              "name": "Тульская область",
              "count": 24,
              "chld": [
                  {
                      "id": "15",
                      "name": "Тула",
                      "count": 23
                  }
              ]
          },
          {
              "id": "11108",
              "name": "Пермский край",
              "count": 23,
              "chld": [
                  {
                      "id": "50",
                      "name": "Пермь",
                      "count": 22
                  }
              ]
          },
          {
              "id": "11318",
              "name": "Омская область",
              "count": 21,
              "chld": [
                  {
                      "id": "66",
                      "name": "Омск",
                      "count": 20
                  }
              ]
          },
          {
              "id": "10950",
              "name": "Волгоградская область",
              "count": 18,
              "chld": [
                  {
                      "id": "38",
                      "name": "Волгоград",
                      "count": 14
                  },
                  {
                      "id": "10951",
                      "name": "Волжский",
                      "count": 3
                  },
                  {
                      "id": "10981",
                      "name": "Урюпинск",
                      "count": 1
                  }
              ]
          },
          {
              "id": "11266",
              "name": "Иркутская область",
              "count": 14,
              "chld": [
                  {
                      "id": "63",
                      "name": "Иркутск",
                      "count": 11
                  },
                  {
                      "id": "11256",
                      "name": "Ангарск",
                      "count": 1
                  }
              ]
          },
          {
              "id": "10819",
              "name": "Тверская область",
              "count": 13,
              "chld": [
                  {
                      "id": "14",
                      "name": "Тверь",
                      "count": 13
                  }
              ]
          },
          {
              "id": "11353",
              "name": "Томская область",
              "count": 13,
              "chld": [
                  {
                      "id": "67",
                      "name": "Томск",
                      "count": 13
                  }
              ]
          },
          {
              "id": "11176",
              "name": "Тюменская область",
              "count": 13,
              "chld": [
                  {
                      "id": "55",
                      "name": "Тюмень",
                      "count": 12
                  },
                  {
                      "id": "11173",
                      "name": "Ишим",
                      "count": 1
                  }
              ]
          },
          {
              "id": "11232",
              "name": "Ямало-Ненецкий автономный округ",
              "count": 13,
              "chld": [
                  {
                      "id": "11230",
                      "name": "Новый Уренгой",
                      "count": 7
                  },
                  {
                      "id": "23329",
                      "name": "Лабытнанги",
                      "count": 3
                  },
                  {
                      "id": "58",
                      "name": "Салехард",
                      "count": 3
                  }
              ]
          },
          {
              "id": "11069",
              "name": "Ставропольский край",
              "count": 12,
              "chld": [
                  {
                      "id": "36",
                      "name": "Ставрополь",
                      "count": 8
                  },
                  {
                      "id": "11056",
                      "name": "Георгиевск",
                      "count": 1
                  },
                  {
                      "id": "11064",
                      "name": "Невинномысск",
                      "count": 1
                  }
              ]
          },
          {
              "id": "11153",
              "name": "Ульяновская область",
              "count": 11,
              "chld": [
                  {
                      "id": "195",
                      "name": "Ульяновск",
                      "count": 11
                  }
              ]
          },
          {
              "id": "21949",
              "name": "Забайкальский край",
              "count": 11,
              "chld": [
                  {
                      "id": "68",
                      "name": "Чита",
                      "count": 9
                  },
                  {
                      "id": "11358",
                      "name": "Краснокаменск",
                      "count": 2
                  }
              ]
          },
          {
              "id": "11282",
              "name": "Кемеровская область (Кузбасс)",
              "count": 11,
              "chld": [
                  {
                      "id": "64",
                      "name": "Кемерово",
                      "count": 6
                  }
              ]
          },
          {
              "id": "10853",
              "name": "Вологодская область",
              "count": 10,
              "chld": [
                  {
                      "id": "21",
                      "name": "Вологда",
                      "count": 7
                  },
                  {
                      "id": "968",
                      "name": "Череповец",
                      "count": 3
                  }
              ]
          },
          {
              "id": "10946",
              "name": "Астраханская область",
              "count": 9,
              "chld": [
                  {
                      "id": "37",
                      "name": "Астрахань",
                      "count": 9
                  }
              ]
          },
          {
              "id": "11146",
              "name": "Саратовская область",
              "count": 9,
              "chld": [
                  {
                      "id": "194",
                      "name": "Саратов",
                      "count": 6
                  }
              ]
          },
          {
              "id": "11084",
              "name": "Оренбургская область",
              "count": 8,
              "chld": [
                  {
                      "id": "48",
                      "name": "Оренбург",
                      "count": 8
                  }
              ]
          },
          {
              "id": "11077",
              "name": "Республика Марий Эл",
              "count": 8,
              "chld": [
                  {
                      "id": "41",
                      "name": "Йошкар-Ола",
                      "count": 8
                  }
              ]
          },
          {
              "id": "11156",
              "name": "Чувашская Республика",
              "count": 8,
              "chld": [
                  {
                      "id": "45",
                      "name": "Чебоксары",
                      "count": 8
                  }
              ]
          },
          {
              "id": "10939",
              "name": "Республика Коми",
              "count": 8,
              "chld": [
                  {
                      "id": "10945",
                      "name": "Ухта",
                      "count": 6
                  },
                  {
                      "id": "19",
                      "name": "Сыктывкар",
                      "count": 2
                  }
              ]
          },
          {
              "id": "10841",
              "name": "Ярославская область",
              "count": 8,
              "chld": [
                  {
                      "id": "16",
                      "name": "Ярославль",
                      "count": 5
                  },
                  {
                      "id": "10840",
                      "name": "Углич",
                      "count": 2
                  }
              ]
          },
          {
              "id": "10842",
              "name": "Архангельская область",
              "count": 8,
              "chld": [
                  {
                      "id": "20",
                      "name": "Архангельск",
                      "count": 4
                  },
                  {
                      "id": "10849",
                      "name": "Северодвинск",
                      "count": 3
                  },
                  {
                      "id": "10843",
                      "name": "Вельск",
                      "count": 1
                  }
              ]
          },
          {
              "id": "10795",
              "name": "Смоленская область",
              "count": 7,
              "chld": [
                  {
                      "id": "12",
                      "name": "Смоленск",
                      "count": 7
                  }
              ]
          },
          {
              "id": "11457",
              "name": "Хабаровский край",
              "count": 7,
              "chld": [
                  {
                      "id": "76",
                      "name": "Хабаровск",
                      "count": 6
                  }
              ]
          },
          {
              "id": "11020",
              "name": "Карачаево-Черкесская Республика",
              "count": 6,
              "chld": [
                  {
                      "id": "1104",
                      "name": "Черкесск",
                      "count": 6
                  }
              ]
          },
          {
              "id": "10712",
              "name": "Липецкая область",
              "count": 6,
              "chld": [
                  {
                      "id": "9",
                      "name": "Липецк",
                      "count": 6
                  }
              ]
          },
          {
              "id": "11148",
              "name": "Удмуртская Республика",
              "count": 6,
              "chld": [
                  {
                      "id": "44",
                      "name": "Ижевск",
                      "count": 6
                  }
              ]
          },
          {
              "id": "10699",
              "name": "Костромская область",
              "count": 6,
              "chld": [
                  {
                      "id": "7",
                      "name": "Кострома",
                      "count": 4
                  },
                  {
                      "id": "10700",
                      "name": "Буй",
                      "count": 1
                  },
                  {
                      "id": "20218",
                      "name": "Солигалич",
                      "count": 1
                  }
              ]
          },
          {
              "id": "11193",
              "name": "Ханты-Мансийский автономный округ - Югра",
              "count": 6,
              "chld": [
                  {
                      "id": "11186",
                      "name": "Нягань",
                      "count": 1
                  },
                  {
                      "id": "973",
                      "name": "Сургут",
                      "count": 1
                  }
              ]
          },
          {
              "id": "11095",
              "name": "Пензенская область",
              "count": 5,
              "chld": [
                  {
                      "id": "49",
                      "name": "Пенза",
                      "count": 5
                  }
              ]
          },
          {
              "id": "10802",
              "name": "Тамбовская область",
              "count": 5,
              "chld": [
                  {
                      "id": "13",
                      "name": "Тамбов",
                      "count": 5
                  }
              ]
          },
          {
              "id": "11443",
              "name": "Республика Саха (Якутия)",
              "count": 5,
              "chld": [
                  {
                      "id": "74",
                      "name": "Якутск",
                      "count": 4
                  }
              ]
          },
          {
              "id": "10687",
              "name": "Ивановская область",
              "count": 4,
              "chld": [
                  {
                      "id": "5",
                      "name": "Иваново",
                      "count": 4
                  }
              ]
          },
          {
              "id": "10705",
              "name": "Курская область",
              "count": 4,
              "chld": [
                  {
                      "id": "8",
                      "name": "Курск",
                      "count": 4
                  }
              ]
          },
          {
              "id": "11117",
              "name": "Республика Мордовия",
              "count": 4,
              "chld": [
                  {
                      "id": "42",
                      "name": "Саранск",
                      "count": 4
                  }
              ]
          },
          {
              "id": "11375",
              "name": "Амурская область",
              "count": 4,
              "chld": [
                  {
                      "id": "77",
                      "name": "Благовещенск",
                      "count": 3
                  }
              ]
          },
          {
              "id": "11004",
              "name": "Республика Адыгея",
              "count": 4,
              "chld": [
                  {
                      "id": "1093",
                      "name": "Майкоп",
                      "count": 1
                  }
              ]
          },
          {
              "id": "10693",
              "name": "Калужская область",
              "count": 4,
              "chld": [
                  {
                      "id": "6",
                      "name": "Калуга",
                      "count": 2
                  }
              ]
          },
          {
              "id": "11070",
              "name": "Кировская область",
              "count": 3,
              "chld": [
                  {
                      "id": "46",
                      "name": "Киров",
                      "count": 3
                  }
              ]
          },
          {
              "id": "11158",
              "name": "Курганская область",
              "count": 3,
              "chld": [
                  {
                      "id": "53",
                      "name": "Курган",
                      "count": 3
                  }
              ]
          },
          {
              "id": "10897",
              "name": "Мурманская область",
              "count": 3,
              "chld": [
                  {
                      "id": "23",
                      "name": "Мурманск",
                      "count": 3
                  }
              ]
          },
          {
              "id": "11024",
              "name": "Чеченская Республика",
              "count": 3,
              "chld": [
                  {
                      "id": "1106",
                      "name": "Грозный",
                      "count": 3
                  }
              ]
          },
          {
              "id": "10645",
              "name": "Белгородская область",
              "count": 3,
              "chld": [
                  {
                      "id": "4",
                      "name": "Белгород",
                      "count": 2
                  }
              ]
          },
          {
              "id": "10772",
              "name": "Орловская область",
              "count": 3,
              "chld": [
                  {
                      "id": "10",
                      "name": "Орёл",
                      "count": 2
                  },
                  {
                      "id": "10769",
                      "name": "Ливны",
                      "count": 1
                  }
              ]
          },
          {
              "id": "11235",
              "name": "Алтайский край",
              "count": 2,
              "chld": [
                  {
                      "id": "197",
                      "name": "Барнаул",
                      "count": 2
                  }
              ]
          },
          {
              "id": "10650",
              "name": "Брянская область",
              "count": 2,
              "chld": [
                  {
                      "id": "26899",
                      "name": "Фокино",
                      "count": 2
                  }
              ]
          },
          {
              "id": "11013",
              "name": "Кабардино-Балкарская Республика",
              "count": 2,
              "chld": [
                  {
                      "id": "30",
                      "name": "Нальчик",
                      "count": 2
                  }
              ]
          },
          {
              "id": "11450",
              "name": "Сахалинская область",
              "count": 2,
              "chld": [
                  {
                      "id": "80",
                      "name": "Южно-Сахалинск",
                      "count": 2
                  }
              ]
          },
          {
              "id": "11012",
              "name": "Республика Ингушетия",
              "count": 2,
              "chld": [
                  {
                      "id": "20181",
                      "name": "Магас",
                      "count": 1
                  },
                  {
                      "id": "1092",
                      "name": "Назрань",
                      "count": 1
                  }
              ]
          },
          {
              "id": "10933",
              "name": "Республика Карелия",
              "count": 2,
              "chld": [
                  {
                      "id": "10935",
                      "name": "Костомукша",
                      "count": 1
                  },
                  {
                      "id": "20127",
                      "name": "Лахденпохья",
                      "count": 1
                  }
              ]
          },
          {
              "id": "11403",
              "name": "Магаданская область",
              "count": 1,
              "chld": [
                  {
                      "id": "79",
                      "name": "Магадан",
                      "count": 1
                  }
              ]
          },
          {
              "id": "10904",
              "name": "Новгородская область",
              "count": 1,
              "chld": [
                  {
                      "id": "24",
                      "name": "Великий Новгород",
                      "count": 1
                  }
              ]
          },
          {
              "id": "10926",
              "name": "Псковская область",
              "count": 1,
              "chld": [
                  {
                      "id": "10928",
                      "name": "Великие Луки",
                      "count": 1
                  }
              ]
          },
          {
              "id": "10231",
              "name": "Республика Алтай",
              "count": 1
          },
          {
              "id": "11330",
              "name": "Республика Бурятия",
              "count": 1,
              "chld": [
                  {
                      "id": "198",
                      "name": "Улан-Удэ",
                      "count": 1
                  }
              ]
          },
          {
              "id": "11340",
              "name": "Республика Хакасия",
              "count": 1,
              "chld": [
                  {
                      "id": "1095",
                      "name": "Абакан",
                      "count": 1
                  }
              ]
          }
      ]
  },
  {
      "id": "84",
      "name": "США",
      "count": 573,
      "chld": [
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 87
          },
          {
              "id": "29322",
              "name": "Штат Калифорния",
              "count": 85,
              "chld": [
                  {
                      "id": "200",
                      "name": "Лос-Анджелес",
                      "count": 28
                  },
                  {
                      "id": "20765",
                      "name": "Сакраменто",
                      "count": 9
                  },
                  {
                      "id": "21756",
                      "name": "Сан-Хосе",
                      "count": 9
                  },
                  {
                      "id": "110042",
                      "name": "Юрика",
                      "count": 5
                  },
                  {
                      "id": "102563",
                      "name": "Ирвайн",
                      "count": 3
                  },
                  {
                      "id": "90",
                      "name": "Сан-Франциско",
                      "count": 3
                  },
                  {
                      "id": "102682",
                      "name": "Бэйкерсфилд",
                      "count": 2
                  },
                  {
                      "id": "102613",
                      "name": "Санта-Клара",
                      "count": 2
                  },
                  {
                      "id": "102606",
                      "name": "Фресно",
                      "count": 2
                  },
                  {
                      "id": "102788",
                      "name": "Анахайм",
                      "count": 1
                  },
                  {
                      "id": "110795",
                      "name": "Вальехо",
                      "count": 1
                  },
                  {
                      "id": "108680",
                      "name": "Вест Ковина",
                      "count": 1
                  },
                  {
                      "id": "103076",
                      "name": "Гарден Гров",
                      "count": 1
                  },
                  {
                      "id": "145353",
                      "name": "Камарильо",
                      "count": 1
                  },
                  {
                      "id": "103458",
                      "name": "Карсон",
                      "count": 1
                  },
                  {
                      "id": "103175",
                      "name": "Редвуд Сити",
                      "count": 1
                  },
                  {
                      "id": "103408",
                      "name": "Риверсайд",
                      "count": 1
                  },
                  {
                      "id": "110612",
                      "name": "Ронерт Парк",
                      "count": 1
                  },
                  {
                      "id": "21754",
                      "name": "Сан-Диего",
                      "count": 1
                  },
                  {
                      "id": "103520",
                      "name": "Уитьер",
                      "count": 1
                  },
                  {
                      "id": "108684",
                      "name": "Ферфилд",
                      "count": 1
                  }
              ]
          },
          {
              "id": "29360",
              "name": "Штат Техас",
              "count": 47,
              "chld": [
                  {
                      "id": "21772",
                      "name": "Даллас",
                      "count": 10
                  },
                  {
                      "id": "21749",
                      "name": "Сан-Антонио",
                      "count": 5
                  },
                  {
                      "id": "10130",
                      "name": "Хьюстон",
                      "count": 4
                  },
                  {
                      "id": "103405",
                      "name": "Карроллтон",
                      "count": 3
                  },
                  {
                      "id": "103160",
                      "name": "Абилин",
                      "count": 2
                  },
                  {
                      "id": "103030",
                      "name": "Арлингтон",
                      "count": 2
                  },
                  {
                      "id": "108654",
                      "name": "Мак-Кинни",
                      "count": 2
                  },
                  {
                      "id": "21416",
                      "name": "Остин",
                      "count": 2
                  },
                  {
                      "id": "110608",
                      "name": "Рокуолл",
                      "count": 2
                  },
                  {
                      "id": "102982",
                      "name": "Амарилло",
                      "count": 1
                  },
                  {
                      "id": "102829",
                      "name": "Грейпвайн",
                      "count": 1
                  },
                  {
                      "id": "110215",
                      "name": "Керрвилл",
                      "count": 1
                  },
                  {
                      "id": "103289",
                      "name": "Кэти",
                      "count": 1
                  },
                  {
                      "id": "102916",
                      "name": "Лаббок",
                      "count": 1
                  },
                  {
                      "id": "102791",
                      "name": "Лиг-Сити",
                      "count": 1
                  },
                  {
                      "id": "115660",
                      "name": "Флауэр-Маунд",
                      "count": 1
                  },
                  {
                      "id": "102662",
                      "name": "Эль-Пасо",
                      "count": 1
                  }
              ]
          },
          {
              "id": "29349",
              "name": "Штат Нью-Йорк",
              "count": 43,
              "chld": [
                  {
                      "id": "202",
                      "name": "Нью-Йорк",
                      "count": 30
                  },
                  {
                      "id": "102898",
                      "name": "Питсфорд",
                      "count": 2
                  },
                  {
                      "id": "102588",
                      "name": "Рочестер",
                      "count": 2
                  },
                  {
                      "id": "102830",
                      "name": "Скенектади",
                      "count": 2
                  },
                  {
                      "id": "102604",
                      "name": "Буффало",
                      "count": 1
                  },
                  {
                      "id": "110052",
                      "name": "Фармингдейл",
                      "count": 1
                  },
                  {
                      "id": "102822",
                      "name": "Хантингтон",
                      "count": 1
                  }
              ]
          },
          {
              "id": "29331",
              "name": "Штат Индиана",
              "count": 30,
              "chld": [
                  {
                      "id": "109793",
                      "name": "Андерсон",
                      "count": 24
                  },
                  {
                      "id": "109880",
                      "name": "Браунсберг",
                      "count": 2
                  },
                  {
                      "id": "110199",
                      "name": "Джаспер",
                      "count": 1
                  },
                  {
                      "id": "21504",
                      "name": "Индианаполис",
                      "count": 1
                  },
                  {
                      "id": "102922",
                      "name": "Колумбус",
                      "count": 1
                  },
                  {
                      "id": "110058",
                      "name": "Фишерс",
                      "count": 1
                  }
              ]
          },
          {
              "id": "29326",
              "name": "Штат Флорида",
              "count": 28,
              "chld": [
                  {
                      "id": "10122",
                      "name": "Майами",
                      "count": 6
                  },
                  {
                      "id": "103425",
                      "name": "Майами-Бич",
                      "count": 6
                  },
                  {
                      "id": "145525",
                      "name": "Санни-Айлс-Бич",
                      "count": 3
                  },
                  {
                      "id": "109864",
                      "name": "Брадентон",
                      "count": 2
                  },
                  {
                      "id": "102847",
                      "name": "Киссимми",
                      "count": 1
                  },
                  {
                      "id": "103364",
                      "name": "Норт Порт",
                      "count": 1
                  },
                  {
                      "id": "102582",
                      "name": "Орландо",
                      "count": 1
                  },
                  {
                      "id": "100661",
                      "name": "Пенсакола",
                      "count": 1
                  },
                  {
                      "id": "110746",
                      "name": "Санрайз",
                      "count": 1
                  },
                  {
                      "id": "102970",
                      "name": "Санфорд",
                      "count": 1
                  },
                  {
                      "id": "102566",
                      "name": "Тампа",
                      "count": 1
                  },
                  {
                      "id": "102832",
                      "name": "Уинтер-Парк",
                      "count": 1
                  },
                  {
                      "id": "102685",
                      "name": "Форт-Лодердейл",
                      "count": 1
                  },
                  {
                      "id": "103036",
                      "name": "Хоумстед",
                      "count": 1
                  }
              ]
          },
          {
              "id": "29363",
              "name": "Штат Вирджиния",
              "count": 25,
              "chld": [
                  {
                      "id": "102820",
                      "name": "Ашберн",
                      "count": 9
                  },
                  {
                      "id": "103239",
                      "name": "Арлингтон",
                      "count": 2
                  },
                  {
                      "id": "102624",
                      "name": "Рестон",
                      "count": 2
                  },
                  {
                      "id": "103105",
                      "name": "Фредериксбург",
                      "count": 2
                  },
                  {
                      "id": "110802",
                      "name": "Вьенна",
                      "count": 1
                  },
                  {
                      "id": "110545",
                      "name": "Питерсберг",
                      "count": 1
                  },
                  {
                      "id": "110841",
                      "name": "Уильямсбург",
                      "count": 1
                  }
              ]
          },
          {
              "id": "29350",
              "name": "Штат Северная Каролина",
              "count": 21,
              "chld": [
                  {
                      "id": "102579",
                      "name": "Шарлотт",
                      "count": 4
                  },
                  {
                      "id": "102850",
                      "name": "Дарем",
                      "count": 1
                  },
                  {
                      "id": "103397",
                      "name": "Эшвилл",
                      "count": 1
                  }
              ]
          },
          {
              "id": "29327",
              "name": "Штат Джорджия",
              "count": 21,
              "chld": [
                  {
                      "id": "86",
                      "name": "Атланта",
                      "count": 13
                  },
                  {
                      "id": "103469",
                      "name": "Лоренсвилль",
                      "count": 2
                  },
                  {
                      "id": "103446",
                      "name": "Далонега",
                      "count": 1
                  },
                  {
                      "id": "102802",
                      "name": "Декейтер",
                      "count": 1
                  }
              ]
          },
          {
              "id": "29359",
              "name": "Штат Теннесси",
              "count": 19,
              "chld": [
                  {
                      "id": "113242",
                      "name": "Кливленд",
                      "count": 17
                  },
                  {
                      "id": "102620",
                      "name": "Мемфис",
                      "count": 1
                  },
                  {
                      "id": "102628",
                      "name": "Ноксвилл",
                      "count": 1
                  }
              ]
          },
          {
              "id": "29330",
              "name": "Штат Иллинойс",
              "count": 19,
              "chld": [
                  {
                      "id": "10131",
                      "name": "Чикаго",
                      "count": 10
                  },
                  {
                      "id": "109993",
                      "name": "Де Плейнс",
                      "count": 1
                  },
                  {
                      "id": "113666",
                      "name": "Олни",
                      "count": 1
                  }
              ]
          },
          {
              "id": "29354",
              "name": "Штат Орегон",
              "count": 14,
              "chld": [
                  {
                      "id": "110763",
                      "name": "Даллс",
                      "count": 3
                  },
                  {
                      "id": "21215",
                      "name": "Портленд",
                      "count": 3
                  },
                  {
                      "id": "103052",
                      "name": "Хиллсборо",
                      "count": 2
                  },
                  {
                      "id": "108681",
                      "name": "Грешам",
                      "count": 1
                  },
                  {
                      "id": "102668",
                      "name": "Юджин",
                      "count": 1
                  }
              ]
          },
          {
              "id": "29347",
              "name": "Штат Нью-Джерси",
              "count": 11,
              "chld": [
                  {
                      "id": "102946",
                      "name": "Секаукус",
                      "count": 3
                  },
                  {
                      "id": "21356",
                      "name": "Ньюарк",
                      "count": 2
                  },
                  {
                      "id": "103059",
                      "name": "Вудбери",
                      "count": 1
                  },
                  {
                      "id": "102694",
                      "name": "Клифтон",
                      "count": 1
                  },
                  {
                      "id": "110340",
                      "name": "Линден",
                      "count": 1
                  }
              ]
          },
          {
              "id": "29320",
              "name": "Штат Аризона",
              "count": 10,
              "chld": [
                  {
                      "id": "20959",
                      "name": "Финикс",
                      "count": 8
                  },
                  {
                      "id": "113663",
                      "name": "Ганадо",
                      "count": 1
                  },
                  {
                      "id": "103304",
                      "name": "Темпе",
                      "count": 1
                  }
              ]
          },
          {
              "id": "29366",
              "name": "Штат Висконсин",
              "count": 10,
              "chld": [
                  {
                      "id": "110198",
                      "name": "Джейнсвилл",
                      "count": 6
                  },
                  {
                      "id": "21268",
                      "name": "Милуоки",
                      "count": 1
                  }
              ]
          },
          {
              "id": "29364",
              "name": "Штат Вашингтон",
              "count": 10,
              "chld": [
                  {
                      "id": "91",
                      "name": "Сиэтл",
                      "count": 2
                  },
                  {
                      "id": "102593",
                      "name": "Такома",
                      "count": 1
                  },
                  {
                      "id": "113458",
                      "name": "Уолла-Уолла",
                      "count": 1
                  },
                  {
                      "id": "113762",
                      "name": "Фрайдей Харбор",
                      "count": 1
                  },
                  {
                      "id": "102725",
                      "name": "Якима",
                      "count": 1
                  }
              ]
          },
          {
              "id": "103666",
              "name": "Округ Колумбия",
              "count": 9,
              "chld": [
                  {
                      "id": "87",
                      "name": "Вашингтон",
                      "count": 9
                  }
              ]
          },
          {
              "id": "29357",
              "name": "Штат Южная Каролина",
              "count": 9,
              "chld": [
                  {
                      "id": "102598",
                      "name": "Гринвилл",
                      "count": 2
                  },
                  {
                      "id": "109825",
                      "name": "Бофорт",
                      "count": 1
                  },
                  {
                      "id": "110480",
                      "name": "Норт-Чарлстон",
                      "count": 1
                  },
                  {
                      "id": "103333",
                      "name": "Самтер",
                      "count": 1
                  },
                  {
                      "id": "110696",
                      "name": "Симпсонвилл",
                      "count": 1
                  },
                  {
                      "id": "110071",
                      "name": "Форт-Милл",
                      "count": 1
                  }
              ]
          },
          {
              "id": "29352",
              "name": "Штат Огайо",
              "count": 8,
              "chld": [
                  {
                      "id": "102577",
                      "name": "Кливленд",
                      "count": 3
                  },
                  {
                      "id": "20815",
                      "name": "Колумбус",
                      "count": 1
                  }
              ]
          },
          {
              "id": "29335",
              "name": "Штат Луизиана",
              "count": 8,
              "chld": [
                  {
                      "id": "102919",
                      "name": "Монро",
                      "count": 2
                  },
                  {
                      "id": "21348",
                      "name": "Новый Орлеан",
                      "count": 2
                  },
                  {
                      "id": "110627",
                      "name": "Растон",
                      "count": 2
                  },
                  {
                      "id": "109989",
                      "name": "Денем Спрингс",
                      "count": 1
                  },
                  {
                      "id": "102784",
                      "name": "Лафайетт",
                      "count": 1
                  }
              ]
          },
          {
              "id": "29334",
              "name": "Штат Кентукки",
              "count": 7,
              "chld": [
                  {
                      "id": "21190",
                      "name": "Луисвилл",
                      "count": 5
                  }
              ]
          },
          {
              "id": "29339",
              "name": "Штат Мичиган",
              "count": 7,
              "chld": [
                  {
                      "id": "102623",
                      "name": "Гранд-Рапидс",
                      "count": 2
                  },
                  {
                      "id": "103517",
                      "name": "Кантон",
                      "count": 2
                  },
                  {
                      "id": "89",
                      "name": "Детройт",
                      "count": 1
                  },
                  {
                      "id": "102583",
                      "name": "Саутфилд",
                      "count": 1
                  },
                  {
                      "id": "102677",
                      "name": "Флинт",
                      "count": 1
                  }
              ]
          },
          {
              "id": "29340",
              "name": "Штат Миннесота",
              "count": 6,
              "chld": [
                  {
                      "id": "21270",
                      "name": "Миннеаполис",
                      "count": 3
                  },
                  {
                      "id": "103541",
                      "name": "Бемиджи",
                      "count": 1
                  }
              ]
          },
          {
              "id": "29355",
              "name": "Штат Пенсильвания",
              "count": 6,
              "chld": [
                  {
                      "id": "10129",
                      "name": "Филадельфия",
                      "count": 2
                  },
                  {
                      "id": "109961",
                      "name": "Кранберри Тауншип",
                      "count": 1
                  },
                  {
                      "id": "21218",
                      "name": "Питтсбург",
                      "count": 1
                  }
              ]
          },
          {
              "id": "29323",
              "name": "Штат Колорадо",
              "count": 5,
              "chld": [
                  {
                      "id": "21467",
                      "name": "Денвер",
                      "count": 3
                  }
              ]
          },
          {
              "id": "29341",
              "name": "Штат Миссисипи",
              "count": 5,
              "chld": [
                  {
                      "id": "110786",
                      "name": "Тупело",
                      "count": 3
                  },
                  {
                      "id": "110726",
                      "name": "Старквилл",
                      "count": 2
                  }
              ]
          },
          {
              "id": "29361",
              "name": "Штат Юта",
              "count": 5,
              "chld": [
                  {
                      "id": "10127",
                      "name": "Солт-Лейк-Сити",
                      "count": 3
                  },
                  {
                      "id": "110323",
                      "name": "Лейтон",
                      "count": 1
                  }
              ]
          },
          {
              "id": "29353",
              "name": "Штат Оклахома",
              "count": 4,
              "chld": [
                  {
                      "id": "21366",
                      "name": "Оклахома-Сити",
                      "count": 2
                  },
                  {
                      "id": "109873",
                      "name": "Брокен-Эрроу",
                      "count": 1
                  }
              ]
          },
          {
              "id": "29318",
              "name": "Штат Алабама",
              "count": 2,
              "chld": [
                  {
                      "id": "102610",
                      "name": "Бирмингем",
                      "count": 1
                  }
              ]
          },
          {
              "id": "29324",
              "name": "Штат Коннектикут",
              "count": 2,
              "chld": [
                  {
                      "id": "103434",
                      "name": "Ист-Хартфорд",
                      "count": 1
                  }
              ]
          },
          {
              "id": "29338",
              "name": "Штат Массачусетс",
              "count": 2,
              "chld": [
                  {
                      "id": "223",
                      "name": "Бостон",
                      "count": 1
                  }
              ]
          },
          {
              "id": "29328",
              "name": "Штат Гавайи",
              "count": 1
          },
          {
              "id": "29325",
              "name": "Штат Делавэр",
              "count": 1,
              "chld": [
                  {
                      "id": "102815",
                      "name": "Нью Касл",
                      "count": 1
                  }
              ]
          },
          {
              "id": "29333",
              "name": "Штат Канзас",
              "count": 1,
              "chld": [
                  {
                      "id": "102600",
                      "name": "Уичита",
                      "count": 1
                  }
              ]
          },
          {
              "id": "29342",
              "name": "Штат Миссури",
              "count": 1,
              "chld": [
                  {
                      "id": "102937",
                      "name": "Индепенденс",
                      "count": 1
                  }
              ]
          },
          {
              "id": "29336",
              "name": "Штат Мэн",
              "count": 1
          },
          {
              "id": "29344",
              "name": "Штат Небраска",
              "count": 1,
              "chld": [
                  {
                      "id": "101677",
                      "name": "Омаха",
                      "count": 1
                  }
              ]
          },
          {
              "id": "29345",
              "name": "Штат Невада",
              "count": 1,
              "chld": [
                  {
                      "id": "21121",
                      "name": "Лас-Вегас",
                      "count": 1
                  }
              ]
          },
          {
              "id": "29348",
              "name": "Штат Нью-Мексико",
              "count": 1,
              "chld": [
                  {
                      "id": "113368",
                      "name": "Силвер Сити",
                      "count": 1
                  }
              ]
          }
      ]
  },
  {
      "id": "983",
      "name": "Турция",
      "count": 313,
      "chld": [
          {
              "id": "103728",
              "name": "Провинция Стамбул",
              "count": 131,
              "chld": [
                  {
                      "id": "11508",
                      "name": "Стамбул",
                      "count": 120
                  },
                  {
                      "id": "106124",
                      "name": "Кадыкёй",
                      "count": 2
                  },
                  {
                      "id": "115707",
                      "name": "Фатих",
                      "count": 2
                  },
                  {
                      "id": "106119",
                      "name": "Бакыркёй",
                      "count": 1
                  },
                  {
                      "id": "115692",
                      "name": "Бахчелиэвлер",
                      "count": 1
                  },
                  {
                      "id": "115695",
                      "name": "Бейоглу",
                      "count": 1
                  },
                  {
                      "id": "107756",
                      "name": "Санджактепе",
                      "count": 1
                  },
                  {
                      "id": "115708",
                      "name": "Шишли",
                      "count": 1
                  },
                  {
                      "id": "104767",
                      "name": "Эсенъюрт",
                      "count": 1
                  }
              ]
          },
          {
              "id": "103697",
              "name": "Провинция Измир",
              "count": 39,
              "chld": [
                  {
                      "id": "11505",
                      "name": "Измир",
                      "count": 33
                  },
                  {
                      "id": "115715",
                      "name": "Борнова",
                      "count": 2
                  },
                  {
                      "id": "115717",
                      "name": "Чигли",
                      "count": 2
                  }
              ]
          },
          {
              "id": "103674",
              "name": "Провинция Анкара",
              "count": 31,
              "chld": [
                  {
                      "id": "11503",
                      "name": "Анкара",
                      "count": 28
                  },
                  {
                      "id": "104782",
                      "name": "Чанкая",
                      "count": 1
                  }
              ]
          },
          {
              "id": "103675",
              "name": "Провинция Анталья",
              "count": 23,
              "chld": [
                  {
                      "id": "11511",
                      "name": "Анталья",
                      "count": 14
                  },
                  {
                      "id": "11502",
                      "name": "Аланья",
                      "count": 3
                  },
                  {
                      "id": "21091",
                      "name": "Кемер",
                      "count": 3
                  },
                  {
                      "id": "104667",
                      "name": "Серик",
                      "count": 1
                  }
              ]
          },
          {
              "id": "103688",
              "name": "Провинция Бурса",
              "count": 18,
              "chld": [
                  {
                      "id": "11504",
                      "name": "Бурса",
                      "count": 17
                  }
              ]
          },
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 9
          },
          {
              "id": "103707",
              "name": "Провинция Конья",
              "count": 6,
              "chld": [
                  {
                      "id": "101474",
                      "name": "Конья",
                      "count": 6
                  }
              ]
          },
          {
              "id": "103694",
              "name": "Провинция Диярбакыр",
              "count": 5,
              "chld": [
                  {
                      "id": "103828",
                      "name": "Диярбакыр",
                      "count": 5
                  }
              ]
          },
          {
              "id": "103715",
              "name": "Провинция Мерсин",
              "count": 5,
              "chld": [
                  {
                      "id": "103823",
                      "name": "Мерсин",
                      "count": 5
                  }
              ]
          },
          {
              "id": "103724",
              "name": "Провинция Самсун",
              "count": 5,
              "chld": [
                  {
                      "id": "103833",
                      "name": "Самсун",
                      "count": 4
                  },
                  {
                      "id": "115861",
                      "name": "Атакум",
                      "count": 1
                  }
              ]
          },
          {
              "id": "103706",
              "name": "Провинция Коджаэли",
              "count": 4,
              "chld": [
                  {
                      "id": "103841",
                      "name": "Гебзе",
                      "count": 1
                  },
                  {
                      "id": "101515",
                      "name": "Измит",
                      "count": 1
                  }
              ]
          },
          {
              "id": "103669",
              "name": "Провинция Адана",
              "count": 3,
              "chld": [
                  {
                      "id": "11501",
                      "name": "Адана",
                      "count": 3
                  }
              ]
          },
          {
              "id": "103733",
              "name": "Провинция Ушак",
              "count": 3,
              "chld": [
                  {
                      "id": "103875",
                      "name": "Ушак",
                      "count": 3
                  }
              ]
          },
          {
              "id": "103713",
              "name": "Провинция Маниса",
              "count": 3,
              "chld": [
                  {
                      "id": "104729",
                      "name": "Акхисар",
                      "count": 1
                  },
                  {
                      "id": "104727",
                      "name": "Алашехир",
                      "count": 1
                  },
                  {
                      "id": "103837",
                      "name": "Маниса",
                      "count": 1
                  }
              ]
          },
          {
              "id": "103735",
              "name": "Провинция Хатай",
              "count": 3,
              "chld": [
                  {
                      "id": "104672",
                      "name": "Рейханлы",
                      "count": 1
                  },
                  {
                      "id": "107805",
                      "name": "Эрзин",
                      "count": 1
                  }
              ]
          },
          {
              "id": "103671",
              "name": "Провинция Айдын",
              "count": 2,
              "chld": [
                  {
                      "id": "103845",
                      "name": "Айдын",
                      "count": 2
                  }
              ]
          },
          {
              "id": "103690",
              "name": "Провинция Газиантеп",
              "count": 2,
              "chld": [
                  {
                      "id": "103825",
                      "name": "Газиантеп",
                      "count": 2
                  }
              ]
          },
          {
              "id": "103699",
              "name": "Провинция Кайсери",
              "count": 2,
              "chld": [
                  {
                      "id": "103831",
                      "name": "Кайсери",
                      "count": 2
                  }
              ]
          },
          {
              "id": "103722",
              "name": "Провинция Ризе",
              "count": 2,
              "chld": [
                  {
                      "id": "103879",
                      "name": "Ризе",
                      "count": 2
                  }
              ]
          },
          {
              "id": "103740",
              "name": "Провинция Ширнак",
              "count": 2,
              "chld": [
                  {
                      "id": "103869",
                      "name": "Ширнак",
                      "count": 2
                  }
              ]
          },
          {
              "id": "103747",
              "name": "Провинция Эскишехир",
              "count": 2,
              "chld": [
                  {
                      "id": "103835",
                      "name": "Эскишехир",
                      "count": 2
                  }
              ]
          },
          {
              "id": "103678",
              "name": "Провинция Афьонкарахисар",
              "count": 1
          },
          {
              "id": "103680",
              "name": "Провинция Балыкесир",
              "count": 1,
              "chld": [
                  {
                      "id": "103829",
                      "name": "Балыкесир",
                      "count": 1
                  }
              ]
          },
          {
              "id": "103685",
              "name": "Провинция Битлис",
              "count": 1,
              "chld": [
                  {
                      "id": "104655",
                      "name": "Татван",
                      "count": 1
                  }
              ]
          },
          {
              "id": "103689",
              "name": "Провинция Ван",
              "count": 1,
              "chld": [
                  {
                      "id": "103861",
                      "name": "Ван",
                      "count": 1
                  }
              ]
          },
          {
              "id": "103691",
              "name": "Провинция Гиресун",
              "count": 1,
              "chld": [
                  {
                      "id": "103862",
                      "name": "Гиресун",
                      "count": 1
                  }
              ]
          },
          {
              "id": "103693",
              "name": "Провинция Денизли",
              "count": 1,
              "chld": [
                  {
                      "id": "103842",
                      "name": "Денизли",
                      "count": 1
                  }
              ]
          },
          {
              "id": "103696",
              "name": "Провинция Зонгулдак",
              "count": 1,
              "chld": [
                  {
                      "id": "103850",
                      "name": "Зонгулдак",
                      "count": 1
                  }
              ]
          },
          {
              "id": "103705",
              "name": "Провинция Килис",
              "count": 1,
              "chld": [
                  {
                      "id": "103889",
                      "name": "Килис",
                      "count": 1
                  }
              ]
          },
          {
              "id": "103708",
              "name": "Провинция Кыркларели",
              "count": 1,
              "chld": [
                  {
                      "id": "103890",
                      "name": "Кыркларели",
                      "count": 1
                  }
              ]
          },
          {
              "id": "103714",
              "name": "Провинция Мардин",
              "count": 1,
              "chld": [
                  {
                      "id": "103884",
                      "name": "Мардин",
                      "count": 1
                  }
              ]
          },
          {
              "id": "103716",
              "name": "Провинция Мугла",
              "count": 1,
              "chld": [
                  {
                      "id": "103832",
                      "name": "Мугла",
                      "count": 1
                  }
              ]
          },
          {
              "id": "103718",
              "name": "Провинция Невшехир",
              "count": 1,
              "chld": [
                  {
                      "id": "103840",
                      "name": "Невшехир",
                      "count": 1
                  }
              ]
          },
          {
              "id": "103725",
              "name": "Провинция Сивас",
              "count": 1,
              "chld": [
                  {
                      "id": "107995",
                      "name": "Сушехри",
                      "count": 1
                  }
              ]
          }
      ]
  },
  {
      "id": "10095",
      "name": "Индонезия",
      "count": 227,
      "chld": [
          {
              "id": "120805",
              "name": "Столичный округ Джакарта",
              "count": 66,
              "chld": [
                  {
                      "id": "10574",
                      "name": "Джакарта",
                      "count": 66
                  }
              ]
          },
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 25
          },
          {
              "id": "120806",
              "name": "Провинция Западная Ява",
              "count": 18,
              "chld": [
                  {
                      "id": "111965",
                      "name": "Бандунг",
                      "count": 8
                  },
                  {
                      "id": "114592",
                      "name": "Богор",
                      "count": 5
                  },
                  {
                      "id": "114911",
                      "name": "Бекаси",
                      "count": 2
                  }
              ]
          },
          {
              "id": "120809",
              "name": "Провинция Восточная Ява",
              "count": 17,
              "chld": [
                  {
                      "id": "21085",
                      "name": "Сурабая",
                      "count": 10
                  },
                  {
                      "id": "96947",
                      "name": "Маланг",
                      "count": 3
                  },
                  {
                      "id": "112644",
                      "name": "Кедири",
                      "count": 1
                  }
              ]
          },
          {
              "id": "10572",
              "name": "Провинция Бали",
              "count": 16,
              "chld": [
                  {
                      "id": "11513",
                      "name": "Денпасар",
                      "count": 10
                  },
                  {
                      "id": "97231",
                      "name": "Убуд",
                      "count": 2
                  }
              ]
          },
          {
              "id": "120821",
              "name": "Провинция Южный Сулавеси",
              "count": 13,
              "chld": [
                  {
                      "id": "21115",
                      "name": "Макасар",
                      "count": 13
                  }
              ]
          },
          {
              "id": "120807",
              "name": "Провинция Центральная Ява",
              "count": 11,
              "chld": [
                  {
                      "id": "10581",
                      "name": "Семаранг",
                      "count": 8
                  },
                  {
                      "id": "112639",
                      "name": "Магеланг",
                      "count": 2
                  },
                  {
                      "id": "112637",
                      "name": "Пурвокерто",
                      "count": 1
                  }
              ]
          },
          {
              "id": "120796",
              "name": "Провинция Северная Суматра",
              "count": 9,
              "chld": [
                  {
                      "id": "10577",
                      "name": "Медан",
                      "count": 9
                  }
              ]
          },
          {
              "id": "120798",
              "name": "Провинция Риау",
              "count": 9,
              "chld": [
                  {
                      "id": "112661",
                      "name": "Паканбару",
                      "count": 7
                  }
              ]
          },
          {
              "id": "120817",
              "name": "Провинция Восточный Калимантан",
              "count": 7,
              "chld": [
                  {
                      "id": "20775",
                      "name": "Самаринда",
                      "count": 6
                  },
                  {
                      "id": "21000",
                      "name": "Баликпапан",
                      "count": 1
                  }
              ]
          },
          {
              "id": "120812",
              "name": "Провинция Восточные Малые Зондские острова",
              "count": 4,
              "chld": [
                  {
                      "id": "97372",
                      "name": "Купанг",
                      "count": 4
                  }
              ]
          },
          {
              "id": "120799",
              "name": "Провинция Джамби",
              "count": 4,
              "chld": [
                  {
                      "id": "112640",
                      "name": "Джамби",
                      "count": 4
                  }
              ]
          },
          {
              "id": "120808",
              "name": "Особый округ Джокьякарта",
              "count": 3,
              "chld": [
                  {
                      "id": "111966",
                      "name": "Джокьякарта",
                      "count": 3
                  }
              ]
          },
          {
              "id": "120819",
              "name": "Провинция Северный Сулавеси",
              "count": 3,
              "chld": [
                  {
                      "id": "97014",
                      "name": "Манадо",
                      "count": 3
                  }
              ]
          },
          {
              "id": "120820",
              "name": "Провинция Центральный Сулавеси",
              "count": 3,
              "chld": [
                  {
                      "id": "112684",
                      "name": "Палу",
                      "count": 3
                  }
              ]
          },
          {
              "id": "120800",
              "name": "Провинция Южная Суматра",
              "count": 3,
              "chld": [
                  {
                      "id": "112653",
                      "name": "Палембанг",
                      "count": 3
                  }
              ]
          },
          {
              "id": "120810",
              "name": "Провинция Бантен",
              "count": 3,
              "chld": [
                  {
                      "id": "114912",
                      "name": "Тангеранг",
                      "count": 2
                  },
                  {
                      "id": "21014",
                      "name": "Серанг",
                      "count": 1
                  }
              ]
          },
          {
              "id": "120811",
              "name": "Провинция Западные Малые Зондские острова",
              "count": 3,
              "chld": [
                  {
                      "id": "112672",
                      "name": "Сумбава Бесар",
                      "count": 2
                  },
                  {
                      "id": "97240",
                      "name": "Матарам",
                      "count": 1
                  }
              ]
          },
          {
              "id": "120802",
              "name": "Провинция Лампунг",
              "count": 2,
              "chld": [
                  {
                      "id": "112636",
                      "name": "Бандар-Лампунг",
                      "count": 2
                  }
              ]
          },
          {
              "id": "120797",
              "name": "Провинция Западная Суматра",
              "count": 2,
              "chld": [
                  {
                      "id": "10578",
                      "name": "Паданг",
                      "count": 1
                  }
              ]
          },
          {
              "id": "120826",
              "name": "Провинция Северное Малуку",
              "count": 2,
              "chld": [
                  {
                      "id": "112675",
                      "name": "Тернате",
                      "count": 1
                  }
              ]
          },
          {
              "id": "120825",
              "name": "Провинция Малуку",
              "count": 1,
              "chld": [
                  {
                      "id": "20853",
                      "name": "Амбон",
                      "count": 1
                  }
              ]
          },
          {
              "id": "120818",
              "name": "Провинция Северный Калимантан",
              "count": 1,
              "chld": [
                  {
                      "id": "10582",
                      "name": "Таракан",
                      "count": 1
                  }
              ]
          },
          {
              "id": "120815",
              "name": "Провинция Центральный Калимантан",
              "count": 1,
              "chld": [
                  {
                      "id": "112658",
                      "name": "Паланкарая",
                      "count": 1
                  }
              ]
          },
          {
              "id": "120816",
              "name": "Провинция Южный Калимантан",
              "count": 1
          }
      ]
  },
  {
      "id": "96",
      "name": "Германия",
      "count": 227,
      "chld": [
          {
              "id": "103753",
              "name": "Гессен",
              "count": 75,
              "chld": [
                  {
                      "id": "100",
                      "name": "Франкфурт-на-Майне",
                      "count": 55
                  },
                  {
                      "id": "100492",
                      "name": "Висбаден",
                      "count": 5
                  },
                  {
                      "id": "115246",
                      "name": "Ханау",
                      "count": 1
                  }
              ]
          },
          {
              "id": "103759",
              "name": "Свободное государство Саксония",
              "count": 30,
              "chld": [
                  {
                      "id": "10407",
                      "name": "Дрезден",
                      "count": 2
                  }
              ]
          },
          {
              "id": "4294967294",
              "name": "Без области",
              "count": 30,
              "chld": [
                  {
                      "id": "177",
                      "name": "Берлин",
                      "count": 27
                  },
                  {
                      "id": "178",
                      "name": "Гамбург",
                      "count": 3
                  }
              ]
          },
          {
              "id": "103756",
              "name": "Северный Рейн-Вестфалия",
              "count": 25,
              "chld": [
                  {
                      "id": "10408",
                      "name": "Дюссельдорф",
                      "count": 6
                  },
                  {
                      "id": "103784",
                      "name": "Бохум",
                      "count": 4
                  },
                  {
                      "id": "101065",
                      "name": "Мюльхайм-на-Руре",
                      "count": 3
                  },
                  {
                      "id": "10402",
                      "name": "Бонн",
                      "count": 1
                  },
                  {
                      "id": "115193",
                      "name": "Гельзенкирхен",
                      "count": 1
                  },
                  {
                      "id": "103808",
                      "name": "Гютерсло",
                      "count": 1
                  },
                  {
                      "id": "115155",
                      "name": "Динслакен",
                      "count": 1
                  },
                  {
                      "id": "100500",
                      "name": "Дортмунд",
                      "count": 1
                  },
                  {
                      "id": "103802",
                      "name": "Зиген",
                      "count": 1
                  },
                  {
                      "id": "98",
                      "name": "Кёльн",
                      "count": 1
                  },
                  {
                      "id": "103799",
                      "name": "Леверкузен",
                      "count": 1
                  },
                  {
                      "id": "115142",
                      "name": "Ратинген",
                      "count": 1
                  },
                  {
                      "id": "115360",
                      "name": "Рёсрат",
                      "count": 1
                  },
                  {
                      "id": "115189",
                      "name": "Санкт-Августин",
                      "count": 1
                  },
                  {
                      "id": "20776",
                      "name": "Эссен",
                      "count": 1
                  }
              ]
          },
          {
              "id": "103750",
              "name": "Свободное государство Бавария",
              "count": 24,
              "chld": [
                  {
                      "id": "10412",
                      "name": "Нюрнберг",
                      "count": 10
                  },
                  {
                      "id": "99",
                      "name": "Мюнхен",
                      "count": 4
                  },
                  {
                      "id": "115313",
                      "name": "Эрланген",
                      "count": 3
                  },
                  {
                      "id": "112382",
                      "name": "Эрдинг",
                      "count": 2
                  },
                  {
                      "id": "100814",
                      "name": "Вюрцбург",
                      "count": 1
                  },
                  {
                      "id": "112217",
                      "name": "Деггендорф",
                      "count": 1
                  }
              ]
          },
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 23
          },
          {
              "id": "103749",
              "name": "Баден-Вюртемберг",
              "count": 12,
              "chld": [
                  {
                      "id": "103764",
                      "name": "Карлсруэ",
                      "count": 2
                  },
                  {
                      "id": "103765",
                      "name": "Мангейм",
                      "count": 2
                  },
                  {
                      "id": "112213",
                      "name": "Ален",
                      "count": 1
                  },
                  {
                      "id": "115266",
                      "name": "Бёблинген",
                      "count": 1
                  },
                  {
                      "id": "115269",
                      "name": "Зиндельфинген",
                      "count": 1
                  },
                  {
                      "id": "115277",
                      "name": "Людвигсбург",
                      "count": 1
                  },
                  {
                      "id": "115291",
                      "name": "Пфорцхайм",
                      "count": 1
                  },
                  {
                      "id": "115294",
                      "name": "Филлинген-Швеннинген",
                      "count": 1
                  },
                  {
                      "id": "101",
                      "name": "Штутгарт",
                      "count": 1
                  }
              ]
          },
          {
              "id": "103755",
              "name": "Нижняя Саксония",
              "count": 2,
              "chld": [
                  {
                      "id": "115109",
                      "name": "Гарбсен",
                      "count": 1
                  }
              ]
          },
          {
              "id": "103751",
              "name": "Бранденбург",
              "count": 1,
              "chld": [
                  {
                      "id": "103809",
                      "name": "Франкфурт-на-Одере",
                      "count": 1
                  }
              ]
          },
          {
              "id": "103752",
              "name": "Вольный ганзейский город Бремен",
              "count": 1,
              "chld": [
                  {
                      "id": "10403",
                      "name": "Бремен",
                      "count": 1
                  }
              ]
          },
          {
              "id": "103757",
              "name": "Рейнланд-Пфальц",
              "count": 1
          },
          {
              "id": "103758",
              "name": "Саар",
              "count": 1,
              "chld": [
                  {
                      "id": "115318",
                      "name": "Фёльклинген",
                      "count": 1
                  }
              ]
          },
          {
              "id": "103760",
              "name": "Саксония-Анхальт",
              "count": 1
          },
          {
              "id": "103761",
              "name": "Шлезвиг-Гольштейн",
              "count": 1,
              "chld": [
                  {
                      "id": "115102",
                      "name": "Аренсбург",
                      "count": 1
                  }
              ]
          }
      ]
  },
  {
      "id": "994",
      "name": "Индия",
      "count": 202,
      "chld": [
          {
              "id": "114631",
              "name": "Махараштра",
              "count": 38,
              "chld": [
                  {
                      "id": "10558",
                      "name": "Мумбаи",
                      "count": 19
                  },
                  {
                      "id": "111963",
                      "name": "Пуна",
                      "count": 10
                  },
                  {
                      "id": "112560",
                      "name": "Акола",
                      "count": 2
                  },
                  {
                      "id": "21310",
                      "name": "Нагпур",
                      "count": 2
                  },
                  {
                      "id": "112511",
                      "name": "Аурангабад",
                      "count": 1
                  },
                  {
                      "id": "145382",
                      "name": "Нави Мумбаи",
                      "count": 1
                  },
                  {
                      "id": "112601",
                      "name": "Солапур",
                      "count": 1
                  }
              ]
          },
          {
              "id": "114628",
              "name": "Карнатака",
              "count": 22,
              "chld": [
                  {
                      "id": "21006",
                      "name": "Бангалор",
                      "count": 20
                  },
                  {
                      "id": "112505",
                      "name": "Майсур",
                      "count": 1
                  }
              ]
          },
          {
              "id": "4294967294",
              "name": "Без области",
              "count": 20,
              "chld": [
                  {
                      "id": "10562",
                      "name": "Дели",
                      "count": 20
                  }
              ]
          },
          {
              "id": "114624",
              "name": "Гуджарат",
              "count": 18,
              "chld": [
                  {
                      "id": "10571",
                      "name": "Сурат",
                      "count": 10
                  },
                  {
                      "id": "10555",
                      "name": "Ахмадабад",
                      "count": 5
                  },
                  {
                      "id": "112548",
                      "name": "Вадодара",
                      "count": 1
                  },
                  {
                      "id": "10569",
                      "name": "Раджкот",
                      "count": 1
                  }
              ]
          },
          {
              "id": "114643",
              "name": "Уттар-Прадеш",
              "count": 15,
              "chld": [
                  {
                      "id": "10566",
                      "name": "Лакхнау",
                      "count": 4
                  },
                  {
                      "id": "112512",
                      "name": "Канпур",
                      "count": 3
                  },
                  {
                      "id": "20761",
                      "name": "Агра",
                      "count": 2
                  },
                  {
                      "id": "112586",
                      "name": "Мератх",
                      "count": 1
                  },
                  {
                      "id": "112533",
                      "name": "Морадабад",
                      "count": 1
                  }
              ]
          },
          {
              "id": "114639",
              "name": "Тамилнад",
              "count": 14,
              "chld": [
                  {
                      "id": "20902",
                      "name": "Ченнай",
                      "count": 7
                  },
                  {
                      "id": "112521",
                      "name": "Салем",
                      "count": 3
                  },
                  {
                      "id": "21212",
                      "name": "Мадурай",
                      "count": 1
                  },
                  {
                      "id": "112569",
                      "name": "Тирунелвели",
                      "count": 1
                  },
                  {
                      "id": "112591",
                      "name": "Тируччираппалли",
                      "count": 1
                  }
              ]
          },
          {
              "id": "115669",
              "name": "Телангана",
              "count": 12,
              "chld": [
                  {
                      "id": "20934",
                      "name": "Хайдарабад",
                      "count": 12
                  }
              ]
          },
          {
              "id": "114627",
              "name": "Западная Бенгалия",
              "count": 11
          },
          {
              "id": "114637",
              "name": "Раджастхан",
              "count": 10,
              "chld": [
                  {
                      "id": "21470",
                      "name": "Джайпур",
                      "count": 9
                  }
              ]
          },
          {
              "id": "114629",
              "name": "Мадхья-Прадеш",
              "count": 8,
              "chld": [
                  {
                      "id": "21225",
                      "name": "Бхопал",
                      "count": 3
                  },
                  {
                      "id": "21503",
                      "name": "Индаур",
                      "count": 3
                  },
                  {
                      "id": "21469",
                      "name": "Джабалпур",
                      "count": 1
                  }
              ]
          },
          {
              "id": "114653",
              "name": "Чандигарх",
              "count": 7,
              "chld": [
                  {
                      "id": "112509",
                      "name": "Чандигарх",
                      "count": 7
                  }
              ]
          },
          {
              "id": "114623",
              "name": "Бихар",
              "count": 7,
              "chld": [
                  {
                      "id": "21279",
                      "name": "Патна",
                      "count": 6
                  }
              ]
          },
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 4
          },
          {
              "id": "108941",
              "name": "Керала",
              "count": 4,
              "chld": [
                  {
                      "id": "112522",
                      "name": "Коччи",
                      "count": 1
                  },
                  {
                      "id": "112571",
                      "name": "Триссур",
                      "count": 1
                  }
              ]
          },
          {
              "id": "114622",
              "name": "Ассам",
              "count": 3,
              "chld": [
                  {
                      "id": "112592",
                      "name": "Гувахати",
                      "count": 3
                  }
              ]
          },
          {
              "id": "114626",
              "name": "Джаркханд",
              "count": 3,
              "chld": [
                  {
                      "id": "10570",
                      "name": "Ранчи",
                      "count": 2
                  },
                  {
                      "id": "21471",
                      "name": "Джамшедпур",
                      "count": 1
                  }
              ]
          },
          {
              "id": "114635",
              "name": "Орисса",
              "count": 2,
              "chld": [
                  {
                      "id": "21229",
                      "name": "Бхубанешвар",
                      "count": 2
                  }
              ]
          },
          {
              "id": "114636",
              "name": "Пенджаб",
              "count": 2,
              "chld": [
                  {
                      "id": "112545",
                      "name": "Амритсар",
                      "count": 1
                  }
              ]
          },
          {
              "id": "114644",
              "name": "Харьяна",
              "count": 1,
              "chld": [
                  {
                      "id": "112609",
                      "name": "Рохтак",
                      "count": 1
                  }
              ]
          },
          {
              "id": "114645",
              "name": "Химачал-Прадеш",
              "count": 1
          }
      ]
  },
  {
      "id": "134",
      "name": "Китай",
      "count": 186,
      "chld": [
          {
              "id": "4294967294",
              "name": "Без области",
              "count": 39,
              "chld": [
                  {
                      "id": "10590",
                      "name": "Пекин",
                      "count": 19
                  },
                  {
                      "id": "10599",
                      "name": "Шанхай",
                      "count": 18
                  },
                  {
                      "id": "21073",
                      "name": "Тяньцзинь",
                      "count": 1
                  },
                  {
                      "id": "20827",
                      "name": "Чунцин",
                      "count": 1
                  }
              ]
          },
          {
              "id": "10584",
              "name": "Гонконг (Сянган)",
              "count": 36,
              "chld": [
                  {
                      "id": "11514",
                      "name": "Гонконг",
                      "count": 33
                  }
              ]
          },
          {
              "id": "114568",
              "name": "Цзянсу",
              "count": 33,
              "chld": [
                  {
                      "id": "98538",
                      "name": "Нанкин",
                      "count": 30
                  },
                  {
                      "id": "111457",
                      "name": "Наньтун",
                      "count": 1
                  },
                  {
                      "id": "111586",
                      "name": "Янчжоу",
                      "count": 1
                  }
              ]
          },
          {
              "id": "114552",
              "name": "Гуандун",
              "count": 23,
              "chld": [
                  {
                      "id": "21431",
                      "name": "Гуанчжоу",
                      "count": 12
                  },
                  {
                      "id": "109371",
                      "name": "Шэньчжэнь",
                      "count": 10
                  },
                  {
                      "id": "111496",
                      "name": "Мэйчжоу",
                      "count": 1
                  }
              ]
          },
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 18
          },
          {
              "id": "114571",
              "name": "Чжэцзян",
              "count": 11,
              "chld": [
                  {
                      "id": "109372",
                      "name": "Вэньчжоу",
                      "count": 4
                  },
                  {
                      "id": "20930",
                      "name": "Ханчжоу",
                      "count": 4
                  },
                  {
                      "id": "111576",
                      "name": "Цюйчжоу",
                      "count": 2
                  }
              ]
          },
          {
              "id": "114564",
              "name": "Хэнань",
              "count": 6,
              "chld": [
                  {
                      "id": "37181",
                      "name": "Чжэнчжоу",
                      "count": 4
                  },
                  {
                      "id": "21177",
                      "name": "Лоян",
                      "count": 1
                  },
                  {
                      "id": "111468",
                      "name": "Шанцю",
                      "count": 1
                  }
              ]
          },
          {
              "id": "114575",
              "name": "Шаньдун",
              "count": 6,
              "chld": [
                  {
                      "id": "20577",
                      "name": "Цзинань",
                      "count": 3
                  },
                  {
                      "id": "20892",
                      "name": "Циндао",
                      "count": 2
                  },
                  {
                      "id": "111455",
                      "name": "Линьи",
                      "count": 1
                  }
              ]
          },
          {
              "id": "114554",
              "name": "Ляонин",
              "count": 2,
              "chld": [
                  {
                      "id": "20831",
                      "name": "Шэньян",
                      "count": 2
                  }
              ]
          },
          {
              "id": "114561",
              "name": "Хунань",
              "count": 2,
              "chld": [
                  {
                      "id": "20895",
                      "name": "Чанша",
                      "count": 1
                  }
              ]
          },
          {
              "id": "114562",
              "name": "Хэбэй",
              "count": 2,
              "chld": [
                  {
                      "id": "111584",
                      "name": "Ланфан",
                      "count": 1
                  },
                  {
                      "id": "111460",
                      "name": "Ханьдань",
                      "count": 1
                  }
              ]
          },
          {
              "id": "116763",
              "name": "Макао",
              "count": 1
          },
          {
              "id": "114581",
              "name": "Тибетский автономный район",
              "count": 1,
              "chld": [
                  {
                      "id": "10588",
                      "name": "Лхаса",
                      "count": 1
                  }
              ]
          },
          {
              "id": "114559",
              "name": "Хайнань",
              "count": 1,
              "chld": [
                  {
                      "id": "20966",
                      "name": "Санья",
                      "count": 1
                  }
              ]
          },
          {
              "id": "114560",
              "name": "Хубэй",
              "count": 1,
              "chld": [
                  {
                      "id": "111679",
                      "name": "Хуанган",
                      "count": 1
                  }
              ]
          },
          {
              "id": "114563",
              "name": "Хэйлунцзян",
              "count": 1,
              "chld": [
                  {
                      "id": "10596",
                      "name": "Харбин",
                      "count": 1
                  }
              ]
          },
          {
              "id": "114574",
              "name": "Шаньси",
              "count": 1,
              "chld": [
                  {
                      "id": "21090",
                      "name": "Тайюань",
                      "count": 1
                  }
              ]
          },
          {
              "id": "114572",
              "name": "Шэньси",
              "count": 1,
              "chld": [
                  {
                      "id": "10591",
                      "name": "Сиань",
                      "count": 1
                  }
              ]
          },
          {
              "id": "114576",
              "name": "Юньнань",
              "count": 1,
              "chld": [
                  {
                      "id": "20884",
                      "name": "Куньмин",
                      "count": 1
                  }
              ]
          }
      ]
  },
  {
      "id": "149",
      "name": "Беларусь",
      "count": 180,
      "chld": [
          {
              "id": "29630",
              "name": "Минская область",
              "count": 154,
              "chld": [
                  {
                      "id": "157",
                      "name": "Минск",
                      "count": 143
                  },
                  {
                      "id": "26034",
                      "name": "Жодино",
                      "count": 1
                  },
                  {
                      "id": "101627",
                      "name": "Фаниполь",
                      "count": 1
                  }
              ]
          },
          {
              "id": "29631",
              "name": "Гомельская область",
              "count": 17,
              "chld": [
                  {
                      "id": "21274",
                      "name": "Мозырь",
                      "count": 8
                  },
                  {
                      "id": "155",
                      "name": "Гомель",
                      "count": 7
                  }
              ]
          },
          {
              "id": "29629",
              "name": "Могилёвская область",
              "count": 7,
              "chld": [
                  {
                      "id": "158",
                      "name": "Могилёв",
                      "count": 6
                  },
                  {
                      "id": "26774",
                      "name": "Горки",
                      "count": 1
                  }
              ]
          },
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 2
          }
      ]
  },
  {
      "id": "120",
      "name": "Польша",
      "count": 178,
      "chld": [
          {
              "id": "104111",
              "name": "Мазовецкое воеводство",
              "count": 85,
              "chld": [
                  {
                      "id": "10472",
                      "name": "Варшава",
                      "count": 79
                  },
                  {
                      "id": "106567",
                      "name": "Констанцин-Езорна",
                      "count": 2
                  },
                  {
                      "id": "108406",
                      "name": "Ласкажев",
                      "count": 2
                  },
                  {
                      "id": "106437",
                      "name": "Гродзиск-Мазовецки",
                      "count": 1
                  }
              ]
          },
          {
              "id": "104123",
              "name": "Силезское воеводство",
              "count": 35,
              "chld": [
                  {
                      "id": "10473",
                      "name": "Катовице",
                      "count": 29
                  },
                  {
                      "id": "104836",
                      "name": "Забже",
                      "count": 3
                  },
                  {
                      "id": "106637",
                      "name": "Люблинец",
                      "count": 2
                  },
                  {
                      "id": "104907",
                      "name": "Домброва-Гурнича",
                      "count": 1
                  }
              ]
          },
          {
              "id": "104124",
              "name": "Великопольское воеводство",
              "count": 15,
              "chld": [
                  {
                      "id": "10477",
                      "name": "Познань",
                      "count": 13
                  },
                  {
                      "id": "104868",
                      "name": "Острув-Велькопольски",
                      "count": 1
                  }
              ]
          },
          {
              "id": "104117",
              "name": "Малопольское воеводство",
              "count": 12,
              "chld": [
                  {
                      "id": "10474",
                      "name": "Краков",
                      "count": 12
                  }
              ]
          },
          {
              "id": "104112",
              "name": "Нижнесилезское воеводство",
              "count": 10,
              "chld": [
                  {
                      "id": "21392",
                      "name": "Вроцлав",
                      "count": 9
                  },
                  {
                      "id": "108522",
                      "name": "Сехнице",
                      "count": 1
                  }
              ]
          },
          {
              "id": "104121",
              "name": "Поморское воеводство",
              "count": 9,
              "chld": [
                  {
                      "id": "21417",
                      "name": "Гданьск",
                      "count": 8
                  },
                  {
                      "id": "104854",
                      "name": "Слупск",
                      "count": 1
                  }
              ]
          },
          {
              "id": "104113",
              "name": "Куявско-Поморское воеводство",
              "count": 3,
              "chld": [
                  {
                      "id": "108446",
                      "name": "Нове",
                      "count": 2
                  },
                  {
                      "id": "104896",
                      "name": "Иновроцлав",
                      "count": 1
                  }
              ]
          },
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 2
          },
          {
              "id": "104119",
              "name": "Подкарпатское воеводство",
              "count": 2,
              "chld": [
                  {
                      "id": "104822",
                      "name": "Ярослав",
                      "count": 1
                  }
              ]
          },
          {
              "id": "104126",
              "name": "Западно-Поморское воеводство",
              "count": 1,
              "chld": [
                  {
                      "id": "104851",
                      "name": "Старгард",
                      "count": 1
                  }
              ]
          },
          {
              "id": "104116",
              "name": "Лодзинское воеводство",
              "count": 1,
              "chld": [
                  {
                      "id": "10475",
                      "name": "Лодзь",
                      "count": 1
                  }
              ]
          },
          {
              "id": "104114",
              "name": "Люблинское воеводство",
              "count": 1,
              "chld": [
                  {
                      "id": "104815",
                      "name": "Люблин",
                      "count": 1
                  }
              ]
          },
          {
              "id": "104115",
              "name": "Любушское воеводство",
              "count": 1,
              "chld": [
                  {
                      "id": "104830",
                      "name": "Зелёна-Гура",
                      "count": 1
                  }
              ]
          },
          {
              "id": "104120",
              "name": "Подляшское воеводство",
              "count": 1,
              "chld": [
                  {
                      "id": "10471",
                      "name": "Белосток",
                      "count": 1
                  }
              ]
          }
      ]
  },
  {
      "id": "204",
      "name": "Испания",
      "count": 173,
      "chld": [
          {
              "id": "104188",
              "name": "Провинция Мадрид",
              "count": 58,
              "chld": [
                  {
                      "id": "10435",
                      "name": "Мадрид",
                      "count": 45
                  },
                  {
                      "id": "123458",
                      "name": "Алькала-де-Энарес",
                      "count": 2
                  }
              ]
          },
          {
              "id": "104187",
              "name": "Каталония",
              "count": 31,
              "chld": [
                  {
                      "id": "10429",
                      "name": "Барселона",
                      "count": 19
                  },
                  {
                      "id": "109286",
                      "name": "Льорет-де-Мар",
                      "count": 4
                  },
                  {
                      "id": "109299",
                      "name": "Таррагона",
                      "count": 1
                  }
              ]
          },
          {
              "id": "104182",
              "name": "Провинция Валенсия",
              "count": 28,
              "chld": [
                  {
                      "id": "10430",
                      "name": "Валенсия",
                      "count": 16
                  },
                  {
                      "id": "20835",
                      "name": "Аликанте",
                      "count": 1
                  },
                  {
                      "id": "218919",
                      "name": "Альсира",
                      "count": 1
                  },
                  {
                      "id": "21075",
                      "name": "Бенидорм",
                      "count": 1
                  },
                  {
                      "id": "123345",
                      "name": "Дения",
                      "count": 1
                  },
                  {
                      "id": "145177",
                      "name": "Эльче",
                      "count": 1
                  }
              ]
          },
          {
              "id": "104178",
              "name": "Андалусия",
              "count": 21,
              "chld": [
                  {
                      "id": "10437",
                      "name": "Малага",
                      "count": 14
                  },
                  {
                      "id": "10440",
                      "name": "Севилья",
                      "count": 2
                  },
                  {
                      "id": "101592",
                      "name": "Гранада",
                      "count": 1
                  },
                  {
                      "id": "20836",
                      "name": "Кордова",
                      "count": 1
                  },
                  {
                      "id": "10438",
                      "name": "Марбелья",
                      "count": 1
                  }
              ]
          },
          {
              "id": "21618",
              "name": "Канарские острова",
              "count": 9,
              "chld": [
                  {
                      "id": "100494",
                      "name": "Лас-Пальмас-де-Гран-Канария",
                      "count": 6
                  },
                  {
                      "id": "10443",
                      "name": "Санта-Крус-де-Тенерифе",
                      "count": 1
                  }
              ]
          },
          {
              "id": "104191",
              "name": "Риоха",
              "count": 4,
              "chld": [
                  {
                      "id": "105103",
                      "name": "Логроньо",
                      "count": 4
                  }
              ]
          },
          {
              "id": "104183",
              "name": "Галисия",
              "count": 4,
              "chld": [
                  {
                      "id": "109288",
                      "name": "Оренсе",
                      "count": 2
                  },
                  {
                      "id": "109304",
                      "name": "Виго",
                      "count": 1
                  },
                  {
                      "id": "21057",
                      "name": "Ла-Корунья",
                      "count": 1
                  }
              ]
          },
          {
              "id": "104184",
              "name": "Кантабрия",
              "count": 3,
              "chld": [
                  {
                      "id": "105097",
                      "name": "Сантандер",
                      "count": 3
                  }
              ]
          },
          {
              "id": "104185",
              "name": "Кастилия-Ла-Манча",
              "count": 3
          },
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 3
          },
          {
              "id": "21616",
              "name": "Балеарские острова",
              "count": 2,
              "chld": [
                  {
                      "id": "105092",
                      "name": "Пальма",
                      "count": 2
                  }
              ]
          },
          {
              "id": "104186",
              "name": "Кастилия и Леон",
              "count": 2,
              "chld": [
                  {
                      "id": "109284",
                      "name": "Леон",
                      "count": 1
                  },
                  {
                      "id": "145424",
                      "name": "Понферрада",
                      "count": 1
                  }
              ]
          },
          {
              "id": "104189",
              "name": "Провинция Мурсия",
              "count": 2,
              "chld": [
                  {
                      "id": "114252",
                      "name": "Лорка",
                      "count": 1
                  },
                  {
                      "id": "100951",
                      "name": "Мурсия",
                      "count": 1
                  }
              ]
          },
          {
              "id": "104179",
              "name": "Арагон",
              "count": 1,
              "chld": [
                  {
                      "id": "20946",
                      "name": "Сарагоса",
                      "count": 1
                  }
              ]
          },
          {
              "id": "104181",
              "name": "Страна Басков",
              "count": 1,
              "chld": [
                  {
                      "id": "109295",
                      "name": "Сан-Себастьян",
                      "count": 1
                  }
              ]
          },
          {
              "id": "104192",
              "name": "Эстремадура",
              "count": 1,
              "chld": [
                  {
                      "id": "145251",
                      "name": "Пласенсия",
                      "count": 1
                  }
              ]
          }
      ]
  },
  {
      "id": "10093",
      "name": "Вьетнам",
      "count": 156,
      "chld": [
          {
              "id": "4294967294",
              "name": "Без области",
              "count": 143,
              "chld": [
                  {
                      "id": "10552",
                      "name": "Ханой",
                      "count": 78
                  },
                  {
                      "id": "10553",
                      "name": "Хошимин",
                      "count": 53
                  },
                  {
                      "id": "10543",
                      "name": "Дананг",
                      "count": 10
                  },
                  {
                      "id": "114516",
                      "name": "Кантхо",
                      "count": 1
                  },
                  {
                      "id": "20923",
                      "name": "Хайфон",
                      "count": 1
                  }
              ]
          },
          {
              "id": "145659",
              "name": "Провинция Намдинь",
              "count": 4,
              "chld": [
                  {
                      "id": "21317",
                      "name": "Намдинь",
                      "count": 3
                  }
              ]
          },
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 2
          },
          {
              "id": "145652",
              "name": "Провинция Кханьхоа",
              "count": 2,
              "chld": [
                  {
                      "id": "10547",
                      "name": "Нячанг",
                      "count": 2
                  }
              ]
          },
          {
              "id": "145624",
              "name": "Провинция Анзянг",
              "count": 1
          },
          {
              "id": "145649",
              "name": "Провинция Куангнгай",
              "count": 1,
              "chld": [
                  {
                      "id": "145696",
                      "name": "Куангнгай",
                      "count": 1
                  }
              ]
          },
          {
              "id": "145655",
              "name": "Провинция Ламдонг",
              "count": 1,
              "chld": [
                  {
                      "id": "21448",
                      "name": "Далат",
                      "count": 1
                  }
              ]
          },
          {
              "id": "145661",
              "name": "Провинция Ниньбинь",
              "count": 1,
              "chld": [
                  {
                      "id": "145535",
                      "name": "Ниньбинь",
                      "count": 1
                  }
              ]
          },
          {
              "id": "145667",
              "name": "Провинция Тхыатхьен-Хюэ",
              "count": 1,
              "chld": [
                  {
                      "id": "20905",
                      "name": "Хюэ",
                      "count": 1
                  }
              ]
          }
      ]
  },
  {
      "id": "159",
      "name": "Казахстан",
      "count": 155,
      "chld": [
          {
              "id": "29406",
              "name": "Алматинская область",
              "count": 66,
              "chld": [
                  {
                      "id": "162",
                      "name": "Алматы",
                      "count": 66
                  }
              ]
          },
          {
              "id": "29403",
              "name": "Акмолинская область",
              "count": 34,
              "chld": [
                  {
                      "id": "163",
                      "name": "Астана",
                      "count": 33
                  },
                  {
                      "id": "20809",
                      "name": "Кокшетау",
                      "count": 1
                  }
              ]
          },
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 17
          },
          {
              "id": "29412",
              "name": "Костанайская область",
              "count": 12,
              "chld": [
                  {
                      "id": "10295",
                      "name": "Костанай",
                      "count": 11
                  }
              ]
          },
          {
              "id": "29411",
              "name": "Карагандинская область",
              "count": 7,
              "chld": [
                  {
                      "id": "164",
                      "name": "Караганда",
                      "count": 7
                  }
              ]
          },
          {
              "id": "29409",
              "name": "Жамбылская область",
              "count": 6,
              "chld": [
                  {
                      "id": "21094",
                      "name": "Тараз",
                      "count": 6
                  }
              ]
          },
          {
              "id": "29408",
              "name": "Восточно-Казахстанская область",
              "count": 4,
              "chld": [
                  {
                      "id": "10306",
                      "name": "Усть-Каменогорск",
                      "count": 4
                  }
              ]
          },
          {
              "id": "29407",
              "name": "Атырауская область",
              "count": 3,
              "chld": [
                  {
                      "id": "29476",
                      "name": "Кульсары",
                      "count": 3
                  }
              ]
          },
          {
              "id": "29417",
              "name": "Туркестанская область",
              "count": 2,
              "chld": [
                  {
                      "id": "221",
                      "name": "Шымкент",
                      "count": 2
                  }
              ]
          },
          {
              "id": "29413",
              "name": "Кызылординская область",
              "count": 1,
              "chld": [
                  {
                      "id": "21050",
                      "name": "Кызылорда",
                      "count": 1
                  }
              ]
          },
          {
              "id": "29414",
              "name": "Мангистауская область",
              "count": 1,
              "chld": [
                  {
                      "id": "29575",
                      "name": "Актау",
                      "count": 1
                  }
              ]
          },
          {
              "id": "218918",
              "name": "Область Жетысу",
              "count": 1,
              "chld": [
                  {
                      "id": "10303",
                      "name": "Талдыкорган",
                      "count": 1
                  }
              ]
          },
          {
              "id": "218936",
              "name": "Область Улытау",
              "count": 1
          }
      ]
  },
  {
      "id": "124",
      "name": "Франция",
      "count": 155,
      "chld": [
          {
              "id": "4294967294",
              "name": "Без области",
              "count": 51,
              "chld": [
                  {
                      "id": "10502",
                      "name": "Париж",
                      "count": 51
                  }
              ]
          },
          {
              "id": "152701",
              "name": "Воклюз",
              "count": 42,
              "chld": [
                  {
                      "id": "144526",
                      "name": "Оранж",
                      "count": 42
                  }
              ]
          },
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 16
          },
          {
              "id": "152784",
              "name": "Нор",
              "count": 9,
              "chld": [
                  {
                      "id": "105070",
                      "name": "Лилль",
                      "count": 3
                  },
                  {
                      "id": "112076",
                      "name": "Азбрук",
                      "count": 2
                  },
                  {
                      "id": "116115",
                      "name": "Рубе",
                      "count": 2
                  }
              ]
          },
          {
              "id": "152590",
              "name": "Жиронда",
              "count": 7
          },
          {
              "id": "147713",
              "name": "О-де-Сен",
              "count": 3,
              "chld": [
                  {
                      "id": "147764",
                      "name": "Аньер-сюр-Сен",
                      "count": 1
                  },
                  {
                      "id": "147762",
                      "name": "Булонь-Бийанкур",
                      "count": 1
                  },
                  {
                      "id": "147777",
                      "name": "Монруж",
                      "count": 1
                  }
              ]
          },
          {
              "id": "152654",
              "name": "Атлантическая Луара",
              "count": 2,
              "chld": [
                  {
                      "id": "7222",
                      "name": "Нант",
                      "count": 2
                  }
              ]
          },
          {
              "id": "152754",
              "name": "Верхний Рейн",
              "count": 2,
              "chld": [
                  {
                      "id": "100488",
                      "name": "Мюлуз",
                      "count": 2
                  }
              ]
          },
          {
              "id": "152966",
              "name": "Кальвадос",
              "count": 2,
              "chld": [
                  {
                      "id": "105069",
                      "name": "Кан",
                      "count": 2
                  }
              ]
          },
          {
              "id": "152752",
              "name": "Нижний Рейн",
              "count": 2,
              "chld": [
                  {
                      "id": "21081",
                      "name": "Страсбург",
                      "count": 2
                  }
              ]
          },
          {
              "id": "152697",
              "name": "Буш-дю-Рон",
              "count": 2,
              "chld": [
                  {
                      "id": "10501",
                      "name": "Марсель",
                      "count": 1
                  },
                  {
                      "id": "109085",
                      "name": "Экс-ан-Прованс",
                      "count": 1
                  }
              ]
          },
          {
              "id": "147715",
              "name": "Вал-де-Марн",
              "count": 2,
              "chld": [
                  {
                      "id": "147746",
                      "name": "Витри-сюр-Сен",
                      "count": 1
                  }
              ]
          },
          {
              "id": "152702",
              "name": "Приморские Альпы",
              "count": 2,
              "chld": [
                  {
                      "id": "109093",
                      "name": "Антиб",
                      "count": 1
                  },
                  {
                      "id": "10500",
                      "name": "Ницца",
                      "count": 1
                  }
              ]
          },
          {
              "id": "147720",
              "name": "Вал-д’Уаз",
              "count": 1,
              "chld": [
                  {
                      "id": "112060",
                      "name": "Сержи",
                      "count": 1
                  }
              ]
          },
          {
              "id": "152848",
              "name": "Верхняя Гаронна",
              "count": 1,
              "chld": [
                  {
                      "id": "10504",
                      "name": "Тулуза",
                      "count": 1
                  }
              ]
          },
          {
              "id": "152845",
              "name": "Восточные Пиренеи",
              "count": 1,
              "chld": [
                  {
                      "id": "21244",
                      "name": "Перпиньян",
                      "count": 1
                  }
              ]
          },
          {
              "id": "152599",
              "name": "Дром",
              "count": 1
          },
          {
              "id": "152566",
              "name": "Дё-Севр",
              "count": 1,
              "chld": [
                  {
                      "id": "152630",
                      "name": "Брессюир",
                      "count": 1
                  }
              ]
          },
          {
              "id": "152606",
              "name": "Иль и Вилен",
              "count": 1
          },
          {
              "id": "152824",
              "name": "Кот-д’Армор",
              "count": 1
          },
          {
              "id": "152838",
              "name": "Лот",
              "count": 1
          },
          {
              "id": "152751",
              "name": "Об",
              "count": 1
          },
          {
              "id": "152796",
              "name": "Па-де-Кале",
              "count": 1,
              "chld": [
                  {
                      "id": "112064",
                      "name": "Аррас",
                      "count": 1
                  }
              ]
          },
          {
              "id": "152607",
              "name": "Пюи-де-Дом",
              "count": 1,
              "chld": [
                  {
                      "id": "105071",
                      "name": "Клермон-Ферран",
                      "count": 1
                  }
              ]
          },
          {
              "id": "147714",
              "name": "Сен-Сен-Дени",
              "count": 1,
              "chld": [
                  {
                      "id": "123574",
                      "name": "Сен-Дени",
                      "count": 1
                  }
              ]
          },
          {
              "id": "152615",
              "name": "Финистер",
              "count": 1
          }
      ]
  },
  {
      "id": "118",
      "name": "Нидерланды",
      "count": 154,
      "chld": [
          {
              "id": "104308",
              "name": "Северная Голландия",
              "count": 99,
              "chld": [
                  {
                      "id": "10466",
                      "name": "Амстердам",
                      "count": 97
                  }
              ]
          },
          {
              "id": "104301",
              "name": "Гелдерланд",
              "count": 20,
              "chld": [
                  {
                      "id": "145215",
                      "name": "Неймеген",
                      "count": 18
                  },
                  {
                      "id": "105143",
                      "name": "Арнем",
                      "count": 2
                  }
              ]
          },
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 14
          },
          {
              "id": "104303",
              "name": "Дренте",
              "count": 7
          },
          {
              "id": "104312",
              "name": "Южная Голландия",
              "count": 5,
              "chld": [
                  {
                      "id": "21396",
                      "name": "Гаага",
                      "count": 1
                  },
                  {
                      "id": "20755",
                      "name": "Роттердам",
                      "count": 1
                  }
              ]
          },
          {
              "id": "104310",
              "name": "Флеволанд",
              "count": 4,
              "chld": [
                  {
                      "id": "218625",
                      "name": "Дронтен",
                      "count": 4
                  }
              ]
          },
          {
              "id": "104309",
              "name": "Провинция Утрехт",
              "count": 2,
              "chld": [
                  {
                      "id": "144940",
                      "name": "Амерсфорт",
                      "count": 1
                  },
                  {
                      "id": "101472",
                      "name": "Утрехт",
                      "count": 1
                  }
              ]
          },
          {
              "id": "104311",
              "name": "Фрисландия",
              "count": 2,
              "chld": [
                  {
                      "id": "105151",
                      "name": "Леуварден",
                      "count": 1
                  }
              ]
          },
          {
              "id": "104307",
              "name": "Северный Брабант",
              "count": 1,
              "chld": [
                  {
                      "id": "113092",
                      "name": "Росендал",
                      "count": 1
                  }
              ]
          }
      ]
  },
  {
      "id": "169",
      "name": "Грузия",
      "count": 146,
      "chld": [
          {
              "id": "4294967294",
              "name": "Без области",
              "count": 86,
              "chld": [
                  {
                      "id": "10277",
                      "name": "Тбилиси",
                      "count": 86
                  }
              ]
          },
          {
              "id": "122054",
              "name": "Автономная Республика Аджария",
              "count": 29,
              "chld": [
                  {
                      "id": "10278",
                      "name": "Батуми",
                      "count": 29
                  }
              ]
          },
          {
              "id": "122062",
              "name": "Квемо-Картли",
              "count": 18,
              "chld": [
                  {
                      "id": "10285",
                      "name": "Рустави",
                      "count": 18
                  }
              ]
          },
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 13
          }
      ]
  },
  {
      "id": "168",
      "name": "Армения",
      "count": 120,
      "chld": [
          {
              "id": "4294967294",
              "name": "Без области",
              "count": 98,
              "chld": [
                  {
                      "id": "10262",
                      "name": "Ереван",
                      "count": 98
                  }
              ]
          },
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 12
          },
          {
              "id": "105793",
              "name": "Область Котайк",
              "count": 4,
              "chld": [
                  {
                      "id": "116128",
                      "name": "Абовян",
                      "count": 1
                  }
              ]
          },
          {
              "id": "105791",
              "name": "Область Армавир",
              "count": 3,
              "chld": [
                  {
                      "id": "10257",
                      "name": "Армавир",
                      "count": 3
                  }
              ]
          },
          {
              "id": "105789",
              "name": "Область Арагацотн",
              "count": 1,
              "chld": [
                  {
                      "id": "105799",
                      "name": "Аштарак",
                      "count": 1
                  }
              ]
          },
          {
              "id": "105790",
              "name": "Область Арарат",
              "count": 1,
              "chld": [
                  {
                      "id": "116120",
                      "name": "Веди",
                      "count": 1
                  }
              ]
          },
          {
              "id": "105795",
              "name": "Область Ширак",
              "count": 1,
              "chld": [
                  {
                      "id": "10259",
                      "name": "Гюмри",
                      "count": 1
                  }
              ]
          }
      ]
  },
  {
      "id": "102",
      "name": "Великобритания",
      "count": 106,
      "chld": [
          {
              "id": "4294967294",
              "name": "Без области",
              "count": 37,
              "chld": [
                  {
                      "id": "10393",
                      "name": "Лондон",
                      "count": 36
                  },
                  {
                      "id": "104562",
                      "name": "Кардифф",
                      "count": 1
                  }
              ]
          },
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 31
          },
          {
              "id": "104623",
              "name": "Графство Беркшир",
              "count": 12,
              "chld": [
                  {
                      "id": "123254",
                      "name": "Слау",
                      "count": 9
                  },
                  {
                      "id": "109550",
                      "name": "Мейденхед",
                      "count": 1
                  },
                  {
                      "id": "109555",
                      "name": "Ньюбери",
                      "count": 1
                  },
                  {
                      "id": "105764",
                      "name": "Рединг",
                      "count": 1
                  }
              ]
          },
          {
              "id": "104608",
              "name": "Графство Уэст-Мидлендс",
              "count": 5,
              "chld": [
                  {
                      "id": "21134",
                      "name": "Бирмингем",
                      "count": 2
                  },
                  {
                      "id": "145403",
                      "name": "Уолсолл",
                      "count": 2
                  },
                  {
                      "id": "109514",
                      "name": "Ковентри",
                      "count": 1
                  }
              ]
          },
          {
              "id": "104559",
              "name": "Город-графство Торвайн",
              "count": 4,
              "chld": [
                  {
                      "id": "105061",
                      "name": "Понтипул",
                      "count": 4
                  }
              ]
          },
          {
              "id": "104604",
              "name": "Графство Мерсисайд",
              "count": 2,
              "chld": [
                  {
                      "id": "10392",
                      "name": "Ливерпуль",
                      "count": 2
                  }
              ]
          },
          {
              "id": "104600",
              "name": "Графство Ноттингемшир",
              "count": 2,
              "chld": [
                  {
                      "id": "104981",
                      "name": "Ноттингем",
                      "count": 2
                  }
              ]
          },
          {
              "id": "104627",
              "name": "Графство Суррей",
              "count": 2
          },
          {
              "id": "104628",
              "name": "Графство Хэмпшир",
              "count": 2,
              "chld": [
                  {
                      "id": "109563",
                      "name": "Саутгемптон",
                      "count": 1
                  },
                  {
                      "id": "114663",
                      "name": "Фархэм",
                      "count": 1
                  }
              ]
          },
          {
              "id": "104578",
              "name": "Графство Антрим",
              "count": 1,
              "chld": [
                  {
                      "id": "145200",
                      "name": "Баллимони",
                      "count": 1
                  }
              ]
          },
          {
              "id": "104618",
              "name": "Графство Бакингемшир",
              "count": 1
          },
          {
              "id": "104603",
              "name": "Графство Большой Манчестер",
              "count": 1,
              "chld": [
                  {
                      "id": "10394",
                      "name": "Манчестер",
                      "count": 1
                  }
              ]
          },
          {
              "id": "104631",
              "name": "Графство Девон",
              "count": 1,
              "chld": [
                  {
                      "id": "105763",
                      "name": "Эксетер",
                      "count": 1
                  }
              ]
          },
          {
              "id": "104602",
              "name": "Графство Дербишир",
              "count": 1,
              "chld": [
                  {
                      "id": "21468",
                      "name": "Дерби",
                      "count": 1
                  }
              ]
          },
          {
              "id": "104612",
              "name": "Графство Кембриджшир",
              "count": 1,
              "chld": [
                  {
                      "id": "10391",
                      "name": "Кембридж",
                      "count": 1
                  }
              ]
          },
          {
              "id": "104624",
              "name": "Графство Кент",
              "count": 1,
              "chld": [
                  {
                      "id": "109494",
                      "name": "Ашфорд",
                      "count": 1
                  }
              ]
          },
          {
              "id": "104596",
              "name": "Графство Ланкашир",
              "count": 1,
              "chld": [
                  {
                      "id": "104975",
                      "name": "Престон",
                      "count": 1
                  }
              ]
          },
          {
              "id": "104601",
              "name": "Графство Южный Йоркшир",
              "count": 1,
              "chld": [
                  {
                      "id": "100479",
                      "name": "Донкастер",
                      "count": 1
                  }
              ]
          }
      ]
  },
  {
      "id": "171",
      "name": "Узбекистан",
      "count": 105,
      "chld": [
          {
              "id": "4294967294",
              "name": "Без области",
              "count": 94,
              "chld": [
                  {
                      "id": "10335",
                      "name": "Ташкент",
                      "count": 94
                  }
              ]
          },
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 11
          }
      ]
  },
  {
      "id": "167",
      "name": "Азербайджан",
      "count": 101,
      "chld": [
          {
              "id": "4294967294",
              "name": "Без области",
              "count": 96,
              "chld": [
                  {
                      "id": "10253",
                      "name": "Баку",
                      "count": 95
                  },
                  {
                      "id": "105881",
                      "name": "Ленкорань",
                      "count": 1
                  }
              ]
          },
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 4
          },
          {
              "id": "105817",
              "name": "Апшеронский район",
              "count": 1,
              "chld": [
                  {
                      "id": "105888",
                      "name": "Хырдалан",
                      "count": 1
                  }
              ]
          }
      ]
  },
  {
      "id": "1056",
      "name": "Египет",
      "count": 90,
      "chld": [
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 44
          },
          {
              "id": "121455",
              "name": "Мухафаза Каир",
              "count": 33,
              "chld": [
                  {
                      "id": "11485",
                      "name": "Каир",
                      "count": 33
                  }
              ]
          },
          {
              "id": "121451",
              "name": "Мухафаза Исмаилия",
              "count": 3,
              "chld": [
                  {
                      "id": "111906",
                      "name": "Исмаилия",
                      "count": 3
                  }
              ]
          },
          {
              "id": "121450",
              "name": "Мухафаза Александрия",
              "count": 2,
              "chld": [
                  {
                      "id": "11484",
                      "name": "Александрия",
                      "count": 2
                  }
              ]
          },
          {
              "id": "121447",
              "name": "Мухафаза Бухейра",
              "count": 2,
              "chld": [
                  {
                      "id": "111901",
                      "name": "Даманхур",
                      "count": 2
                  }
              ]
          },
          {
              "id": "121452",
              "name": "Мухафаза Эль-Гиза",
              "count": 2,
              "chld": [
                  {
                      "id": "114930",
                      "name": "Гиза",
                      "count": 2
                  }
              ]
          },
          {
              "id": "121460",
              "name": "Мухафаза Суэц",
              "count": 1,
              "chld": [
                  {
                      "id": "121473",
                      "name": "Суэц",
                      "count": 1
                  }
              ]
          },
          {
              "id": "121459",
              "name": "Мухафаза Шаркия",
              "count": 1,
              "chld": [
                  {
                      "id": "111902",
                      "name": "Эз-Заказик",
                      "count": 1
                  }
              ]
          },
          {
              "id": "121448",
              "name": "Мухафаза Эль-Файюм",
              "count": 1,
              "chld": [
                  {
                      "id": "111899",
                      "name": "Эль-Файюм",
                      "count": 1
                  }
              ]
          },
          {
              "id": "121466",
              "name": "Мухафаза Южный Синай",
              "count": 1,
              "chld": [
                  {
                      "id": "11487",
                      "name": "Шарм-эль-Шейх",
                      "count": 1
                  }
              ]
          }
      ]
  },
  {
      "id": "94",
      "name": "Бразилия",
      "count": 90,
      "chld": [
          {
              "id": "29315",
              "name": "Штат Сан-Паулу",
              "count": 26,
              "chld": [
                  {
                      "id": "20924",
                      "name": "Сан-Паулу",
                      "count": 8
                  },
                  {
                      "id": "111255",
                      "name": "Сан-Жозе-дус-Кампус",
                      "count": 3
                  },
                  {
                      "id": "111274",
                      "name": "Бауру",
                      "count": 2
                  },
                  {
                      "id": "219495",
                      "name": "Индаятуба",
                      "count": 2
                  },
                  {
                      "id": "219430",
                      "name": "Сан-Бернарду-ду-Кампу",
                      "count": 2
                  },
                  {
                      "id": "219182",
                      "name": "Taboão da Serra",
                      "count": 1
                  },
                  {
                      "id": "111266",
                      "name": "Лимейра",
                      "count": 1
                  },
                  {
                      "id": "111237",
                      "name": "Можи-Гуасу",
                      "count": 1
                  },
                  {
                      "id": "115631",
                      "name": "Озаску",
                      "count": 1
                  },
                  {
                      "id": "109482",
                      "name": "Санту-Андре",
                      "count": 1
                  },
                  {
                      "id": "115652",
                      "name": "Сузану",
                      "count": 1
                  },
                  {
                      "id": "115653",
                      "name": "Таубате",
                      "count": 1
                  }
              ]
          },
          {
              "id": "29303",
              "name": "Штат Минас-Жерайс",
              "count": 11,
              "chld": [
                  {
                      "id": "21760",
                      "name": "Белу-Оризонти",
                      "count": 4
                  },
                  {
                      "id": "219076",
                      "name": "Araguari",
                      "count": 1
                  },
                  {
                      "id": "111268",
                      "name": "Говернадор-Валадарис",
                      "count": 1
                  },
                  {
                      "id": "219446",
                      "name": "Лагоа-Санта",
                      "count": 1
                  }
              ]
          },
          {
              "id": "29306",
              "name": "Штат Парана",
              "count": 8,
              "chld": [
                  {
                      "id": "98537",
                      "name": "Куритиба",
                      "count": 6
                  },
                  {
                      "id": "109471",
                      "name": "Лондрина",
                      "count": 1
                  },
                  {
                      "id": "109473",
                      "name": "Маринга",
                      "count": 1
                  }
              ]
          },
          {
              "id": "29309",
              "name": "Штат Рио-де-Жанейро",
              "count": 7,
              "chld": [
                  {
                      "id": "21221",
                      "name": "Рио-де-Жанейро",
                      "count": 4
                  },
                  {
                      "id": "114608",
                      "name": "Нитерой",
                      "count": 1
                  },
                  {
                      "id": "111222",
                      "name": "Сан-Гонсалу",
                      "count": 1
                  }
              ]
          },
          {
              "id": "29307",
              "name": "Штат Пернамбуку",
              "count": 6,
              "chld": [
                  {
                      "id": "105728",
                      "name": "Ресифи",
                      "count": 5
                  },
                  {
                      "id": "115632",
                      "name": "Жабоатан-дус-Гуарарапес",
                      "count": 1
                  }
              ]
          },
          {
              "id": "29311",
              "name": "Штат Риу-Гранди-ду-Сул",
              "count": 6,
              "chld": [
                  {
                      "id": "111241",
                      "name": "Нову-Амбургу",
                      "count": 2
                  },
                  {
                      "id": "219538",
                      "name": "Виаман",
                      "count": 1
                  },
                  {
                      "id": "109463",
                      "name": "Кашиас-ду-Сул",
                      "count": 1
                  },
                  {
                      "id": "111329",
                      "name": "Пелотас",
                      "count": 1
                  },
                  {
                      "id": "105731",
                      "name": "Порту-Алегри",
                      "count": 1
                  }
              ]
          },
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 5
          },
          {
              "id": "29314",
              "name": "Штат Санта-Катарина",
              "count": 5,
              "chld": [
                  {
                      "id": "101585",
                      "name": "Флорианополис",
                      "count": 3
                  },
                  {
                      "id": "109470",
                      "name": "Жоинвили",
                      "count": 1
                  }
              ]
          },
          {
              "id": "29297",
              "name": "Штат Сеара",
              "count": 4,
              "chld": [
                  {
                      "id": "105735",
                      "name": "Форталеза",
                      "count": 4
                  }
              ]
          },
          {
              "id": "29305",
              "name": "Штат Параиба",
              "count": 3
          },
          {
              "id": "29296",
              "name": "Штат Баия",
              "count": 3,
              "chld": [
                  {
                      "id": "219292",
                      "name": "Иресе",
                      "count": 1
                  }
              ]
          },
          {
              "id": "29293",
              "name": "Штат Алагоас",
              "count": 2,
              "chld": [
                  {
                      "id": "219553",
                      "name": "Пенеду",
                      "count": 1
                  }
              ]
          },
          {
              "id": "115091",
              "name": "Федеральный округ",
              "count": 1
          },
          {
              "id": "29304",
              "name": "Штат Пара",
              "count": 1,
              "chld": [
                  {
                      "id": "105726",
                      "name": "Белен",
                      "count": 1
                  }
              ]
          },
          {
              "id": "29308",
              "name": "Штат Пиауи",
              "count": 1
          },
          {
              "id": "29316",
              "name": "Штат Сержипи",
              "count": 1,
              "chld": [
                  {
                      "id": "219053",
                      "name": "Lagarto",
                      "count": 1
                  }
              ]
          }
      ]
  },
  {
      "id": "208",
      "name": "Молдова",
      "count": 89,
      "chld": [
          {
              "id": "4294967294",
              "name": "Без области",
              "count": 40,
              "chld": [
                  {
                      "id": "10313",
                      "name": "Кишинев",
                      "count": 40
                  }
              ]
          },
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 35
          },
          {
              "id": "104298",
              "name": "Тараклийский район",
              "count": 6,
              "chld": [
                  {
                      "id": "101476",
                      "name": "Тараклия",
                      "count": 6
                  }
              ]
          },
          {
              "id": "104272",
              "name": "Кагульский район",
              "count": 4,
              "chld": [
                  {
                      "id": "101757",
                      "name": "Кагул",
                      "count": 2
                  }
              ]
          },
          {
              "id": "104927",
              "name": "Левобережье Днестра",
              "count": 3,
              "chld": [
                  {
                      "id": "10317",
                      "name": "Тирасполь",
                      "count": 3
                  }
              ]
          },
          {
              "id": "104290",
              "name": "Оргеевский район",
              "count": 1,
              "chld": [
                  {
                      "id": "100523",
                      "name": "Оргеев",
                      "count": 1
                  }
              ]
          }
      ]
  },
  {
      "id": "20271",
      "name": "Мексика",
      "count": 83,
      "chld": [
          {
              "id": "105659",
              "name": "Штат Кинтана-Роо",
              "count": 18,
              "chld": [
                  {
                      "id": "109706",
                      "name": "Пуэрто Хуарес",
                      "count": 7
                  }
              ]
          },
          {
              "id": "105683",
              "name": "Федеральный округ",
              "count": 14,
              "chld": [
                  {
                      "id": "20272",
                      "name": "Мехико",
                      "count": 14
                  }
              ]
          },
          {
              "id": "105662",
              "name": "Штат Мехико",
              "count": 9,
              "chld": [
                  {
                      "id": "219248",
                      "name": "Коакалько-де-Берриосабаль",
                      "count": 3
                  },
                  {
                      "id": "114920",
                      "name": "Несауалькойотль",
                      "count": 2
                  },
                  {
                      "id": "219260",
                      "name": "Сьюдад-Лопес-Матеос",
                      "count": 1
                  },
                  {
                      "id": "105695",
                      "name": "Толука-де-Лердо",
                      "count": 1
                  }
              ]
          },
          {
              "id": "105668",
              "name": "Штат Нуэво-Леон",
              "count": 8,
              "chld": [
                  {
                      "id": "21293",
                      "name": "Монтеррей",
                      "count": 6
                  },
                  {
                      "id": "109679",
                      "name": "Аподака",
                      "count": 1
                  }
              ]
          },
          {
              "id": "105679",
              "name": "Штат Халиско",
              "count": 8,
              "chld": [
                  {
                      "id": "21515",
                      "name": "Гвадалахара",
                      "count": 5
                  },
                  {
                      "id": "21302",
                      "name": "Пуэрто-Вальярта",
                      "count": 1
                  }
              ]
          },
          {
              "id": "105656",
              "name": "Штат Идальго",
              "count": 4,
              "chld": [
                  {
                      "id": "105689",
                      "name": "Пачука-де-Сото",
                      "count": 3
                  }
              ]
          },
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 3
          },
          {
              "id": "105660",
              "name": "Штат Коауила",
              "count": 2
          },
          {
              "id": "105663",
              "name": "Штат Мичоакан",
              "count": 2,
              "chld": [
                  {
                      "id": "113033",
                      "name": "Самора-де-Идальго",
                      "count": 2
                  }
              ]
          },
          {
              "id": "105664",
              "name": "Штат Морелос",
              "count": 2,
              "chld": [
                  {
                      "id": "105697",
                      "name": "Куэрнавака",
                      "count": 2
                  }
              ]
          },
          {
              "id": "105676",
              "name": "Штат Табаско",
              "count": 2
          },
          {
              "id": "105682",
              "name": "Штат Юкатан",
              "count": 2,
              "chld": [
                  {
                      "id": "105709",
                      "name": "Мерида",
                      "count": 2
                  }
              ]
          },
          {
              "id": "105654",
              "name": "Штат Гуанахуато",
              "count": 2,
              "chld": [
                  {
                      "id": "109681",
                      "name": "Селая",
                      "count": 1
                  }
              ]
          },
          {
              "id": "105652",
              "name": "Штат Веракрус",
              "count": 1,
              "chld": [
                  {
                      "id": "113046",
                      "name": "Орисаба",
                      "count": 1
                  }
              ]
          },
          {
              "id": "105655",
              "name": "Штат Дуранго",
              "count": 1,
              "chld": [
                  {
                      "id": "105688",
                      "name": "Виктория-де-Дуранго",
                      "count": 1
                  }
              ]
          },
          {
              "id": "105661",
              "name": "Штат Колима",
              "count": 1,
              "chld": [
                  {
                      "id": "105694",
                      "name": "Колима",
                      "count": 1
                  }
              ]
          },
          {
              "id": "105666",
              "name": "Штат Нижняя Калифорния",
              "count": 1,
              "chld": [
                  {
                      "id": "101662",
                      "name": "Мехикали",
                      "count": 1
                  }
              ]
          },
          {
              "id": "105673",
              "name": "Штат Сан-Луис-Потоси",
              "count": 1,
              "chld": [
                  {
                      "id": "105702",
                      "name": "Сан-Луис-Потоси",
                      "count": 1
                  }
              ]
          },
          {
              "id": "105675",
              "name": "Штат Сонора",
              "count": 1,
              "chld": [
                  {
                      "id": "113051",
                      "name": "Гуаймас",
                      "count": 1
                  }
              ]
          },
          {
              "id": "105680",
              "name": "Штат Чиуауа",
              "count": 1,
              "chld": [
                  {
                      "id": "109683",
                      "name": "Сьюдад-Хуарес",
                      "count": 1
                  }
              ]
          }
      ]
  },
  {
      "id": "205",
      "name": "Италия",
      "count": 82,
      "chld": [
          {
              "id": "104222",
              "name": "Ломбардия",
              "count": 22,
              "chld": [
                  {
                      "id": "10448",
                      "name": "Милан",
                      "count": 14
                  },
                  {
                      "id": "21077",
                      "name": "Бергамо",
                      "count": 1
                  },
                  {
                      "id": "101454",
                      "name": "Брешиа",
                      "count": 1
                  },
                  {
                      "id": "123195",
                      "name": "Комо",
                      "count": 1
                  },
                  {
                      "id": "145065",
                      "name": "Леньяно",
                      "count": 1
                  }
              ]
          },
          {
              "id": "104220",
              "name": "Лацио",
              "count": 14,
              "chld": [
                  {
                      "id": "10445",
                      "name": "Рим",
                      "count": 14
                  }
              ]
          },
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 8
          },
          {
              "id": "104228",
              "name": "Тоскана",
              "count": 7,
              "chld": [
                  {
                      "id": "10458",
                      "name": "Пиза",
                      "count": 1
                  },
                  {
                      "id": "144389",
                      "name": "Портоферрайо",
                      "count": 1
                  },
                  {
                      "id": "145127",
                      "name": "Розиньяно-Мариттимо",
                      "count": 1
                  },
                  {
                      "id": "10461",
                      "name": "Флоренция",
                      "count": 1
                  }
              ]
          },
          {
              "id": "104217",
              "name": "Венето",
              "count": 6,
              "chld": [
                  {
                      "id": "10452",
                      "name": "Верона",
                      "count": 3
                  },
                  {
                      "id": "10451",
                      "name": "Венеция",
                      "count": 1
                  }
              ]
          },
          {
              "id": "104225",
              "name": "Пьемонт",
              "count": 6,
              "chld": [
                  {
                      "id": "10449",
                      "name": "Турин",
                      "count": 3
                  },
                  {
                      "id": "112824",
                      "name": "Кунео",
                      "count": 1
                  }
              ]
          },
          {
              "id": "104232",
              "name": "Эмилия-Романья",
              "count": 5,
              "chld": [
                  {
                      "id": "10444",
                      "name": "Болонья",
                      "count": 3
                  },
                  {
                      "id": "112784",
                      "name": "Равенна",
                      "count": 1
                  }
              ]
          },
          {
              "id": "104214",
              "name": "Апулия",
              "count": 2
          },
          {
              "id": "104223",
              "name": "Марке",
              "count": 2
          },
          {
              "id": "104219",
              "name": "Кампания",
              "count": 2,
              "chld": [
                  {
                      "id": "10446",
                      "name": "Неаполь",
                      "count": 1
                  }
              ]
          },
          {
              "id": "104229",
              "name": "Трентино - Альто-Адидже",
              "count": 2,
              "chld": [
                  {
                      "id": "145128",
                      "name": "Роверето",
                      "count": 1
                  },
                  {
                      "id": "105082",
                      "name": "Тренто",
                      "count": 1
                  }
              ]
          },
          {
              "id": "104213",
              "name": "Абруццо",
              "count": 1
          },
          {
              "id": "104215",
              "name": "Базиликата",
              "count": 1
          },
          {
              "id": "104218",
              "name": "Калабрия",
              "count": 1
          },
          {
              "id": "104221",
              "name": "Лигурия",
              "count": 1,
              "chld": [
                  {
                      "id": "10447",
                      "name": "Генуя",
                      "count": 1
                  }
              ]
          },
          {
              "id": "104227",
              "name": "Сицилия",
              "count": 1,
              "chld": [
                  {
                      "id": "111970",
                      "name": "Мессина",
                      "count": 1
                  }
              ]
          },
          {
              "id": "104231",
              "name": "Фриули-Венеция-Джулия",
              "count": 1
          }
      ]
  },
  {
      "id": "10536",
      "name": "Иран",
      "count": 77,
      "chld": [
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 28
          },
          {
              "id": "121484",
              "name": "Остан Тегеран",
              "count": 25,
              "chld": [
                  {
                      "id": "11495",
                      "name": "Тегеран",
                      "count": 25
                  }
              ]
          },
          {
              "id": "121512",
              "name": "Остан Хорасан-Резави",
              "count": 6,
              "chld": [
                  {
                      "id": "112695",
                      "name": "Мешхед",
                      "count": 6
                  }
              ]
          },
          {
              "id": "121491",
              "name": "Остан Восточный Азербайджан",
              "count": 5,
              "chld": [
                  {
                      "id": "112703",
                      "name": "Тебриз",
                      "count": 5
                  }
              ]
          },
          {
              "id": "121507",
              "name": "Остан Исфахан",
              "count": 5,
              "chld": [
                  {
                      "id": "112699",
                      "name": "Исфахан",
                      "count": 5
                  }
              ]
          },
          {
              "id": "121502",
              "name": "Остан Фарс",
              "count": 3,
              "chld": [
                  {
                      "id": "20838",
                      "name": "Шираз",
                      "count": 3
                  }
              ]
          },
          {
              "id": "121485",
              "name": "Остан Кум",
              "count": 2,
              "chld": [
                  {
                      "id": "112696",
                      "name": "Кум",
                      "count": 2
                  }
              ]
          },
          {
              "id": "121489",
              "name": "Остан Ардебиль",
              "count": 1,
              "chld": [
                  {
                      "id": "112719",
                      "name": "Ардебиль",
                      "count": 1
                  }
              ]
          },
          {
              "id": "121487",
              "name": "Остан Казвин",
              "count": 1,
              "chld": [
                  {
                      "id": "112694",
                      "name": "Казвин",
                      "count": 1
                  }
              ]
          },
          {
              "id": "121498",
              "name": "Остан Хузестан",
              "count": 1,
              "chld": [
                  {
                      "id": "112705",
                      "name": "Ахваз",
                      "count": 1
                  }
              ]
          }
      ]
  },
  {
      "id": "10540",
      "name": "Саудовская Аравия",
      "count": 75,
      "chld": [
          {
              "id": "4294967294",
              "name": "Без области",
              "count": 73,
              "chld": [
                  {
                      "id": "11500",
                      "name": "Эр-Рияд",
                      "count": 34
                  },
                  {
                      "id": "114139",
                      "name": "Джидда",
                      "count": 19
                  },
                  {
                      "id": "40419",
                      "name": "Даммам",
                      "count": 13
                  },
                  {
                      "id": "40249",
                      "name": "Медина",
                      "count": 2
                  },
                  {
                      "id": "41030",
                      "name": "Мекка",
                      "count": 2
                  },
                  {
                      "id": "114156",
                      "name": "Табук",
                      "count": 1
                  },
                  {
                      "id": "114147",
                      "name": "Хамис-Мушаит",
                      "count": 1
                  },
                  {
                      "id": "114137",
                      "name": "Эль-Хуфуф",
                      "count": 1
                  }
              ]
          },
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 2
          }
      ]
  },
  {
      "id": "995",
      "name": "Таиланд",
      "count": 75,
      "chld": [
          {
              "id": "122005",
              "name": "Провинция Пхукет",
              "count": 23,
              "chld": [
                  {
                      "id": "10622",
                      "name": "Пхукет",
                      "count": 17
                  },
                  {
                      "id": "155382",
                      "name": "Чалонг",
                      "count": 6
                  }
              ]
          },
          {
              "id": "4294967294",
              "name": "Без области",
              "count": 19,
              "chld": [
                  {
                      "id": "10620",
                      "name": "Бангкок",
                      "count": 19
                  }
              ]
          },
          {
              "id": "121997",
              "name": "Провинция Чонбури",
              "count": 16,
              "chld": [
                  {
                      "id": "21341",
                      "name": "Паттайя",
                      "count": 8
                  },
                  {
                      "id": "109040",
                      "name": "Чонбури",
                      "count": 8
                  }
              ]
          },
          {
              "id": "121963",
              "name": "Провинция Чиангмай",
              "count": 3,
              "chld": [
                  {
                      "id": "20825",
                      "name": "Чиангмай",
                      "count": 3
                  }
              ]
          },
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 2
          },
          {
              "id": "121974",
              "name": "Провинция Ангтхонг",
              "count": 2,
              "chld": [
                  {
                      "id": "122024",
                      "name": "Ангтхонг",
                      "count": 2
                  }
              ]
          },
          {
              "id": "121993",
              "name": "Провинция Районг",
              "count": 2,
              "chld": [
                  {
                      "id": "109062",
                      "name": "Районг",
                      "count": 2
                  }
              ]
          },
          {
              "id": "122009",
              "name": "Провинция Сураттани",
              "count": 2,
              "chld": [
                  {
                      "id": "114398",
                      "name": "Сураттани",
                      "count": 2
                  }
              ]
          },
          {
              "id": "122012",
              "name": "Провинция Махасаракам",
              "count": 1,
              "chld": [
                  {
                      "id": "122030",
                      "name": "Махасаракам",
                      "count": 1
                  }
              ]
          },
          {
              "id": "121979",
              "name": "Провинция Наконпатом",
              "count": 1,
              "chld": [
                  {
                      "id": "114408",
                      "name": "Накхонпатом",
                      "count": 1
                  }
              ]
          },
          {
              "id": "121952",
              "name": "Провинция Накхонсаван",
              "count": 1,
              "chld": [
                  {
                      "id": "122033",
                      "name": "Накхонсаван",
                      "count": 1
                  }
              ]
          },
          {
              "id": "122000",
              "name": "Провинция Накхонситхаммарат",
              "count": 1,
              "chld": [
                  {
                      "id": "114405",
                      "name": "Накхонситхаммарат",
                      "count": 1
                  }
              ]
          },
          {
              "id": "122008",
              "name": "Провинция Сонгкхла",
              "count": 1,
              "chld": [
                  {
                      "id": "114404",
                      "name": "Хатъяй",
                      "count": 1
                  }
              ]
          },
          {
              "id": "121973",
              "name": "Провинция Ясотхон",
              "count": 1,
              "chld": [
                  {
                      "id": "122053",
                      "name": "Ясотхон",
                      "count": 1
                  }
              ]
          }
      ]
  },
  {
      "id": "10108",
      "name": "Филиппины",
      "count": 71,
      "chld": [
          {
              "id": "144393",
              "name": "Столичный регион",
              "count": 27,
              "chld": [
                  {
                      "id": "10629",
                      "name": "Манила",
                      "count": 25
                  },
                  {
                      "id": "114892",
                      "name": "Макати",
                      "count": 2
                  }
              ]
          },
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 14
          },
          {
              "id": "144411",
              "name": "Провинция Нуэва-Эсиха",
              "count": 4
          },
          {
              "id": "144415",
              "name": "Провинция Кавите",
              "count": 4,
              "chld": [
                  {
                      "id": "144551",
                      "name": "Имус",
                      "count": 1
                  }
              ]
          },
          {
              "id": "144449",
              "name": "Провинция Восточный Мисамис",
              "count": 3,
              "chld": [
                  {
                      "id": "21065",
                      "name": "Кагаян-де-Оро",
                      "count": 3
                  }
              ]
          },
          {
              "id": "144437",
              "name": "Провинция Себу",
              "count": 3,
              "chld": [
                  {
                      "id": "114083",
                      "name": "Себу",
                      "count": 3
                  }
              ]
          },
          {
              "id": "144456",
              "name": "Провинция Южный Давао",
              "count": 3,
              "chld": [
                  {
                      "id": "10626",
                      "name": "Давао",
                      "count": 3
                  }
              ]
          },
          {
              "id": "144410",
              "name": "Провинция Булакан",
              "count": 2,
              "chld": [
                  {
                      "id": "114090",
                      "name": "Малолос",
                      "count": 1
                  }
              ]
          },
          {
              "id": "144417",
              "name": "Провинция Лагуна",
              "count": 2,
              "chld": [
                  {
                      "id": "114106",
                      "name": "Каламба",
                      "count": 1
                  },
                  {
                      "id": "114108",
                      "name": "Сан-Пабло",
                      "count": 1
                  }
              ]
          },
          {
              "id": "144414",
              "name": "Провинция Батангас",
              "count": 1
          },
          {
              "id": "144436",
              "name": "Провинция Восточный Негрос",
              "count": 1,
              "chld": [
                  {
                      "id": "114098",
                      "name": "Думагете",
                      "count": 1
                  }
              ]
          },
          {
              "id": "144435",
              "name": "Провинция Западный Негрос",
              "count": 1
          },
          {
              "id": "144433",
              "name": "Провинция Илоило",
              "count": 1,
              "chld": [
                  {
                      "id": "114082",
                      "name": "Илоило",
                      "count": 1
                  }
              ]
          },
          {
              "id": "144384",
              "name": "Провинция Ла-Унион",
              "count": 1,
              "chld": [
                  {
                      "id": "114120",
                      "name": "Сан-Фернандо",
                      "count": 1
                  }
              ]
          },
          {
              "id": "144418",
              "name": "Провинция Рисаль",
              "count": 1
          },
          {
              "id": "144462",
              "name": "Провинция Северный Агусан",
              "count": 1,
              "chld": [
                  {
                      "id": "114099",
                      "name": "Бутуан",
                      "count": 1
                  }
              ]
          },
          {
              "id": "144452",
              "name": "Провинция Северный Ланао",
              "count": 1
          },
          {
              "id": "144413",
              "name": "Провинция Тарлак",
              "count": 1,
              "chld": [
                  {
                      "id": "144575",
                      "name": "Тарлак",
                      "count": 1
                  }
              ]
          }
      ]
  },
  {
      "id": "10074",
      "name": "Португалия",
      "count": 69,
      "chld": [
          {
              "id": "104343",
              "name": "Округ Лиссабон",
              "count": 29,
              "chld": [
                  {
                      "id": "10478",
                      "name": "Лиссабон",
                      "count": 28
                  },
                  {
                      "id": "145294",
                      "name": "Уэйраш",
                      "count": 1
                  }
              ]
          },
          {
              "id": "105205",
              "name": "Мадейра",
              "count": 16,
              "chld": [
                  {
                      "id": "20937",
                      "name": "Фуншал",
                      "count": 16
                  }
              ]
          },
          {
              "id": "104345",
              "name": "Округ Порту",
              "count": 13,
              "chld": [
                  {
                      "id": "10479",
                      "name": "Порту",
                      "count": 5
                  }
              ]
          },
          {
              "id": "104341",
              "name": "Округ Коимбра",
              "count": 9
          },
          {
              "id": "104332",
              "name": "Округ Авейру",
              "count": 1
          },
          {
              "id": "104347",
              "name": "Округ Сетубал",
              "count": 1,
              "chld": [
                  {
                      "id": "100537",
                      "name": "Алмада",
                      "count": 1
                  }
              ]
          }
      ]
  },
  {
      "id": "10020",
      "name": "Марокко",
      "count": 66,
      "chld": [
          {
              "id": "4294967294",
              "name": "Без области",
              "count": 58,
              "chld": [
                  {
                      "id": "10138",
                      "name": "Касабланка",
                      "count": 26
                  },
                  {
                      "id": "21258",
                      "name": "Марракеш",
                      "count": 7
                  },
                  {
                      "id": "60150",
                      "name": "Мекнес",
                      "count": 7
                  },
                  {
                      "id": "60101",
                      "name": "Танжер",
                      "count": 4
                  },
                  {
                      "id": "20745",
                      "name": "Агадир",
                      "count": 3
                  },
                  {
                      "id": "144937",
                      "name": "Сале",
                      "count": 3
                  },
                  {
                      "id": "20956",
                      "name": "Фес",
                      "count": 3
                  },
                  {
                      "id": "10139",
                      "name": "Рабат",
                      "count": 2
                  },
                  {
                      "id": "113065",
                      "name": "Уджда",
                      "count": 2
                  },
                  {
                      "id": "109392",
                      "name": "Эль-Джадида",
                      "count": 1
                  }
              ]
          },
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 8
          }
      ]
  },
  {
      "id": "181",
      "name": "Израиль",
      "count": 65,
      "chld": [
          {
              "id": "103905",
              "name": "Тель-Авивский АО",
              "count": 41,
              "chld": [
                  {
                      "id": "131",
                      "name": "Тель-Авив",
                      "count": 23
                  },
                  {
                      "id": "108938",
                      "name": "Герцлия",
                      "count": 7
                  },
                  {
                      "id": "218694",
                      "name": "Кирьят-Оно",
                      "count": 5
                  },
                  {
                      "id": "101443",
                      "name": "Бат-Ям",
                      "count": 2
                  },
                  {
                      "id": "218692",
                      "name": "Рамат-Ган",
                      "count": 2
                  },
                  {
                      "id": "218693",
                      "name": "Гиватаим",
                      "count": 1
                  },
                  {
                      "id": "218695",
                      "name": "Ор Йехуда",
                      "count": 1
                  }
              ]
          },
          {
              "id": "103900",
              "name": "Центральный АО",
              "count": 10,
              "chld": [
                  {
                      "id": "116114",
                      "name": "Ришон-ле-Цион",
                      "count": 4
                  },
                  {
                      "id": "101739",
                      "name": "Петах-Тиква",
                      "count": 2
                  },
                  {
                      "id": "218668",
                      "name": "Нес-Циона",
                      "count": 1
                  }
              ]
          },
          {
              "id": "103904",
              "name": "Южный АО",
              "count": 6,
              "chld": [
                  {
                      "id": "100944",
                      "name": "Ашкелон",
                      "count": 2
                  },
                  {
                      "id": "101391",
                      "name": "Ашдод",
                      "count": 1
                  },
                  {
                      "id": "129",
                      "name": "Беэр-Шева",
                      "count": 1
                  },
                  {
                      "id": "101509",
                      "name": "Кирьят-Гат",
                      "count": 1
                  },
                  {
                      "id": "20797",
                      "name": "Эйлат",
                      "count": 1
                  }
              ]
          },
          {
              "id": "103901",
              "name": "Хайфский АО",
              "count": 3,
              "chld": [
                  {
                      "id": "132",
                      "name": "Хайфа",
                      "count": 3
                  }
              ]
          },
          {
              "id": "103903",
              "name": "Иерусалимский АО",
              "count": 3,
              "chld": [
                  {
                      "id": "130",
                      "name": "Иерусалим",
                      "count": 2
                  }
              ]
          },
          {
              "id": "103902",
              "name": "Северный АО",
              "count": 2
          }
      ]
  },
  {
      "id": "127",
      "name": "Швеция",
      "count": 54,
      "chld": [
          {
              "id": "104437",
              "name": "Лен Стокгольм",
              "count": 39,
              "chld": [
                  {
                      "id": "10519",
                      "name": "Стокгольм",
                      "count": 36
                  }
              ]
          },
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 9
          },
          {
              "id": "104448",
              "name": "Лен Вестра-Гёталанд",
              "count": 2,
              "chld": [
                  {
                      "id": "112005",
                      "name": "Бурос",
                      "count": 2
                  }
              ]
          },
          {
              "id": "104449",
              "name": "Лен Вермланд",
              "count": 1
          },
          {
              "id": "104456",
              "name": "Лен Вестерботтен",
              "count": 1,
              "chld": [
                  {
                      "id": "105168",
                      "name": "Умео",
                      "count": 1
                  }
              ]
          },
          {
              "id": "104453",
              "name": "Лен Евлеборг",
              "count": 1,
              "chld": [
                  {
                      "id": "105165",
                      "name": "Евле",
                      "count": 1
                  }
              ]
          },
          {
              "id": "104446",
              "name": "Лен Сконе",
              "count": 1,
              "chld": [
                  {
                      "id": "10521",
                      "name": "Мальмё",
                      "count": 1
                  }
              ]
          }
      ]
  },
  {
      "id": "123",
      "name": "Финляндия",
      "count": 51,
      "chld": [
          {
              "id": "104357",
              "name": "Уусимаа",
              "count": 47,
              "chld": [
                  {
                      "id": "10493",
                      "name": "Хельсинки",
                      "count": 47
                  }
              ]
          },
          {
              "id": "105117",
              "name": "Пирканмаа",
              "count": 3,
              "chld": [
                  {
                      "id": "101514",
                      "name": "Тампере",
                      "count": 3
                  }
              ]
          },
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 1
          }
      ]
  },
  {
      "id": "210",
      "name": "Объединённые Арабские Эмираты",
      "count": 51,
      "chld": [
          {
              "id": "121479",
              "name": "Эмират Дубай",
              "count": 37,
              "chld": [
                  {
                      "id": "11499",
                      "name": "Дубай",
                      "count": 37
                  }
              ]
          },
          {
              "id": "121483",
              "name": "Эмират Шарджа",
              "count": 8,
              "chld": [
                  {
                      "id": "20843",
                      "name": "Шарджа",
                      "count": 8
                  }
              ]
          },
          {
              "id": "121477",
              "name": "Эмират Абу-Даби",
              "count": 6,
              "chld": [
                  {
                      "id": "11498",
                      "name": "Абу-Даби",
                      "count": 6
                  }
              ]
          }
      ]
  },
  {
      "id": "20574",
      "name": "Кипр",
      "count": 48,
      "chld": [
          {
              "id": "105224",
              "name": "Район Лимассол",
              "count": 19,
              "chld": [
                  {
                      "id": "21159",
                      "name": "Лимассол",
                      "count": 19
                  }
              ]
          },
          {
              "id": "105221",
              "name": "Район Никосия",
              "count": 14,
              "chld": [
                  {
                      "id": "20575",
                      "name": "Никосия",
                      "count": 14
                  }
              ]
          },
          {
              "id": "105223",
              "name": "Район Ларнака",
              "count": 9,
              "chld": [
                  {
                      "id": "10421",
                      "name": "Ларнака",
                      "count": 9
                  }
              ]
          },
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 3
          },
          {
              "id": "105225",
              "name": "Район Пафос",
              "count": 3,
              "chld": [
                  {
                      "id": "21242",
                      "name": "Пафос",
                      "count": 3
                  }
              ]
          }
      ]
  },
  {
      "id": "95",
      "name": "Канада",
      "count": 48,
      "chld": [
          {
              "id": "29372",
              "name": "Провинция Онтарио",
              "count": 16,
              "chld": [
                  {
                      "id": "10115",
                      "name": "Торонто",
                      "count": 8
                  },
                  {
                      "id": "103603",
                      "name": "Ошава",
                      "count": 2
                  },
                  {
                      "id": "103569",
                      "name": "Лондон",
                      "count": 1
                  },
                  {
                      "id": "103617",
                      "name": "Оквилл",
                      "count": 1
                  },
                  {
                      "id": "103620",
                      "name": "Ричмонд-Хилл",
                      "count": 1
                  },
                  {
                      "id": "103625",
                      "name": "Скарборо",
                      "count": 1
                  },
                  {
                      "id": "103581",
                      "name": "Торнхилл",
                      "count": 1
                  }
              ]
          },
          {
              "id": "29369",
              "name": "Провинция Альберта",
              "count": 13,
              "chld": [
                  {
                      "id": "21457",
                      "name": "Калгари",
                      "count": 12
                  },
                  {
                      "id": "20806",
                      "name": "Эдмонтон",
                      "count": 1
                  }
              ]
          },
          {
              "id": "29368",
              "name": "Провинция Британская Колумбия",
              "count": 8,
              "chld": [
                  {
                      "id": "21353",
                      "name": "Ванкувер",
                      "count": 6
                  },
                  {
                      "id": "144949",
                      "name": "Парксвилл",
                      "count": 1
                  },
                  {
                      "id": "103591",
                      "name": "Принс-Джордж",
                      "count": 1
                  }
              ]
          },
          {
              "id": "29373",
              "name": "Провинция Квебек",
              "count": 7,
              "chld": [
                  {
                      "id": "21276",
                      "name": "Монреаль",
                      "count": 3
                  },
                  {
                      "id": "111363",
                      "name": "Гатино",
                      "count": 1
                  }
              ]
          },
          {
              "id": "4294967294",
              "name": "Без области",
              "count": 2,
              "chld": [
                  {
                      "id": "10114",
                      "name": "Оттава",
                      "count": 2
                  }
              ]
          },
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 1
          },
          {
              "id": "29371",
              "name": "Провинция Манитоба",
              "count": 1,
              "chld": [
                  {
                      "id": "21380",
                      "name": "Виннипег",
                      "count": 1
                  }
              ]
          }
      ]
  },
  {
      "id": "180",
      "name": "Сербия",
      "count": 38,
      "chld": [
          {
              "id": "104492",
              "name": "Округ Белград",
              "count": 36,
              "chld": [
                  {
                      "id": "10522",
                      "name": "Белград",
                      "count": 36
                  }
              ]
          },
          {
              "id": "105389",
              "name": "Средне-Банатский округ",
              "count": 1,
              "chld": [
                  {
                      "id": "105419",
                      "name": "Зренянин",
                      "count": 1
                  }
              ]
          },
          {
              "id": "105392",
              "name": "Южно-Бачский округ",
              "count": 1,
              "chld": [
                  {
                      "id": "105422",
                      "name": "Нови-Сад",
                      "count": 1
                  }
              ]
          }
      ]
  },
  {
      "id": "122",
      "name": "Словения",
      "count": 35,
      "chld": [
          {
              "id": "4294967294",
              "name": "Без области",
              "count": 20,
              "chld": [
                  {
                      "id": "10492",
                      "name": "Любляна",
                      "count": 20
                  }
              ]
          },
          {
              "id": "105522",
              "name": "Подравский регион",
              "count": 13,
              "chld": [
                  {
                      "id": "105523",
                      "name": "Марибор",
                      "count": 13
                  }
              ]
          },
          {
              "id": "105517",
              "name": "Засавский регион",
              "count": 1,
              "chld": [
                  {
                      "id": "105530",
                      "name": "Трбовле",
                      "count": 1
                  }
              ]
          },
          {
              "id": "105515",
              "name": "Средняя Словения",
              "count": 1,
              "chld": [
                  {
                      "id": "105533",
                      "name": "Домжале",
                      "count": 1
                  }
              ]
          }
      ]
  },
  {
      "id": "93",
      "name": "Аргентина",
      "count": 34,
      "chld": [
          {
              "id": "4294967294",
              "name": "Без области",
              "count": 15,
              "chld": [
                  {
                      "id": "10133",
                      "name": "Буэнос-Айрес",
                      "count": 15
                  }
              ]
          },
          {
              "id": "122082",
              "name": "Провинция Мендоса",
              "count": 6,
              "chld": [
                  {
                      "id": "109419",
                      "name": "Мендоса",
                      "count": 6
                  }
              ]
          },
          {
              "id": "122075",
              "name": "Провинция Кордова",
              "count": 5,
              "chld": [
                  {
                      "id": "20837",
                      "name": "Кордова",
                      "count": 5
                  }
              ]
          },
          {
              "id": "122091",
              "name": "Провинция Санта-Фе",
              "count": 2,
              "chld": [
                  {
                      "id": "109428",
                      "name": "Росарио",
                      "count": 2
                  }
              ]
          },
          {
              "id": "122093",
              "name": "Провинция Тукуман",
              "count": 2,
              "chld": [
                  {
                      "id": "109435",
                      "name": "Тукуман",
                      "count": 2
                  }
              ]
          },
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 1
          },
          {
              "id": "122076",
              "name": "Провинция Корриентес",
              "count": 1,
              "chld": [
                  {
                      "id": "122097",
                      "name": "Корриентес",
                      "count": 1
                  }
              ]
          },
          {
              "id": "122087",
              "name": "Провинция Сальта",
              "count": 1,
              "chld": [
                  {
                      "id": "20772",
                      "name": "Сальта",
                      "count": 1
                  }
              ]
          },
          {
              "id": "122073",
              "name": "Провинция Чако",
              "count": 1,
              "chld": [
                  {
                      "id": "109427",
                      "name": "Ресистенсия",
                      "count": 1
                  }
              ]
          }
      ]
  },
  {
      "id": "125",
      "name": "Чехия",
      "count": 33,
      "chld": [
          {
              "id": "4294967294",
              "name": "Без области",
              "count": 22,
              "chld": [
                  {
                      "id": "10511",
                      "name": "Прага",
                      "count": 22
                  }
              ]
          },
          {
              "id": "104392",
              "name": "Моравскосилезский край",
              "count": 3,
              "chld": [
                  {
                      "id": "105088",
                      "name": "Острава",
                      "count": 3
                  }
              ]
          },
          {
              "id": "104383",
              "name": "Среднечешский край",
              "count": 3,
              "chld": [
                  {
                      "id": "107253",
                      "name": "Нератовице",
                      "count": 2
                  },
                  {
                      "id": "107231",
                      "name": "Млада-Болеслав",
                      "count": 1
                  }
              ]
          },
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 2
          },
          {
              "id": "104386",
              "name": "Карловарский край",
              "count": 1,
              "chld": [
                  {
                      "id": "10510",
                      "name": "Карловы Вары",
                      "count": 1
                  }
              ]
          },
          {
              "id": "104391",
              "name": "Оломоуцкий край",
              "count": 1,
              "chld": [
                  {
                      "id": "105087",
                      "name": "Оломоуц",
                      "count": 1
                  }
              ]
          },
          {
              "id": "104393",
              "name": "Южноморавский край",
              "count": 1
          }
      ]
  },
  {
      "id": "21191",
      "name": "Колумбия",
      "count": 33,
      "chld": [
          {
              "id": "123307",
              "name": "Столичный округ Богота",
              "count": 10,
              "chld": [
                  {
                      "id": "21174",
                      "name": "Богота",
                      "count": 10
                  }
              ]
          },
          {
              "id": "123275",
              "name": "Департамент Антьокия",
              "count": 6,
              "chld": [
                  {
                      "id": "109670",
                      "name": "Медельин",
                      "count": 6
                  }
              ]
          },
          {
              "id": "123300",
              "name": "Департамент Сантандер",
              "count": 5,
              "chld": [
                  {
                      "id": "109665",
                      "name": "Букараманга",
                      "count": 5
                  }
              ]
          },
          {
              "id": "123282",
              "name": "Департамент Кальдас",
              "count": 3,
              "chld": [
                  {
                      "id": "109669",
                      "name": "Манисалес",
                      "count": 3
                  }
              ]
          },
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 3
          },
          {
              "id": "123279",
              "name": "Департамент Атлантико",
              "count": 2,
              "chld": [
                  {
                      "id": "109664",
                      "name": "Барранкилья",
                      "count": 2
                  }
              ]
          },
          {
              "id": "123304",
              "name": "Департамент Валье-дель-Каука",
              "count": 1,
              "chld": [
                  {
                      "id": "109666",
                      "name": "Кали",
                      "count": 1
                  }
              ]
          },
          {
              "id": "123295",
              "name": "Департамент Нариньо",
              "count": 1,
              "chld": [
                  {
                      "id": "111809",
                      "name": "Ипьялес",
                      "count": 1
                  }
              ]
          },
          {
              "id": "123298",
              "name": "Департамент Рисаральда",
              "count": 1,
              "chld": [
                  {
                      "id": "109672",
                      "name": "Перейра",
                      "count": 1
                  }
              ]
          },
          {
              "id": "123303",
              "name": "Департамент Толима",
              "count": 1
          }
      ]
  },
  {
      "id": "117",
      "name": "Литва",
      "count": 32,
      "chld": [
          {
              "id": "104260",
              "name": "Вильнюсский уезд",
              "count": 25,
              "chld": [
                  {
                      "id": "11475",
                      "name": "Вильнюс",
                      "count": 25
                  }
              ]
          },
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 3
          },
          {
              "id": "104261",
              "name": "Каунасский уезд",
              "count": 2,
              "chld": [
                  {
                      "id": "11476",
                      "name": "Каунас",
                      "count": 2
                  }
              ]
          },
          {
              "id": "104262",
              "name": "Клайпедский уезд",
              "count": 2,
              "chld": [
                  {
                      "id": "11477",
                      "name": "Клайпеда",
                      "count": 2
                  }
              ]
          }
      ]
  },
  {
      "id": "10102",
      "name": "Пакистан",
      "count": 31,
      "chld": [
          {
              "id": "144595",
              "name": "Провинция Синд",
              "count": 14,
              "chld": [
                  {
                      "id": "10615",
                      "name": "Карачи",
                      "count": 12
                  },
                  {
                      "id": "114000",
                      "name": "Хайдарабад",
                      "count": 2
                  }
              ]
          },
          {
              "id": "144594",
              "name": "Провинция Пенджаб",
              "count": 14,
              "chld": [
                  {
                      "id": "114013",
                      "name": "Фейсалабад",
                      "count": 6
                  },
                  {
                      "id": "10616",
                      "name": "Лахор",
                      "count": 3
                  },
                  {
                      "id": "114025",
                      "name": "Гуджранвала",
                      "count": 1
                  },
                  {
                      "id": "114004",
                      "name": "Джелам",
                      "count": 1
                  },
                  {
                      "id": "101441",
                      "name": "Равалпинди",
                      "count": 1
                  }
              ]
          },
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 2
          },
          {
              "id": "144596",
              "name": "Федеральная столичная территория",
              "count": 1,
              "chld": [
                  {
                      "id": "10614",
                      "name": "Исламабад",
                      "count": 1
                  }
              ]
          }
      ]
  },
  {
      "id": "10077",
      "name": "Румыния",
      "count": 29,
      "chld": [
          {
              "id": "4294967294",
              "name": "Без области",
              "count": 8,
              "chld": [
                  {
                      "id": "10487",
                      "name": "Бухарест",
                      "count": 8
                  }
              ]
          },
          {
              "id": "105329",
              "name": "Мехединци",
              "count": 4,
              "chld": [
                  {
                      "id": "105375",
                      "name": "Дробета-Турну-Северин",
                      "count": 4
                  }
              ]
          },
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 4
          },
          {
              "id": "105326",
              "name": "Жудец Констанца",
              "count": 3,
              "chld": [
                  {
                      "id": "20832",
                      "name": "Констанца",
                      "count": 3
                  }
              ]
          },
          {
              "id": "105344",
              "name": "Жудец Яссы",
              "count": 2,
              "chld": [
                  {
                      "id": "15090",
                      "name": "Яссы",
                      "count": 2
                  }
              ]
          },
          {
              "id": "105322",
              "name": "Илфов",
              "count": 2,
              "chld": [
                  {
                      "id": "145332",
                      "name": "Отопени",
                      "count": 1
                  }
              ]
          },
          {
              "id": "105309",
              "name": "Бихор",
              "count": 1,
              "chld": [
                  {
                      "id": "105356",
                      "name": "Орадя",
                      "count": 1
                  }
              ]
          },
          {
              "id": "105318",
              "name": "Горж",
              "count": 1,
              "chld": [
                  {
                      "id": "105372",
                      "name": "Тыргу Жиу",
                      "count": 1
                  }
              ]
          },
          {
              "id": "105317",
              "name": "Жудец Галац",
              "count": 1
          },
          {
              "id": "105324",
              "name": "Клуж",
              "count": 1,
              "chld": [
                  {
                      "id": "105357",
                      "name": "Клуж-Напока",
                      "count": 1
                  }
              ]
          },
          {
              "id": "105333",
              "name": "Прахова",
              "count": 1,
              "chld": [
                  {
                      "id": "105382",
                      "name": "Плоешти",
                      "count": 1
                  }
              ]
          },
          {
              "id": "105339",
              "name": "Тимиш",
              "count": 1
          }
      ]
  },
  {
      "id": "20741",
      "name": "Нигерия",
      "count": 27,
      "chld": [
          {
              "id": "4294967294",
              "name": "Без области",
              "count": 22,
              "chld": [
                  {
                      "id": "20742",
                      "name": "Абуджа",
                      "count": 11
                  },
                  {
                      "id": "21063",
                      "name": "Лагос",
                      "count": 6
                  },
                  {
                      "id": "113891",
                      "name": "Кано",
                      "count": 2
                  },
                  {
                      "id": "113883",
                      "name": "Порт-Харкорт",
                      "count": 2
                  },
                  {
                      "id": "113908",
                      "name": "Абакалики",
                      "count": 1
                  }
              ]
          },
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 5
          }
      ]
  },
  {
      "id": "207",
      "name": "Киргизия",
      "count": 24,
      "chld": [
          {
              "id": "4294967294",
              "name": "Без области",
              "count": 23,
              "chld": [
                  {
                      "id": "10309",
                      "name": "Бишкек",
                      "count": 23
                  }
              ]
          },
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 1
          }
      ]
  },
  {
      "id": "115",
      "name": "Болгария",
      "count": 24,
      "chld": [
          {
              "id": "104156",
              "name": "Городская область София",
              "count": 16,
              "chld": [
                  {
                      "id": "10379",
                      "name": "София",
                      "count": 16
                  }
              ]
          },
          {
              "id": "104160",
              "name": "Варненская область",
              "count": 4,
              "chld": [
                  {
                      "id": "10381",
                      "name": "Варна",
                      "count": 4
                  }
              ]
          },
          {
              "id": "104138",
              "name": "Бургасская область",
              "count": 2,
              "chld": [
                  {
                      "id": "10380",
                      "name": "Бургас",
                      "count": 2
                  }
              ]
          },
          {
              "id": "104149",
              "name": "Пловдивская область",
              "count": 1,
              "chld": [
                  {
                      "id": "10385",
                      "name": "Пловдив",
                      "count": 1
                  }
              ]
          },
          {
              "id": "104141",
              "name": "Хасковская область",
              "count": 1,
              "chld": [
                  {
                      "id": "10383",
                      "name": "Димитровград",
                      "count": 1
                  }
              ]
          }
      ]
  },
  {
      "id": "20785",
      "name": "Эквадор",
      "count": 23,
      "chld": [
          {
              "id": "123378",
              "name": "Провинция Пичинча",
              "count": 16,
              "chld": [
                  {
                      "id": "20786",
                      "name": "Кито",
                      "count": 16
                  }
              ]
          },
          {
              "id": "123369",
              "name": "Провинция Гуаяс",
              "count": 4,
              "chld": [
                  {
                      "id": "21432",
                      "name": "Гуаякиль",
                      "count": 4
                  }
              ]
          },
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 1
          },
          {
              "id": "123352",
              "name": "Провинция Асуай",
              "count": 1,
              "chld": [
                  {
                      "id": "21049",
                      "name": "Куэнка",
                      "count": 1
                  }
              ]
          },
          {
              "id": "123362",
              "name": "Провинция Чимборасо",
              "count": 1,
              "chld": [
                  {
                      "id": "111890",
                      "name": "Риобамба",
                      "count": 1
                  }
              ]
          }
      ]
  },
  {
      "id": "10105",
      "name": "Сингапур",
      "count": 23,
      "chld": [
          {
              "id": "4294967294",
              "name": "Без области",
              "count": 13,
              "chld": [
                  {
                      "id": "10619",
                      "name": "Сингапур",
                      "count": 13
                  }
              ]
          },
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 10
          }
      ]
  },
  {
      "id": "126",
      "name": "Швейцария",
      "count": 22,
      "chld": [
          {
              "id": "104396",
              "name": "Кантон Цюрих",
              "count": 15,
              "chld": [
                  {
                      "id": "10515",
                      "name": "Цюрих",
                      "count": 12
                  }
              ]
          },
          {
              "id": "104417",
              "name": "Кантон Во",
              "count": 3
          },
          {
              "id": "104407",
              "name": "Кантон Базель-Штадт",
              "count": 1,
              "chld": [
                  {
                      "id": "102527",
                      "name": "Базель",
                      "count": 1
                  }
              ]
          },
          {
              "id": "104413",
              "name": "Кантон Граубюнден",
              "count": 1
          },
          {
              "id": "104420",
              "name": "Кантон Женева",
              "count": 1,
              "chld": [
                  {
                      "id": "10514",
                      "name": "Женева",
                      "count": 1
                  }
              ]
          },
          {
              "id": "104416",
              "name": "Кантон Тичино",
              "count": 1,
              "chld": [
                  {
                      "id": "109082",
                      "name": "Лугано",
                      "count": 1
                  }
              ]
          }
      ]
  },
  {
      "id": "246",
      "name": "Греция",
      "count": 22,
      "chld": [
          {
              "id": "104165",
              "name": "Аттика",
              "count": 11,
              "chld": [
                  {
                      "id": "10418",
                      "name": "Афины",
                      "count": 9
                  },
                  {
                      "id": "116382",
                      "name": "Пирей",
                      "count": 1
                  }
              ]
          },
          {
              "id": "104174",
              "name": "Южные Эгейские острова",
              "count": 5,
              "chld": [
                  {
                      "id": "10423",
                      "name": "Родос",
                      "count": 4
                  },
                  {
                      "id": "109203",
                      "name": "Миконос",
                      "count": 1
                  }
              ]
          },
          {
              "id": "104167",
              "name": "Центральная Македония",
              "count": 2,
              "chld": [
                  {
                      "id": "10424",
                      "name": "Салоники",
                      "count": 2
                  }
              ]
          },
          {
              "id": "104168",
              "name": "Крит",
              "count": 2,
              "chld": [
                  {
                      "id": "10419",
                      "name": "Ираклион",
                      "count": 1
                  },
                  {
                      "id": "16745",
                      "name": "Ханья",
                      "count": 1
                  }
              ]
          },
          {
              "id": "104171",
              "name": "Ионические острова",
              "count": 1
          },
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 1
          }
      ]
  },
  {
      "id": "10097",
      "name": "Малайзия",
      "count": 20,
      "chld": [
          {
              "id": "4294967294",
              "name": "Без области",
              "count": 15,
              "chld": [
                  {
                      "id": "10604",
                      "name": "Куала-Лумпур",
                      "count": 14
                  },
                  {
                      "id": "121377",
                      "name": "Путраджая",
                      "count": 1
                  }
              ]
          },
          {
              "id": "109025",
              "name": "Штат Сабах",
              "count": 2,
              "chld": [
                  {
                      "id": "96471",
                      "name": "Кота-Кинабалу",
                      "count": 2
                  }
              ]
          },
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 1
          },
          {
              "id": "21338",
              "name": "Штат Пинанг",
              "count": 1
          },
          {
              "id": "121374",
              "name": "Штат Селангор",
              "count": 1,
              "chld": [
                  {
                      "id": "123497",
                      "name": "Петалинг Джайя",
                      "count": 1
                  }
              ]
          }
      ]
  },
  {
      "id": "10091",
      "name": "Бангладеш",
      "count": 20,
      "chld": [
          {
              "id": "144720",
              "name": "Область Дакка",
              "count": 12,
              "chld": [
                  {
                      "id": "10530",
                      "name": "Дакка",
                      "count": 12
                  }
              ]
          },
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 5
          },
          {
              "id": "144725",
              "name": "Область Читтагонг",
              "count": 2,
              "chld": [
                  {
                      "id": "20801",
                      "name": "Кокс-Базар",
                      "count": 1
                  },
                  {
                      "id": "10531",
                      "name": "Читтагонг",
                      "count": 1
                  }
              ]
          },
          {
              "id": "144719",
              "name": "Область Барисал",
              "count": 1
          }
      ]
  },
  {
      "id": "21610",
      "name": "Черногория",
      "count": 20,
      "chld": [
          {
              "id": "105444",
              "name": "Община Будва",
              "count": 9,
              "chld": [
                  {
                      "id": "13458",
                      "name": "Будва",
                      "count": 9
                  }
              ]
          },
          {
              "id": "105454",
              "name": "Община Подгорица",
              "count": 6,
              "chld": [
                  {
                      "id": "21611",
                      "name": "Подгорица",
                      "count": 6
                  }
              ]
          },
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 3
          },
          {
              "id": "105441",
              "name": "Община Бар",
              "count": 1,
              "chld": [
                  {
                      "id": "13461",
                      "name": "Бар",
                      "count": 1
                  }
              ]
          },
          {
              "id": "105458",
              "name": "Община Херцег-Нови",
              "count": 1,
              "chld": [
                  {
                      "id": "116263",
                      "name": "Дженовичи",
                      "count": 1
                  }
              ]
          }
      ]
  },
  {
      "id": "20572",
      "name": "Ирак",
      "count": 20,
      "chld": [
          {
              "id": "144754",
              "name": "Мухафаза Багдад",
              "count": 4,
              "chld": [
                  {
                      "id": "20573",
                      "name": "Багдад",
                      "count": 4
                  }
              ]
          },
          {
              "id": "144756",
              "name": "Мухафаза Дияла",
              "count": 4
          },
          {
              "id": "144769",
              "name": "Мухафаза Эрбиль",
              "count": 4,
              "chld": [
                  {
                      "id": "112762",
                      "name": "Эрбиль",
                      "count": 4
                  }
              ]
          },
          {
              "id": "144764",
              "name": "Мухафаза Кербела",
              "count": 2,
              "chld": [
                  {
                      "id": "112761",
                      "name": "Кербела",
                      "count": 2
                  }
              ]
          },
          {
              "id": "144766",
              "name": "Мухафаза Анбар",
              "count": 1,
              "chld": [
                  {
                      "id": "112775",
                      "name": "Эль-Фаллуджа",
                      "count": 1
                  }
              ]
          },
          {
              "id": "144757",
              "name": "Мухафаза Васит",
              "count": 1
          },
          {
              "id": "144770",
              "name": "Мухафаза Киркук",
              "count": 1,
              "chld": [
                  {
                      "id": "112769",
                      "name": "Киркук",
                      "count": 1
                  }
              ]
          },
          {
              "id": "144758",
              "name": "Мухафаза Майсан",
              "count": 1
          },
          {
              "id": "144767",
              "name": "Мухафаза Нинава",
              "count": 1,
              "chld": [
                  {
                      "id": "40608",
                      "name": "Мосул",
                      "count": 1
                  }
              ]
          },
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 1
          }
      ]
  },
  {
      "id": "21175",
      "name": "Гондурас",
      "count": 19,
      "chld": [
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 12
          },
          {
              "id": "4294967294",
              "name": "Без области",
              "count": 7,
              "chld": [
                  {
                      "id": "109603",
                      "name": "Сан-Педро-Сула",
                      "count": 4
                  },
                  {
                      "id": "21181",
                      "name": "Тегусигальпа",
                      "count": 3
                  }
              ]
          }
      ]
  },
  {
      "id": "135",
      "name": "Южная Корея",
      "count": 19,
      "chld": [
          {
              "id": "4294967294",
              "name": "Без области",
              "count": 9,
              "chld": [
                  {
                      "id": "10635",
                      "name": "Сеул",
                      "count": 9
                  }
              ]
          },
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 9
          },
          {
              "id": "114960",
              "name": "Кёнсан-Намдо",
              "count": 1,
              "chld": [
                  {
                      "id": "114923",
                      "name": "Чханвон",
                      "count": 1
                  }
              ]
          }
      ]
  },
  {
      "id": "20862",
      "name": "Чили",
      "count": 18,
      "chld": [
          {
              "id": "123323",
              "name": "Столичная область",
              "count": 17,
              "chld": [
                  {
                      "id": "20963",
                      "name": "Сантьяго",
                      "count": 17
                  }
              ]
          },
          {
              "id": "123318",
              "name": "Область Био-Био",
              "count": 1,
              "chld": [
                  {
                      "id": "111430",
                      "name": "Консепсьон",
                      "count": 1
                  }
              ]
          }
      ]
  },
  {
      "id": "21156",
      "name": "Перу",
      "count": 18,
      "chld": [
          {
              "id": "4294967294",
              "name": "Без области",
              "count": 11,
              "chld": [
                  {
                      "id": "21157",
                      "name": "Лима",
                      "count": 11
                  }
              ]
          },
          {
              "id": "105750",
              "name": "Регион Ла-Либертад",
              "count": 3,
              "chld": [
                  {
                      "id": "84501",
                      "name": "Трухильо",
                      "count": 3
                  }
              ]
          },
          {
              "id": "105741",
              "name": "Регион Арекипа",
              "count": 1,
              "chld": [
                  {
                      "id": "84752",
                      "name": "Арекипа",
                      "count": 1
                  }
              ]
          },
          {
              "id": "105748",
              "name": "Регион Ика",
              "count": 1
          },
          {
              "id": "105752",
              "name": "Регион Лима",
              "count": 1
          },
          {
              "id": "105761",
              "name": "Регион Тумбес",
              "count": 1,
              "chld": [
                  {
                      "id": "84370",
                      "name": "Тумбес",
                      "count": 1
                  }
              ]
          }
      ]
  },
  {
      "id": "21184",
      "name": "Венесуэла",
      "count": 18,
      "chld": [
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 8
          },
          {
              "id": "123168",
              "name": "Штат Лара",
              "count": 2,
              "chld": [
                  {
                      "id": "114490",
                      "name": "Баркисимето",
                      "count": 2
                  }
              ]
          },
          {
              "id": "123176",
              "name": "Штат Трухильо",
              "count": 2,
              "chld": [
                  {
                      "id": "114494",
                      "name": "Валера",
                      "count": 2
                  }
              ]
          },
          {
              "id": "123181",
              "name": "Федеральный округ Венесуэлы",
              "count": 1
          },
          {
              "id": "123162",
              "name": "Штат Боливар",
              "count": 1,
              "chld": [
                  {
                      "id": "114487",
                      "name": "Пуэрто-Ордас",
                      "count": 1
                  }
              ]
          },
          {
              "id": "123163",
              "name": "Штат Карабобо",
              "count": 1,
              "chld": [
                  {
                      "id": "114784",
                      "name": "Валенсия",
                      "count": 1
                  }
              ]
          },
          {
              "id": "123170",
              "name": "Штат Миранда",
              "count": 1,
              "chld": [
                  {
                      "id": "114785",
                      "name": "Лос Текес",
                      "count": 1
                  }
              ]
          },
          {
              "id": "123179",
              "name": "Штат Сулия",
              "count": 1,
              "chld": [
                  {
                      "id": "109584",
                      "name": "Маракайбо",
                      "count": 1
                  }
              ]
          },
          {
              "id": "123166",
              "name": "Штат Фалькон",
              "count": 1,
              "chld": [
                  {
                      "id": "114499",
                      "name": "Коро",
                      "count": 1
                  }
              ]
          }
      ]
  },
  {
      "id": "10021",
      "name": "ЮАР",
      "count": 18,
      "chld": [
          {
              "id": "4294967294",
              "name": "Без области",
              "count": 14,
              "chld": [
                  {
                      "id": "10140",
                      "name": "Претория",
                      "count": 6
                  },
                  {
                      "id": "21438",
                      "name": "Йоханнесбург",
                      "count": 4
                  },
                  {
                      "id": "21379",
                      "name": "Кейптаун",
                      "count": 3
                  },
                  {
                      "id": "123491",
                      "name": "Херманус",
                      "count": 1
                  }
              ]
          },
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 4
          }
      ]
  },
  {
      "id": "10063",
      "name": "Ирландия",
      "count": 17,
      "chld": [
          {
              "id": "105237",
              "name": "Графство Дублин",
              "count": 13,
              "chld": [
                  {
                      "id": "10426",
                      "name": "Дублин",
                      "count": 13
                  }
              ]
          },
          {
              "id": "105251",
              "name": "Графство Лимерик",
              "count": 2,
              "chld": [
                  {
                      "id": "21615",
                      "name": "Лимерик",
                      "count": 2
                  }
              ]
          },
          {
              "id": "105243",
              "name": "Графство Мит",
              "count": 1
          },
          {
              "id": "105247",
              "name": "Графство Уиклоу",
              "count": 1
          }
      ]
  },
  {
      "id": "113",
      "name": "Австрия",
      "count": 17,
      "chld": [
          {
              "id": "104136",
              "name": "Земля Вена",
              "count": 11,
              "chld": [
                  {
                      "id": "10371",
                      "name": "Вена",
                      "count": 11
                  }
              ]
          },
          {
              "id": "104133",
              "name": "Штирия",
              "count": 4,
              "chld": [
                  {
                      "id": "105220",
                      "name": "Грац",
                      "count": 4
                  }
              ]
          },
          {
              "id": "104130",
              "name": "Нижняя Австрия",
              "count": 1
          },
          {
              "id": "104135",
              "name": "Форарльберг",
              "count": 1
          }
      ]
  },
  {
      "id": "137",
      "name": "Япония",
      "count": 16,
      "chld": [
          {
              "id": "121393",
              "name": "Префектура Токио",
              "count": 9,
              "chld": [
                  {
                      "id": "10636",
                      "name": "Токио",
                      "count": 9
                  }
              ]
          },
          {
              "id": "121407",
              "name": "Префектура Осака",
              "count": 3,
              "chld": [
                  {
                      "id": "10641",
                      "name": "Осака",
                      "count": 3
                  }
              ]
          },
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 1
          },
          {
              "id": "121421",
              "name": "Префектура Сага",
              "count": 1,
              "chld": [
                  {
                      "id": "112887",
                      "name": "Сага",
                      "count": 1
                  }
              ]
          },
          {
              "id": "121392",
              "name": "Префектура Тиба",
              "count": 1,
              "chld": [
                  {
                      "id": "111977",
                      "name": "Тиба",
                      "count": 1
                  }
              ]
          },
          {
              "id": "121381",
              "name": "Префектура Ямагата",
              "count": 1,
              "chld": [
                  {
                      "id": "114607",
                      "name": "Ямагата",
                      "count": 1
                  }
              ]
          }
      ]
  },
  {
      "id": "29385",
      "name": "Тайвань",
      "count": 16,
      "chld": [
          {
              "id": "4294967294",
              "name": "Без области",
              "count": 9,
              "chld": [
                  {
                      "id": "10592",
                      "name": "Тайбэй",
                      "count": 7
                  },
                  {
                      "id": "112006",
                      "name": "Тайчжун",
                      "count": 2
                  }
              ]
          },
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 7
          }
      ]
  },
  {
      "id": "21551",
      "name": "Йемен",
      "count": 15,
      "chld": [
          {
              "id": "144781",
              "name": "Столичная Мухафаза",
              "count": 15,
              "chld": [
                  {
                      "id": "21569",
                      "name": "Сана",
                      "count": 15
                  }
              ]
          }
      ]
  },
  {
      "id": "20975",
      "name": "Камбоджа",
      "count": 15,
      "chld": [
          {
              "id": "4294967294",
              "name": "Без области",
              "count": 14,
              "chld": [
                  {
                      "id": "21316",
                      "name": "Пномпень",
                      "count": 14
                  }
              ]
          },
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 1
          }
      ]
  },
  {
      "id": "211",
      "name": "Австралия",
      "count": 15,
      "chld": [
          {
              "id": "21763",
              "name": "Виктория",
              "count": 11,
              "chld": [
                  {
                      "id": "21265",
                      "name": "Мельбурн",
                      "count": 11
                  }
              ]
          },
          {
              "id": "21769",
              "name": "Западная Австралия",
              "count": 2,
              "chld": [
                  {
                      "id": "21770",
                      "name": "Перт",
                      "count": 2
                  }
              ]
          },
          {
              "id": "21761",
              "name": "Новый Южный Уэльс",
              "count": 2,
              "chld": [
                  {
                      "id": "10145",
                      "name": "Сидней",
                      "count": 2
                  }
              ]
          }
      ]
  },
  {
      "id": "10100",
      "name": "Мьянма",
      "count": 14,
      "chld": [
          {
              "id": "4294967294",
              "name": "Без области",
              "count": 14,
              "chld": [
                  {
                      "id": "10612",
                      "name": "Янгон",
                      "count": 14
                  }
              ]
          }
      ]
  },
  {
      "id": "20826",
      "name": "Алжир",
      "count": 13,
      "chld": [
          {
              "id": "4294967294",
              "name": "Без области",
              "count": 10,
              "chld": [
                  {
                      "id": "20828",
                      "name": "Алжир",
                      "count": 7
                  },
                  {
                      "id": "110952",
                      "name": "Скикда",
                      "count": 2
                  },
                  {
                      "id": "101601",
                      "name": "Аннаба",
                      "count": 1
                  }
              ]
          },
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 3
          }
      ]
  },
  {
      "id": "114",
      "name": "Бельгия",
      "count": 12,
      "chld": [
          {
              "id": "4294967294",
              "name": "Без области",
              "count": 8,
              "chld": [
                  {
                      "id": "10376",
                      "name": "Брюссель",
                      "count": 8
                  }
              ]
          },
          {
              "id": "105782",
              "name": "Валлонский Брабант",
              "count": 1
          },
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 1
          },
          {
              "id": "105779",
              "name": "Провинция Льеж",
              "count": 1
          },
          {
              "id": "105777",
              "name": "Фламандский Брабант",
              "count": 1
          }
      ]
  },
  {
      "id": "119",
      "name": "Норвегия",
      "count": 12,
      "chld": [
          {
              "id": "104322",
              "name": "Фюльке Осло",
              "count": 5,
              "chld": [
                  {
                      "id": "10467",
                      "name": "Осло",
                      "count": 5
                  }
              ]
          },
          {
              "id": "104321",
              "name": "Ругаланд",
              "count": 3,
              "chld": [
                  {
                      "id": "100493",
                      "name": "Ставангер",
                      "count": 2
                  },
                  {
                      "id": "145536",
                      "name": "Саннес",
                      "count": 1
                  }
              ]
          },
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 2
          },
          {
              "id": "104326",
              "name": "Вестфолл",
              "count": 1
          },
          {
              "id": "104320",
              "name": "Хордаланн",
              "count": 1,
              "chld": [
                  {
                      "id": "21079",
                      "name": "Берген",
                      "count": 1
                  }
              ]
          }
      ]
  },
  {
      "id": "116",
      "name": "Венгрия",
      "count": 11,
      "chld": [
          {
              "id": "104483",
              "name": "Пешт",
              "count": 10,
              "chld": [
                  {
                      "id": "10398",
                      "name": "Будапешт",
                      "count": 8
                  }
              ]
          },
          {
              "id": "104472",
              "name": "Баранья",
              "count": 1
          }
      ]
  },
  {
      "id": "10101",
      "name": "Непал",
      "count": 9,
      "chld": [
          {
              "id": "144621",
              "name": "Багмати",
              "count": 5,
              "chld": [
                  {
                      "id": "10613",
                      "name": "Катманду",
                      "count": 5
                  }
              ]
          },
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 2
          },
          {
              "id": "144614",
              "name": "Сетхи",
              "count": 2,
              "chld": [
                  {
                      "id": "113841",
                      "name": "Дхангадхи",
                      "count": 2
                  }
              ]
          }
      ]
  },
  {
      "id": "10109",
      "name": "Шри-Ланка",
      "count": 9,
      "chld": [
          {
              "id": "144608",
              "name": "Западная провинция",
              "count": 6,
              "chld": [
                  {
                      "id": "10633",
                      "name": "Коломбо",
                      "count": 4
                  },
                  {
                      "id": "116224",
                      "name": "Дехивала-Маунт-Лавиния",
                      "count": 1
                  }
              ]
          },
          {
              "id": "144600",
              "name": "Центральная провинция",
              "count": 2,
              "chld": [
                  {
                      "id": "43444",
                      "name": "Канди",
                      "count": 2
                  }
              ]
          },
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 1
          }
      ]
  },
  {
      "id": "20917",
      "name": "Доминиканская Республика",
      "count": 8,
      "chld": [
          {
              "id": "115534",
              "name": "Национальный округ",
              "count": 4,
              "chld": [
                  {
                      "id": "20960",
                      "name": "Санто-Доминго",
                      "count": 4
                  }
              ]
          },
          {
              "id": "115565",
              "name": "Провинция Санто-Доминго",
              "count": 2,
              "chld": [
                  {
                      "id": "115588",
                      "name": "Санто-Доминго-Есте",
                      "count": 2
                  }
              ]
          },
          {
              "id": "115533",
              "name": "Провинция Асуа",
              "count": 2,
              "chld": [
                  {
                      "id": "115567",
                      "name": "Асуа-де-Компостела",
                      "count": 1
                  }
              ]
          }
      ]
  },
  {
      "id": "139",
      "name": "Новая Зеландия",
      "count": 8,
      "chld": [
          {
              "id": "4294967294",
              "name": "Без области",
              "count": 7,
              "chld": [
                  {
                      "id": "20873",
                      "name": "Крайстчерч",
                      "count": 3
                  },
                  {
                      "id": "21368",
                      "name": "Окленд",
                      "count": 2
                  },
                  {
                      "id": "10147",
                      "name": "Веллингтон",
                      "count": 1
                  },
                  {
                      "id": "113861",
                      "name": "Роторуа",
                      "count": 1
                  }
              ]
          },
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 1
          }
      ]
  },
  {
      "id": "10535",
      "name": "Иордания",
      "count": 7,
      "chld": [
          {
              "id": "4294967294",
              "name": "Без области",
              "count": 7,
              "chld": [
                  {
                      "id": "11494",
                      "name": "Амман",
                      "count": 7
                  }
              ]
          }
      ]
  },
  {
      "id": "21231",
      "name": "Никарагуа",
      "count": 7,
      "chld": [
          {
              "id": "4294967294",
              "name": "Без области",
              "count": 4,
              "chld": [
                  {
                      "id": "21232",
                      "name": "Манагуа",
                      "count": 4
                  }
              ]
          },
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 3
          }
      ]
  },
  {
      "id": "10024",
      "name": "Тунис",
      "count": 7,
      "chld": [
          {
              "id": "4294967294",
              "name": "Без области",
              "count": 7,
              "chld": [
                  {
                      "id": "10143",
                      "name": "Тунис",
                      "count": 4
                  },
                  {
                      "id": "114431",
                      "name": "Габес",
                      "count": 1
                  },
                  {
                      "id": "60739",
                      "name": "Сус",
                      "count": 1
                  },
                  {
                      "id": "60750",
                      "name": "Сфакс",
                      "count": 1
                  }
              ]
          }
      ]
  },
  {
      "id": "10015",
      "name": "Боливия",
      "count": 6,
      "chld": [
          {
              "id": "123431",
              "name": "Департамент Санта-Крус",
              "count": 3
          },
          {
              "id": "123425",
              "name": "Департамент Кочабамба",
              "count": 2,
              "chld": [
                  {
                      "id": "109442",
                      "name": "Кочабамба",
                      "count": 2
                  }
              ]
          },
          {
              "id": "123426",
              "name": "Департамент Ла-Пас",
              "count": 1,
              "chld": [
                  {
                      "id": "10134",
                      "name": "Ла-Пас",
                      "count": 1
                  }
              ]
          }
      ]
  },
  {
      "id": "121",
      "name": "Словакия",
      "count": 6,
      "chld": [
          {
              "id": "104510",
              "name": "Братиславский край",
              "count": 2,
              "chld": [
                  {
                      "id": "10489",
                      "name": "Братислава",
                      "count": 2
                  }
              ]
          },
          {
              "id": "104512",
              "name": "Кошицкий край",
              "count": 2,
              "chld": [
                  {
                      "id": "10490",
                      "name": "Кошице",
                      "count": 2
                  }
              ]
          },
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 1
          },
          {
              "id": "104516",
              "name": "Трнавский край",
              "count": 1
          }
      ]
  },
  {
      "id": "20762",
      "name": "Демократическая Республика Конго",
      "count": 5,
      "chld": [
          {
              "id": "4294967294",
              "name": "Без области",
              "count": 5,
              "chld": [
                  {
                      "id": "20763",
                      "name": "Киншаса",
                      "count": 5
                  }
              ]
          }
      ]
  },
  {
      "id": "10538",
      "name": "Ливан",
      "count": 5,
      "chld": [
          {
              "id": "144745",
              "name": "Мухафаза Бейрут",
              "count": 4,
              "chld": [
                  {
                      "id": "11497",
                      "name": "Бейрут",
                      "count": 4
                  }
              ]
          },
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 1
          }
      ]
  },
  {
      "id": "20968",
      "name": "Гватемала",
      "count": 4,
      "chld": [
          {
              "id": "115612",
              "name": "Департамент Гватемала",
              "count": 4,
              "chld": [
                  {
                      "id": "21415",
                      "name": "Гватемала",
                      "count": 4
                  }
              ]
          }
      ]
  },
  {
      "id": "10069",
      "name": "Мальта",
      "count": 4,
      "chld": [
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 4
          }
      ]
  },
  {
      "id": "20769",
      "name": "Сальвадор",
      "count": 4,
      "chld": [
          {
              "id": "4294967294",
              "name": "Без области",
              "count": 4,
              "chld": [
                  {
                      "id": "20919",
                      "name": "Сан-Сальвадор",
                      "count": 4
                  }
              ]
          }
      ]
  },
  {
      "id": "10083",
      "name": "Хорватия",
      "count": 4,
      "chld": [
          {
              "id": "105471",
              "name": "Загребская жупания",
              "count": 1,
              "chld": [
                  {
                      "id": "10507",
                      "name": "Загреб",
                      "count": 1
                  }
              ]
          },
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 1
          },
          {
              "id": "105485",
              "name": "Осиецко-Бараньская жупания",
              "count": 1,
              "chld": [
                  {
                      "id": "105506",
                      "name": "Осиек",
                      "count": 1
                  }
              ]
          },
          {
              "id": "105489",
              "name": "Сплитско-Далматинская жупания",
              "count": 1,
              "chld": [
                  {
                      "id": "10508",
                      "name": "Сплит",
                      "count": 1
                  }
              ]
          }
      ]
  },
  {
      "id": "209",
      "name": "Таджикистан",
      "count": 3,
      "chld": [
          {
              "id": "4294967294",
              "name": "Без области",
              "count": 3,
              "chld": [
                  {
                      "id": "10318",
                      "name": "Душанбе",
                      "count": 3
                  }
              ]
          }
      ]
  },
  {
      "id": "21230",
      "name": "Уганда",
      "count": 3,
      "chld": [
          {
              "id": "4294967294",
              "name": "Без области",
              "count": 3,
              "chld": [
                  {
                      "id": "21424",
                      "name": "Кампала",
                      "count": 3
                  }
              ]
          }
      ]
  },
  {
      "id": "21203",
      "name": "Люксембург",
      "count": 3,
      "chld": [
          {
              "id": "105439",
              "name": "Округ Люксембург",
              "count": 2,
              "chld": [
                  {
                      "id": "21204",
                      "name": "Люксембург",
                      "count": 2
                  }
              ]
          },
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 1
          }
      ]
  },
  {
      "id": "10068",
      "name": "Северная Македония",
      "count": 3,
      "chld": [
          {
              "id": "4294967294",
              "name": "Без области",
              "count": 2,
              "chld": [
                  {
                      "id": "10463",
                      "name": "Скопье",
                      "count": 2
                  }
              ]
          },
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 1
          }
      ]
  },
  {
      "id": "21223",
      "name": "Кения",
      "count": 3,
      "chld": [
          {
              "id": "4294967294",
              "name": "Без области",
              "count": 2,
              "chld": [
                  {
                      "id": "21312",
                      "name": "Найроби",
                      "count": 1
                  },
                  {
                      "id": "112933",
                      "name": "Нанюки",
                      "count": 1
                  }
              ]
          },
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 1
          }
      ]
  },
  {
      "id": "21182",
      "name": "Ангола",
      "count": 2,
      "chld": [
          {
              "id": "4294967294",
              "name": "Без области",
              "count": 2,
              "chld": [
                  {
                      "id": "21183",
                      "name": "Луанда",
                      "count": 2
                  }
              ]
          }
      ]
  },
  {
      "id": "20818",
      "name": "Гвинея",
      "count": 2,
      "chld": [
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 2
          }
      ]
  },
  {
      "id": "20954",
      "name": "Зимбабве",
      "count": 2,
      "chld": [
          {
              "id": "4294967294",
              "name": "Без области",
              "count": 2,
              "chld": [
                  {
                      "id": "20955",
                      "name": "Хараре",
                      "count": 2
                  }
              ]
          }
      ]
  },
  {
      "id": "10064",
      "name": "Исландия",
      "count": 2,
      "chld": [
          {
              "id": "105575",
              "name": "Хёвюдборгарсвайдид",
              "count": 2,
              "chld": [
                  {
                      "id": "10428",
                      "name": "Рейкьявик",
                      "count": 2
                  }
              ]
          }
      ]
  },
  {
      "id": "21486",
      "name": "Катар",
      "count": 2,
      "chld": [
          {
              "id": "219591",
              "name": "Эд-Доха",
              "count": 2,
              "chld": [
                  {
                      "id": "21487",
                      "name": "Доха",
                      "count": 2
                  }
              ]
          }
      ]
  },
  {
      "id": "10017",
      "name": "Куба",
      "count": 2,
      "chld": [
          {
              "id": "4294967294",
              "name": "Без области",
              "count": 2,
              "chld": [
                  {
                      "id": "10137",
                      "name": "Гавана",
                      "count": 2
                  }
              ]
          }
      ]
  },
  {
      "id": "20768",
      "name": "Эфиопия",
      "count": 2,
      "chld": [
          {
              "id": "4294967294",
              "name": "Без области",
              "count": 2,
              "chld": [
                  {
                      "id": "20771",
                      "name": "Аддис-Абеба",
                      "count": 2
                  }
              ]
          }
      ]
  },
  {
      "id": "10054",
      "name": "Албания",
      "count": 2,
      "chld": [
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 1
          },
          {
              "id": "105602",
              "name": "Область Тирана",
              "count": 1,
              "chld": [
                  {
                      "id": "10374",
                      "name": "Тирана",
                      "count": 1
                  }
              ]
          }
      ]
  },
  {
      "id": "10090",
      "name": "Афганистан",
      "count": 2,
      "chld": [
          {
              "id": "4294967294",
              "name": "Без области",
              "count": 1,
              "chld": [
                  {
                      "id": "110948",
                      "name": "Герат",
                      "count": 1
                  }
              ]
          },
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 1
          }
      ]
  },
  {
      "id": "20733",
      "name": "Кот-д’Ивуар",
      "count": 2,
      "chld": [
          {
              "id": "4294967294",
              "name": "Без области",
              "count": 1,
              "chld": [
                  {
                      "id": "20734",
                      "name": "Абиджан",
                      "count": 1
                  }
              ]
          },
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 1
          }
      ]
  },
  {
      "id": "10023",
      "name": "Ливия",
      "count": 2,
      "chld": [
          {
              "id": "4294967294",
              "name": "Без области",
              "count": 2,
              "chld": [
                  {
                      "id": "20988",
                      "name": "Сабха",
                      "count": 1
                  },
                  {
                      "id": "10142",
                      "name": "Триполи",
                      "count": 1
                  }
              ]
          }
      ]
  },
  {
      "id": "10057",
      "name": "Босния и Герцеговина",
      "count": 1,
      "chld": [
          {
              "id": "4294967294",
              "name": "Без области",
              "count": 1,
              "chld": [
                  {
                      "id": "10387",
                      "name": "Сараево",
                      "count": 1
                  }
              ]
          }
      ]
  },
  {
      "id": "21562",
      "name": "Восточный Тимор",
      "count": 1,
      "chld": [
          {
              "id": "4294967294",
              "name": "Без области",
              "count": 1,
              "chld": [
                  {
                      "id": "21563",
                      "name": "Дили",
                      "count": 1
                  }
              ]
          }
      ]
  },
  {
      "id": "20802",
      "name": "Гана",
      "count": 1,
      "chld": [
          {
              "id": "4294967294",
              "name": "Без области",
              "count": 1,
              "chld": [
                  {
                      "id": "20803",
                      "name": "Аккра",
                      "count": 1
                  }
              ]
          }
      ]
  },
  {
      "id": "10089",
      "name": "Гибралтар",
      "count": 1,
      "chld": [
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 1
          }
      ]
  },
  {
      "id": "203",
      "name": "Дания",
      "count": 1,
      "chld": [
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 1
          }
      ]
  },
  {
      "id": "21196",
      "name": "Замбия",
      "count": 1,
      "chld": [
          {
              "id": "4294967294",
              "name": "Без области",
              "count": 1,
              "chld": [
                  {
                      "id": "21197",
                      "name": "Лусака",
                      "count": 1
                  }
              ]
          }
      ]
  },
  {
      "id": "20736",
      "name": "Камерун",
      "count": 1,
      "chld": [
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 1
          }
      ]
  },
  {
      "id": "21131",
      "name": "Коста-Рика",
      "count": 1,
      "chld": [
          {
              "id": "115603",
              "name": "Провинция Сан-Хосе",
              "count": 1,
              "chld": [
                  {
                      "id": "21518",
                      "name": "Сан-Хосе",
                      "count": 1
                  }
              ]
          }
      ]
  },
  {
      "id": "10537",
      "name": "Кувейт",
      "count": 1,
      "chld": [
          {
              "id": "4294967294",
              "name": "Без области",
              "count": 1,
              "chld": [
                  {
                      "id": "11496",
                      "name": "Эль-Кувейт",
                      "count": 1
                  }
              ]
          }
      ]
  },
  {
      "id": "20972",
      "name": "Лаос",
      "count": 1,
      "chld": [
          {
              "id": "4294967294",
              "name": "Без области",
              "count": 1,
              "chld": [
                  {
                      "id": "21394",
                      "name": "Вьентьян",
                      "count": 1
                  }
              ]
          }
      ]
  },
  {
      "id": "21241",
      "name": "Маврикий",
      "count": 1,
      "chld": [
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 1
          }
      ]
  },
  {
      "id": "21151",
      "name": "Малави",
      "count": 1,
      "chld": [
          {
              "id": "4294967294",
              "name": "Без области",
              "count": 1,
              "chld": [
                  {
                      "id": "112991",
                      "name": "Блантайр",
                      "count": 1
                  }
              ]
          }
      ]
  },
  {
      "id": "10098",
      "name": "Мальдивы",
      "count": 1,
      "chld": [
          {
              "id": "4294967294",
              "name": "Без области",
              "count": 1,
              "chld": [
                  {
                      "id": "10605",
                      "name": "Мале",
                      "count": 1
                  }
              ]
          }
      ]
  },
  {
      "id": "101521",
      "name": "Мартиника",
      "count": 1,
      "chld": [
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 1
          }
      ]
  },
  {
      "id": "10099",
      "name": "Монголия",
      "count": 1,
      "chld": [
          {
              "id": "4294967294",
              "name": "Без области",
              "count": 1,
              "chld": [
                  {
                      "id": "10606",
                      "name": "Улан-Батор",
                      "count": 1
                  }
              ]
          }
      ]
  },
  {
      "id": "21586",
      "name": "Оман",
      "count": 1,
      "chld": [
          {
              "id": "144809",
              "name": "Мухафаза Дофар",
              "count": 1,
              "chld": [
                  {
                      "id": "41316",
                      "name": "Салала",
                      "count": 1
                  }
              ]
          }
      ]
  },
  {
      "id": "20739",
      "name": "Папуа-Новая Гвинея",
      "count": 1,
      "chld": [
          {
              "id": "4294967294",
              "name": "Без области",
              "count": 1,
              "chld": [
                  {
                      "id": "94048",
                      "name": "Лаэ",
                      "count": 1
                  }
              ]
          }
      ]
  },
  {
      "id": "20764",
      "name": "Пуэрто-Рико",
      "count": 1,
      "chld": [
          {
              "id": "4294967294",
              "name": "Без области",
              "count": 1,
              "chld": [
                  {
                      "id": "109736",
                      "name": "Кагуас",
                      "count": 1
                  }
              ]
          }
      ]
  },
  {
      "id": "21198",
      "name": "Республика Конго",
      "count": 1,
      "chld": [
          {
              "id": "4294967294",
              "name": "Без области",
              "count": 1,
              "chld": [
                  {
                      "id": "21202",
                      "name": "Браззавиль",
                      "count": 1
                  }
              ]
          }
      ]
  },
  {
      "id": "21371",
      "name": "Руанда",
      "count": 1,
      "chld": [
          {
              "id": "4294967294",
              "name": "Без области",
              "count": 1,
              "chld": [
                  {
                      "id": "21373",
                      "name": "Кигали",
                      "count": 1
                  }
              ]
          }
      ]
  },
  {
      "id": "10542",
      "name": "Сирия",
      "count": 1,
      "chld": [
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 1
          }
      ]
  },
  {
      "id": "21227",
      "name": "Сомали",
      "count": 1,
      "chld": [
          {
              "id": "4294967295",
              "name": "Не определено",
              "count": 1
          }
      ]
  },
  {
      "id": "21187",
      "name": "Тринидад и Тобаго",
      "count": 1,
      "chld": [
          {
              "id": "4294967294",
              "name": "Без области",
              "count": 1,
              "chld": [
                  {
                      "id": "98557",
                      "name": "Порт-оф-Спейн",
                      "count": 1
                  }
              ]
          }
      ]
  },
  {
      "id": "21289",
      "name": "Уругвай",
      "count": 1,
      "chld": [
          {
              "id": "123402",
              "name": "Департамент Мальдонадо",
              "count": 1,
              "chld": [
                  {
                      "id": "123416",
                      "name": "Мальдонадо",
                      "count": 1
                  }
              ]
          }
      ]
  }
]